var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.id ? _vm.id : "confirmModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "info-header-modalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "info-header-modalLabel" }
              },
              [_vm._v(_vm._s(_vm.title || "お知らせ"))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [_vm._t("content")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-light",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("キャンセル")]
            ),
            _vm._v(" "),
            _vm.type === "confirm"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-info",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      disabled: _vm.confirmButtonDisabled
                    },
                    on: { click: _vm.confirm }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.confirmButtonLabel || "確認") +
                        "\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "delete"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.confirm }
                  },
                  [_vm._v("\n          削除\n        ")]
                )
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }