var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-group" }, [
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "text", disabled: "" },
      domProps: { value: _vm.text }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "input-group-append" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.copy()
            }
          }
        },
        [_c("i", { staticClass: "mdi mdi-content-copy" })]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }