import { render, staticRenderFns } from "./ReminderEditor.vue?vue&type=template&id=ec9da272&"
import script from "./ReminderEditor.vue?vue&type=script&lang=js&"
export * from "./ReminderEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec9da272')) {
      api.createRecord('ec9da272', component.options)
    } else {
      api.reload('ec9da272', component.options)
    }
    module.hot.accept("./ReminderEditor.vue?vue&type=template&id=ec9da272&", function () {
      api.rerender('ec9da272', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/page/user/reminder/ReminderEditor.vue"
export default component.exports