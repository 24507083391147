var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "border" }, [
        _vm.isLoading
          ? _c("div", { staticStyle: { padding: "20px" } }, [
              _vm._v("\n      Loading...\n    ")
            ])
          : _c(
              "div",
              {
                staticClass: "flex-content",
                class: _vm.errors.first("flex-template" + _vm.index)
                  ? "invalid-box"
                  : ""
              },
              [
                _vm.flexMessageHtml == null
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "select-sticker",
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#flexMessageModal_" + _vm.index
                          }
                        },
                        [_vm._m(0)]
                      )
                    ])
                  : _c("div", [
                      _c("div", {
                        staticClass: "flex-editor-preview",
                        domProps: { innerHTML: _vm._s(_vm.flexMessageHtml) }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "position-absolute close",
                          staticStyle: { top: "10px", right: "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeFlexMessage()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ])
              ]
            )
      ]),
      _vm._v(" "),
      _vm.errors.first("flex-template" + _vm.index)
        ? _c("span", { staticClass: "invalid-box-label" }, [
            _vm._v("Flexメッセージは必須です")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("modal-select-flex-message-template", {
        attrs: { name: "flexMessageModal_" + _vm.index },
        on: { input: _vm.pickFlexmessage }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.flexMessageHtml,
            expression: "flexMessageHtml"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        attrs: { type: "hidden", name: "flex-template" + _vm.index },
        domProps: { value: _vm.flexMessageHtml },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.flexMessageHtml = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "figure figure-grid-sm my-4 text-center cursor-pointer" },
      [
        _c("p", [_vm._v("Flexメッセージを選択する")]),
        _vm._v(" "),
        _c("div", { staticClass: "text-muted opacity-30" }, [
          _c("i", { staticClass: "far fa-address-card fa-3x" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }