var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "viewer",
            rawName: "v-viewer",
            value: _vm.options,
            expression: "options"
          }
        ]
      },
      [
        _c("img", {
          staticClass: "image bg-position-center",
          class: _vm.isMobile ? "fw-140 fh-100" : "fw-200 fh-150",
          attrs: { src: _vm.src }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }