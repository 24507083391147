var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("代替テキスト"), _c("required-mark")], 1),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.altText,
                expression: "altText",
                modifiers: { trim: true }
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:400",
                expression: "'required|max:400'"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "altText" + _vm.index,
              placeholder: "代替テキストを入力してください",
              maxlength: "401",
              "data-vv-as": "代替テキスト"
            },
            domProps: { value: _vm.altText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.altText = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first("altText" + _vm.index) }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "fw-260" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.backgroundUrl,
                  expression: "backgroundUrl"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "d-none",
              attrs: {
                type: "text",
                name: "image-url" + _vm.index,
                "data-vv-as": "背景画像"
              },
              domProps: { value: _vm.backgroundUrl },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.backgroundUrl = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("imagemap-view", {
              class: _vm.errors.first("image-url" + _vm.index)
                ? "fh-260 invalid-box"
                : "fh-260",
              attrs: {
                background: _vm.backgroundUrl,
                "template-id": _vm.templateId
              },
              on: { click: _vm.expandAction }
            }),
            _vm._v(" "),
            _c("error-message", {
              attrs: { message: _vm.errors.first("image-url" + _vm.index) }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-block btn btn-secondary mt-2",
                attrs: {
                  type: "button",
                  "data-toggle": "modal",
                  "data-target": "#imagemapTemplateSelectorModal" + _vm.index
                }
              },
              [_vm._v("\n        テンプレートを選択\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-block btn btn-secondary mt-2",
                attrs: {
                  type: "button",
                  "data-toggle": "modal",
                  "data-target": "#imagemapModalUploadImage" + _vm.index
                }
              },
              [_vm._v("\n        背景画像をアップロード\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-block btn btn-secondary mt-2",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.isShowingEditor = true
                  }
                }
              },
              [_vm._v("\n        一式の個別画像を編集\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grow-1 ml-4" }, [
          _c("h5", [_vm._v("アクション設定")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "accordion" } },
            _vm._l(_vm.actionObjects, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "card mb-2",
                    class: _vm.errors.items.find(function(item) {
                      return item.field.includes("imagemap_action_" + index)
                    })
                      ? "invalid-box"
                      : ""
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "p-2",
                        on: {
                          click: function($event) {
                            return _vm.expandAction(item.key, false, index)
                          }
                        }
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-block btn-link text-left btn btn-outline-block",
                              attrs: { type: "button" }
                            },
                            [
                              !item.expand
                                ? _c("i", {
                                    staticClass:
                                      "fas mr-2 mdi mdi-chevron-right",
                                    staticStyle: { width: "20px" }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "fas mr-2 mdi mdi-chevron-down",
                                    staticStyle: { width: "20px" }
                                  }),
                              _vm._v(_vm._s(item.key) + "\n                ")
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.expand,
                            expression: "item.expand"
                          }
                        ]
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "card-body pt-0 accordion-0 center"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("action-editor", {
                                    attrs: {
                                      index: index,
                                      value: item.action,
                                      supports: [
                                        "",
                                        "message",
                                        "uri",
                                        "survey"
                                      ],
                                      requiredLabel: false,
                                      showTitle: false,
                                      name: "imagemap_action_" + index,
                                      messageType: "imagemap"
                                    },
                                    on: {
                                      input: function($event) {
                                        item.action = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("imagemap-template-selector", {
        attrs: {
          id: "imagemapTemplateSelectorModal" + _vm.index,
          selectionId: _vm.templateId
        },
        on: { accept: _vm.templateChange }
      }),
      _vm._v(" "),
      _c("modal-select-media", {
        attrs: {
          types: ["imagemap"],
          filterable: false,
          id: "imagemapModalUploadImage" + _vm.index
        },
        on: { select: _vm.onSelectMedia }
      }),
      _vm._v(" "),
      _vm.isShowingEditor
        ? _c("modal-rich-menu-image-editor", {
            attrs: { templateId: _vm.templateId, editor: "imagemap" },
            on: {
              close: function($event) {
                _vm.isShowingEditor = false
              },
              input: _vm.exportImage
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }