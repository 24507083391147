var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { key: _vm.contentKey, staticClass: "panel panel-default pb20 mb-0" },
          [
            _c(
              "ul",
              {
                staticClass: "nav nav-tabs nav-bordered",
                attrs: { role: "tablist" }
              },
              [
                _vm._l(_vm.messageData.columns, function(item, index) {
                  return _c(
                    "li",
                    { key: index, attrs: { role: "presentation" } },
                    [
                      _c(
                        "a",
                        {
                          class: _vm.selected === index ? "active" : "",
                          attrs: {
                            "aria-controls": "text",
                            role: "tab",
                            "data-toggle": "tab",
                            "aria-expanded": "true"
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeSelected(index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            パネル" +
                              _vm._s(index + 1) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.messageData.columns.length > 1
                        ? _c(
                            "span",
                            {
                              staticClass: "pl-1",
                              on: {
                                click: function($event) {
                                  return _vm.removeColumn(index)
                                }
                              }
                            },
                            [_c("i", { staticClass: "dripicons-trash" })]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-flex justify-content-center p-1 pl-2",
                    on: { click: _vm.addMoreColumn }
                  },
                  [_vm._m(0)]
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "panel-body" },
              [
                _c(
                  "div",
                  { staticClass: "carousel-body", attrs: { hidden: "" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "list-carousel d-flex align-items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "carousel-group d-flex align-items-center"
                          },
                          _vm._l(_vm.messageData.columns, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                class:
                                  _vm.selected === index
                                    ? "carousel-preview active"
                                    : "carousel-preview"
                              },
                              [
                                _c("div", { staticClass: "carousel-header" }, [
                                  _c(
                                    "span",
                                    { staticClass: "carousel-header-title" },
                                    [_vm._v(_vm._s(index + 1) + "枚目")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "carousel-header-action" },
                                    [
                                      _vm.messageData.columns.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item",
                                              on: {
                                                click: function($event) {
                                                  return _vm.moveLeftColumn(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "glyphicon glyphicon-arrow-left"
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.messageData.columns.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item",
                                              on: {
                                                click: function($event) {
                                                  return _vm.moveRightColumn(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "glyphicon glyphicon-arrow-right"
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "action-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.copyColumn(index, item)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "mdi mdi-content-copy glyphicon"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "action-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.addMoreColumn(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "glyphicon glyphicon-plus"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.messageData.columns.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "action-item",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeColumn(index)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "glyphicon glyphicon-remove"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "carousel-content",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeSelected(index)
                                        }
                                      }
                                    },
                                    [
                                      item.imageUrl
                                        ? _c("div", {
                                            staticClass: "carousel-thumb",
                                            style: {
                                              backgroundImage:
                                                "url(" + item.imageUrl + ")"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.imageUrl
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "carousel-thumb",
                                              class: _vm.errors.first(
                                                "image-url-" + index
                                              )
                                                ? "invalid-box"
                                                : ""
                                            },
                                            [
                                              _vm._v(
                                                "\n                      (画像未登録)\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.imageUrl,
                                            expression: "item.imageUrl"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        attrs: {
                                          type: "hidden",
                                          name: "image-url-" + index
                                        },
                                        domProps: { value: item.imageUrl },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              item,
                                              "imageUrl",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.messageData.columns.length < 10
                          ? _c(
                              "div",
                              {
                                staticClass: "carousel-add-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.addMoreColumn(null)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "glyphicon glyphicon-plus-sign"
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "count-carousel" }, [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.messageData.columns.length) +
                                      " / 10)"
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.messageData.columns, function(column, indexColumn) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selected === indexColumn,
                          expression: "selected === indexColumn"
                        }
                      ],
                      key: indexColumn,
                      staticClass: "carousel-group-action row"
                    },
                    [
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("選択後の挙動")]),
                            _vm._v(" "),
                            _c("action-editor", {
                              attrs: {
                                name:
                                  "template_image_carousel_" +
                                  _vm.indexParent +
                                  "_column" +
                                  indexColumn +
                                  "_action" +
                                  _vm.index,
                                value: column.action,
                                supports: [
                                  "postback",
                                  "uri",
                                  "message",
                                  "datetimepicker",
                                  "survey"
                                ],
                                requiredLabel: false
                              },
                              on: {
                                input: function($event) {
                                  return _vm.changeActionColumn(
                                    indexColumn,
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "group-button-thumb form-group" },
                          [
                            _c(
                              "label",
                              [
                                _vm._v("画像"),
                                _c("required-mark"),
                                _c("tool-tip", {
                                  attrs: {
                                    title:
                                      "画像のファイルメッセージの表示が遅延することを防ぐために、個々の画像ファイルサイズを小さくしてください（1MB以下推奨)。"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn btn-secondary btn-block uploadfile-thumb",
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target":
                                    "#modalSelectMedia" + _vm.indexParent
                                }
                              },
                              [
                                _vm._v(
                                  "\n                画像選択\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            column.imageUrl
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn btn-outline-danger btn-sm",
                                    on: {
                                      click: function($event) {
                                        return _vm.removeCurrentThumb(
                                          indexColumn
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                このパネルの画像を削除\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            column.imageUrl
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "btn btn-secondary btn-sm",
                                    on: {
                                      click: function($event) {
                                        return _vm.cloneToAll(indexColumn)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                全パネルにこの画像をコピー\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            column.imageUrl
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn btn-outline-danger btn-sm",
                                    on: { click: _vm.removeAllThumb }
                                  },
                                  [
                                    _vm._v(
                                      "\n                全パネルの画像を削除\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-sm mb-2" }, [
                              _vm._v(
                                "画像のファイルメッセージの表示が遅延することを防ぐために、個々の画像ファイルサイズを小さくしてください（1MB以下推奨、縦横比：1:1.51）"
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: column.imageUrl,
                                  expression: "column.imageUrl"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                type: "hidden",
                                name: "image-url-" + indexColumn,
                                "data-vv-as": "パネル画像"
                              },
                              domProps: { value: column.imageUrl },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    column,
                                    "imageUrl",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.first("image-url-" + indexColumn)
                              ? [
                                  _c("error-message", {
                                    attrs: {
                                      message: "パネルの画像は必須項目です"
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group text-center" },
                              [
                                column.imageUrl
                                  ? _c("img", {
                                      staticClass: "fw-250",
                                      attrs: { src: column.imageUrl }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.errorMessageUploadFile
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "label error-message-upload"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errorMessageUploadFile)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("modal-select-media", {
        attrs: {
          types: ["image"],
          id: "modalSelectMedia" + _vm.indexParent,
          filterable: false
        },
        on: { select: _vm.uploadThumb }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "uil-plus" }), _vm._v("追加 ")])
  }
]
render._withStripped = true

export { render, staticRenderFns }