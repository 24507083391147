var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-inline-block text-nowrap" }, [
    _vm.data.type === "text" ? _c("span", [_vm._v("【テキスト】")]) : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "sticker"
      ? _c("span", [_vm._v("【スタンプ】")])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "image" ? _c("span", [_vm._v("【画像】")]) : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "video" ? _c("span", [_vm._v("【動画】")]) : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "audio" ? _c("span", [_vm._v("【音声】")]) : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "location"
      ? _c("span", [_vm._v("【位置情報】")])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "imagemap"
      ? _c("span", [_vm._v("【リッチ画像】")])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "flex" ? _c("span", [_vm._v("【Flex】")]) : _vm._e(),
    _vm._v(" "),
    _vm.data.type === "template"
      ? _c("span", [
          _vm.data.template.type === "buttons"
            ? _c("span", [_vm._v("【ボタン】")])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.template.type === "confirm"
            ? _c("span", [_vm._v("【質問】")])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.template.type === "carousel"
            ? _c("span", [_vm._v("【カルーセル】")])
            : _vm._e(),
          _vm._v(" "),
          _vm.data.template.type === "image_carousel"
            ? _c("span", [_vm._v("【画像カルーセル】")])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }