var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        { staticClass: "w-100" },
        [_vm._v("\n    コンテンツ\n    "), _c("required-mark")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btn-template mb20 fz14" }, [
        _vm.currentTemplate.flex_message_id
          ? _c(
              "a",
              {
                staticClass: "btn-block",
                attrs: { "data-toggle": "modal", "data-target": "#" + _vm.name }
              },
              [_c("span", [_vm._v(_vm._s(_vm.currentTemplate.title))])]
            )
          : _c(
              "a",
              {
                staticClass: "btn-block",
                attrs: { "data-toggle": "modal", "data-target": "#" + _vm.name }
              },
              [_vm._v("Flexメッセージから作成")]
            ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentTemplate.flex_message_id,
              expression: "currentTemplate.flex_message_id"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          attrs: { type: "hidden", name: _vm.name + "_flex_message_id" },
          domProps: { value: _vm.currentTemplate.flex_message_id },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.currentTemplate,
                "flex_message_id",
                $event.target.value
              )
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.first(_vm.name + "_flex_message_id")
          ? _c("span", { staticClass: "invalid-box-label" }, [
              _vm._v("Flexメッセージは必須です")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("modal-select-flex-message-template", {
        attrs: { name: _vm.name },
        on: { input: _vm.selectTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }