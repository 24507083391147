var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c(
              "form",
              {
                attrs: { method: "post", enctype: "multipart/form-data" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    validate().then(_vm.onSubmit)
                  }
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken }
                }),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "mxw-1200" }, [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            { staticClass: "form-border" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "流入経路名称",
                                  rules: "required|max:255"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                [
                                                  _vm._v("流入経路名称"),
                                                  _c("required-mark")
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.streamRouteFormData
                                                        .name,
                                                    expression:
                                                      "streamRouteFormData.name",
                                                    modifiers: { trim: true }
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "255",
                                                  placeholder:
                                                    "流入経路名称を入力してください"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.streamRouteFormData.name
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.streamRouteFormData,
                                                      "name",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$forceUpdate()
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "error-explanation"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-border" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "QRコード表示用テキスト",
                                  rules: "max:16"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v("QRコード表示用テキスト")
                                              ]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.streamRouteFormData
                                                        .qr_title,
                                                    expression:
                                                      "streamRouteFormData.qr_title",
                                                    modifiers: { trim: true }
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "16",
                                                  placeholder:
                                                    "QRコード表示用テキストを入力してください"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.streamRouteFormData
                                                      .qr_title
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.streamRouteFormData,
                                                      "qr_title",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$forceUpdate()
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "error-explanation"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: " form-group" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("div", { staticClass: "has-modal-xl" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-8" },
                                    [
                                      _c("action-editor-custom", {
                                        attrs: {
                                          requiredLabel: _vm.requiredLabel,
                                          showTitle: _vm.showTitle,
                                          showLaunchMessage: false,
                                          value: _vm.streamRouteFormData
                                            .actionData
                                            ? _vm.streamRouteFormData
                                                .actionData[0]
                                            : {}
                                        },
                                        on: { input: _vm.updateAction }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-8" }, [
                              _c(
                                "label",
                                { attrs: { for: "run_add_friend_actions" } },
                                [
                                  _vm._v(
                                    "\n                  友だち追加時設定\n                  "
                                  ),
                                  _c("tool-tip", {
                                    attrs: {
                                      title:
                                        "無視する... 流入経路から友だち追加された場合、「友だち追加時設定」のアクションを無視します。\n                           無視しない... 「友だち追加時設定」のアクションが反映された後にこの流入経路のアクションが反映されます。\n                           「無視する」「無視しない」の設定に関わらず、流入経路のアクションでシナリオを操作している場合、「友だち追加時設定」のシナリオは配信されません。"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-group btn-group-toggle w-100",
                                  attrs: { "data-toggle": "buttons" }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      class:
                                        (!_vm.streamRouteFormData
                                          .run_add_friend_actions
                                          ? "active"
                                          : "") +
                                        " btn btn-primary d-flex justify-content-center prevent-default-label w-50",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeRunAddFriendActions(
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "run_add_friend_actions",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: false,
                                          checked: !_vm.streamRouteFormData
                                            .run_add_friend_actions
                                        }
                                      }),
                                      _vm._v(" 無視する\n                    ")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      class:
                                        (_vm.streamRouteFormData
                                          .run_add_friend_actions
                                          ? "active"
                                          : "") +
                                        " btn btn-primary text-center d-flex justify-content-center prevent-default-label w-50",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeRunAddFriendActions(
                                            true
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "run_add_friend_actions",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: true,
                                          checked:
                                            _vm.streamRouteFormData
                                              .run_add_friend_actions
                                        }
                                      }),
                                      _vm._v(
                                        " 無視しない\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-sm-8" }, [
                              _c(
                                "label",
                                { attrs: { for: "always_run_actions" } },
                                [_vm._v("アクションの実行")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-group btn-group-toggle w-100",
                                  attrs: { "data-toggle": "buttons" }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      class:
                                        (_vm.streamRouteFormData
                                          .always_run_actions
                                          ? "active"
                                          : "") +
                                        " btn btn-primary d-flex justify-content-center prevent-default-label w-50",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeAlwaysRunActions(
                                            true
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "always_run_actions",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: false,
                                          checked:
                                            _vm.streamRouteFormData
                                              .always_run_actions
                                        }
                                      }),
                                      _vm._v(" いつでも\n                    ")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      class:
                                        (!_vm.streamRouteFormData
                                          .always_run_actions
                                          ? "active"
                                          : "") +
                                        " btn btn-primary text-center d-flex justify-content-center prevent-default-label w-50",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeAlwaysRunActions(
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "always_run_actions",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: true,
                                          checked: !_vm.streamRouteFormData
                                            .always_run_actions
                                        }
                                      }),
                                      _vm._v(
                                        " 初回の友だち追加時のみ\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-border mt-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "fw-300" }, [
                                _vm._v("フォルダー")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-grow-1" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.streamRouteFormData.folder_id,
                                        expression:
                                          "streamRouteFormData.folder_id"
                                      }
                                    ],
                                    staticClass: "form-control fw-300",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.streamRouteFormData,
                                          "folder_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  _vm._l(_vm.folder_options, function(
                                    folder,
                                    index
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: folder.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(folder.name) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mw-120",
                              attrs: { type: "submit" }
                            },
                            [_c("strong", [_vm._v("保存")])]
                          )
                        ]),
                        _vm._v(" "),
                        _c("loading-indicator", {
                          attrs: { loading: _vm.loading }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }