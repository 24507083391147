var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.mode === "manage" ? "card mvh-60" : "card" },
    [
      _c(
        "div",
        { staticClass: "card-header d-flex flex-wrap align-items-center" },
        [
          _vm.mode === "manage"
            ? _c("div", { staticClass: "mr-auto" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mb-2",
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia"
                    },
                    on: {
                      click: function($event) {
                        _vm.curUploadType = "image"
                      }
                    }
                  },
                  [_vm._v("\n        画像アップロード\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mb-2",
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia"
                    },
                    on: {
                      click: function($event) {
                        _vm.curUploadType = "audio"
                      }
                    }
                  },
                  [_vm._v("\n        音声アップロード\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mb-2",
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia"
                    },
                    on: {
                      click: function($event) {
                        _vm.curUploadType = "video"
                      }
                    }
                  },
                  [_vm._v("\n        動画アップロード\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mb-2",
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia"
                    },
                    on: {
                      click: function($event) {
                        _vm.curUploadType = "richmenu"
                      }
                    }
                  },
                  [_vm._v("\n        メニュー画像アップロード\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mb-2",
                    attrs: {
                      href: "#",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia",
                      hidden: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.curUploadType = "pdf"
                      }
                    }
                  },
                  [_vm._v("\n        PDFアップロード\n      ")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _vm.filterable
                ? _c("media-filter", { on: { filterChanged: _vm.getMedias } })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { key: _vm.contentKey, staticClass: "card-body d-flex flex-column" },
        [
          _c(
            "div",
            { class: _vm.mode === "read" ? "row flex-grow-1" : "row" },
            _vm._l(_vm.medias, function(media, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: _vm.isManageMode
                    ? "col-xl-2 col-lg-4 col-sm-6 overflow-hidden"
                    : "col-xl-4 col-md-6 col-sm-4 w-50 overflow-hidden",
                  on: {
                    click: function($event) {
                      return _vm.selectMedia(media, $event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card mx-auto",
                      class: _vm.isMobile ? "fw-140" : "fw-200"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body p-0 d-flex align-items-center justify-content-center"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center overflow-hidden" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "media-preview",
                                  attrs: { role: "button" }
                                },
                                [
                                  _vm.isImage(media)
                                    ? [
                                        _vm.mode === "manage"
                                          ? _c("expandable-image", {
                                              attrs: {
                                                src:
                                                  media.preview_url || media.url
                                              }
                                            })
                                          : _c("div", {
                                              directives: [
                                                {
                                                  name: "lazy",
                                                  rawName:
                                                    "v-lazy:background-image",
                                                  value:
                                                    media.preview_url ||
                                                    media.url,
                                                  expression:
                                                    "media.preview_url || media.url",
                                                  arg: "background-image"
                                                }
                                              ],
                                              staticClass: "bg-position-center",
                                              class: _vm.isMobile
                                                ? "fw-140 fh-100"
                                                : "fw-200 fh-150"
                                            })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isVideo(media)
                                    ? [
                                        _c(
                                          "video",
                                          {
                                            attrs: {
                                              width: _vm.isMobile ? 140 : 200,
                                              height: _vm.isMobile ? 100 : 150,
                                              controls: ""
                                            }
                                          },
                                          [
                                            _c("source", {
                                              attrs: { src: media.url }
                                            })
                                          ]
                                        )
                                      ]
                                    : _vm.isPdf(media)
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center",
                                            class: _vm.isMobile
                                              ? "fw-140 fh-100"
                                              : "fw-200 fh-150"
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/images/messages/pdf.png",
                                                width: "100"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    : _vm.isAudio(media)
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center",
                                            class: _vm.isMobile
                                              ? "fw-140 fh-100"
                                              : "fw-200 fh-150"
                                          },
                                          [
                                            _c(
                                              "audio",
                                              {
                                                staticClass:
                                                  "audio-player mx-2 mx-safari",
                                                attrs: { controls: "" }
                                              },
                                              [
                                                _c("source", {
                                                  attrs: { src: media.url }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isManageMode
                        ? _c("div", { staticClass: "card-footer" }, [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center mt-1 mr-auto"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedMedias,
                                        expression: "selectedMedias"
                                      }
                                    ],
                                    staticClass: "select-media-cb mr-1",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: media,
                                      checked: Array.isArray(_vm.selectedMedias)
                                        ? _vm._i(_vm.selectedMedias, media) > -1
                                        : _vm.selectedMedias
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.selectedMedias,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = media,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedMedias = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedMedias = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedMedias = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("b", [_vm._v(_vm._s(media.type))]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ml-auto text-sm text-info",
                                      attrs: { role: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.download(media)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-download"
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "download-icon mt-1",
                                  attrs: { role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.download(media)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "dripicons-cloud-download"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("small", { staticClass: "w-100" }, [
                              _vm._v("\n              登録："),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.formattedDate(media.created_at))
                                )
                              ])
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.medias && _vm.medias.length > 0
            ? _c(
                "div",
                { staticClass: "d-flex align-items-center mt-2" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      "aria-controls": "my-table"
                    },
                    on: { change: _vm.loadPage },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isManageMode
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-items-center ml-auto" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-flex align-items-center m-0 mr-2",
                              attrs: { for: "selectAllMemberCb" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedAll,
                                    expression: "checkedAll"
                                  }
                                ],
                                staticClass: "mr-1",
                                attrs: {
                                  id: "selectAllMemberCb",
                                  type: "checkbox",
                                  value: "1"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.checkedAll)
                                    ? _vm._i(_vm.checkedAll, "1") > -1
                                    : _vm.checkedAll
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.checkedAll,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "1",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checkedAll = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checkedAll = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checkedAll = $$c
                                      }
                                    },
                                    _vm.selectAllMedia
                                  ]
                                }
                              }),
                              _vm._v(
                                "\n          全てのメディアをチェック\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-sm",
                              attrs: {
                                disabled: !(_vm.selectedMedias.length > 0),
                                "data-toggle": "modal",
                                "data-target": "#modalDeleteConfirm"
                              }
                            },
                            [
                              _vm._v(
                                "\n          チェックしたメディアをリストから削除する\n        "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          (!_vm.medias || _vm.medias.length === 0) && !_vm.loading
            ? _c("div", { staticClass: "text-center my-5" }, [
                _c("b", [_vm._v("登録したメディアはありません。")])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _vm.isManageMode
        ? _c("modal-confirm", {
            attrs: {
              title: "選択したものを削除してもよろしいですか？",
              id: "modalDeleteConfirm",
              type: "delete"
            },
            on: { confirm: _vm.deleteSelectedMedia },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v(
                        " 選択したメディア数：" +
                          _vm._s(_vm.selectedMedias.length) +
                          " "
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              153955030
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("modal-upload-media", {
        attrs: { types: [_vm.curUploadType] },
        on: { upload: _vm.onUploadFinished }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }