var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          [
            _c("div", { staticClass: "alert alert-warning" }, [
              _vm._v(
                "\n        パネルタイトルの有無・画像の有無・ボタンの数は全てのパネルで同じ必要があります。\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "nav nav-tabs nav-bordered",
                attrs: { role: "tablist" }
              },
              [
                _vm._l(_vm.messageData.columns, function(item, index) {
                  return _c(
                    "li",
                    { key: index, attrs: { role: "presentation" } },
                    [
                      _c(
                        "a",
                        {
                          class: _vm.selected === index ? "active" : "",
                          attrs: {
                            "aria-controls": "text",
                            role: "tab",
                            "data-toggle": "tab",
                            "aria-expanded": "true"
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeSelected(index)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            パネル" +
                              _vm._s(index + 1) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.messageData.columns.length > 1
                        ? _c(
                            "span",
                            {
                              staticClass: "pl-1",
                              on: {
                                click: function($event) {
                                  return _vm.removeColumn(index)
                                }
                              }
                            },
                            [_c("i", { staticClass: "dripicons-trash" })]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-flex justify-content-center p-1 pl-2",
                    on: { click: _vm.addMoreColumn }
                  },
                  [_vm._m(0)]
                )
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.messageData.columns, function(column, indexColumn) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: indexColumn === _vm.selected,
                      expression: "indexColumn === selected"
                    }
                  ],
                  key: indexColumn
                },
                [
                  _c("div", { staticClass: "carousel-group-action row" }, [
                    _c("div", { staticClass: "col-sm-5 p-0" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "パネル" +
                                  _vm._s(_vm.selected + 1) +
                                  "のタイトル "
                              ),
                              _c("required-mark"),
                              _vm._v(" "),
                              _c("tool-tip", {
                                attrs: {
                                  title:
                                    "各カラムのアクションの数は同じにします。画像またはタイトルの有無も、各カラムで統一してください。"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: column.title,
                                expression: "column.title",
                                modifiers: { trim: true }
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.requiredTitle },
                                expression: "{ required: requiredTitle }"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "carousel-title" + indexColumn,
                              placeholder: "タイトル",
                              maxlength: "40",
                              "data-vv-as": "タイトル"
                            },
                            domProps: { value: column.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  column,
                                  "title",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("error-message", {
                            attrs: {
                              message: _vm.errors.first(
                                "carousel-title" + indexColumn
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "パネル" + _vm._s(_vm.selected + 1) + "の本文"
                              ),
                              _c("required-mark")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: column.text,
                                expression: "column.text"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "carousel-text" + indexColumn,
                              placeholder: "本文を入力してください",
                              maxlength: "60",
                              "data-vv-as": "本文"
                            },
                            domProps: { value: column.text },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(column, "text", $event.target.value)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("error-message", {
                            attrs: {
                              message: _vm.errors.first(
                                "carousel-text" + indexColumn
                              )
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          [
                            _vm._v("画像\n                "),
                            _c("tool-tip", {
                              attrs: {
                                title:
                                  "画像のファイルメッセージの表示が遅延することを防ぐために、個々の画像ファイルサイズを小さくしてください（1MB以下推奨、縦横比：1:1.51）"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-lg-6" }, [
                            _c("div", { staticClass: "group-button-thumb" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn btn-secondary btn-block btn-sm uploadfile-thumb",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target":
                                      "#uploadImageModal" + _vm.indexParent
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "glyphicon glyphicon-picture"
                                  }),
                                  _vm._v(
                                    "\n                      画像選択\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              column.thumbnailImageUrl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-outline-danger btn-sm",
                                      on: { click: _vm.removeCurrentThumb }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      このパネルの画像を削除\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              column.thumbnailImageUrl
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "btn btn-secondary btn-sm",
                                      on: { click: _vm.cloneToAll }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      全パネルにこの画像をコピー\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              column.thumbnailImageUrl
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-outline-danger btn-sm",
                                      on: { click: _vm.removeAllThumb }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      全パネルの画像を削除\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-sm mb-2" }, [
                              _vm._v(
                                "画像のファイルメッセージの表示が遅延することを防ぐために、個々の画像ファイルサイズを小さくしてください（1MB以下推奨、縦横比：1:1.51）"
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-6" }, [
                            column.thumbnailImageUrl
                              ? _c("img", {
                                  staticClass: "image-carousel-thumb",
                                  attrs: { src: column.thumbnailImageUrl }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.errorMessageUploadFile
                              ? _c(
                                  "span",
                                  { staticClass: "label error-message-upload" },
                                  [_vm._v(_vm._s(_vm.errorMessageUploadFile))]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12 p-0 row" }, [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "nav nav-tabs nav-stacked nav-buttons d-block"
                          },
                          [
                            _vm._l(column.actions, function(item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class:
                                    _vm.selectedAction == index ? "active" : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeActiveAction(index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "nav-button d-flex",
                                      class: _vm.errors.items.find(function(
                                        item
                                      ) {
                                        return item.field.includes(
                                          _vm.indexParent +
                                            "template_button_" +
                                            index
                                        )
                                      })
                                        ? "invalid-box"
                                        : ""
                                    },
                                    [
                                      _vm._v(
                                        "\n                    ボタン" +
                                          _vm._s(index + 1) +
                                          "\n                    "
                                      ),
                                      column.actions.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "ml-auto mr-1",
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeCurrentAction(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "dripicons-trash"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            column.actions.length < 3
                              ? _c("li", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "nav-button btn btn-primary justify-content-center",
                                      on: { click: _vm.addMoreAction }
                                    },
                                    [
                                      _c("i", { staticClass: "uil-plus" }),
                                      _vm._v(" 追加\n                  ")
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        _vm._l(column.actions, function(item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedAction === index,
                                  expression: "selectedAction === index"
                                }
                              ],
                              key: index,
                              staticClass: "card card-light"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card-header d-flex flex-start align-items-center"
                                },
                                [
                                  _c("label", [
                                    _vm._v("選択肢" + _vm._s(index + 1))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-light btn-sm ml-auto",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.copyCurrentAction(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "mdi mdi-content-copy"
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("action-editor", {
                                      attrs: {
                                        name:
                                          "template_carousel_" +
                                          _vm.indexParent +
                                          "_column" +
                                          indexColumn +
                                          "_action" +
                                          index,
                                        value: item
                                      },
                                      on: {
                                        input: function($event) {
                                          var i = arguments.length,
                                            argsArray = Array(i)
                                          while (i--)
                                            argsArray[i] = arguments[i]
                                          return _vm.changeActionColumn.apply(
                                            void 0,
                                            [indexColumn, index].concat(
                                              argsArray
                                            )
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("modal-select-media", {
        attrs: {
          types: ["image"],
          id: "uploadImageModal" + _vm.indexParent,
          filterable: false
        },
        on: { select: _vm.uploadThumb }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "uil-plus" }), _vm._v("追加 ")])
  }
]
render._withStripped = true

export { render, staticRenderFns }