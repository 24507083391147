var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.type === "message"
        ? _c("action-send-message", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "uri" && _vm.regexUrl
        ? _c("action-open-url", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "uri" && _vm.regexUri
        ? _c("action-open-tel", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "datetimepicker"
        ? _c("action-datetime-picker", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "survey"
        ? _c("action-survey", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "camera" ||
      _vm.data.type === "cameraRoll" ||
      _vm.data.type === "location"
        ? _c("action-default", {
            attrs: {
              showTitle: _vm.showTitle,
              value: _vm.data,
              name: _vm.name,
              requiredLabel: _vm.requiredLabel
            },
            on: { input: _vm.changeAction }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }