var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mxw-1200" },
    [
      _c("div", { key: _vm.componentKey }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-group d-flex" }, [
                _c("label", { staticClass: "fw-250" }, [
                  _vm._v("メッセージ名（管理用)")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.scenarioMessageData.name,
                          expression: "scenarioMessageData.name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:255",
                          expression: "'max:255'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "message-name",
                        placeholder: "メッセージ名を入力してください",
                        "data-vv-as": "メッセージ名"
                      },
                      domProps: { value: _vm.scenarioMessageData.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.scenarioMessageData,
                            "name",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: { message: _vm.errors.first("message-name") }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fmt-2 d-flex" }, [
                _c("label", { staticClass: "fw-250" }, [_vm._v("配信する")]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scenarioMessageData.status,
                        expression: "scenarioMessageData.status"
                      }
                    ],
                    ref: "status",
                    attrs: {
                      type: "checkbox",
                      id: "messageOnoff",
                      checked: "",
                      "data-switch": "success",
                      "true-value": "enabled",
                      "false-value": "disabled"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.scenarioMessageData.status)
                        ? _vm._i(_vm.scenarioMessageData.status, null) > -1
                        : _vm._q(_vm.scenarioMessageData.status, "enabled")
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.scenarioMessageData.status,
                          $$el = $event.target,
                          $$c = $$el.checked ? "enabled" : "disabled"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.scenarioMessageData,
                                "status",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.scenarioMessageData,
                                "status",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.scenarioMessageData, "status", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", {
                    attrs: {
                      for: "messageOnoff",
                      "data-on-label": "オン",
                      "data-off-label": "オフ"
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: _vm.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("scenario-message-time-editor", {
                  attrs: {
                    mode: _vm.scenario.mode,
                    is_initial: _vm.scenarioMessageData.is_initial,
                    date: _vm.scenarioMessageData.date,
                    time: _vm.scenarioMessageData.time,
                    order: _vm.scenarioMessageData.order
                  },
                  on: {
                    "update:is_initial": function($event) {
                      return _vm.$set(
                        _vm.scenarioMessageData,
                        "is_initial",
                        $event
                      )
                    },
                    "update:date": function($event) {
                      return _vm.$set(_vm.scenarioMessageData, "date", $event)
                    },
                    "update:time": function($event) {
                      return _vm.$set(_vm.scenarioMessageData, "time", $event)
                    },
                    "update:order": function($event) {
                      return _vm.$set(_vm.scenarioMessageData, "order", $event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: _vm.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body py-0" },
              _vm._l(_vm.scenarioMessageData.messages, function(item, index) {
                return _c("message-editor", {
                  key: index,
                  attrs: {
                    allowCreateFromTemplate: true,
                    data: item,
                    index: index,
                    showUrlClickConfig: true,
                    siteMeasurements: _vm.scenarioMessageData.site_measurements
                  },
                  on: {
                    selectTemplate: _vm.selectTemplate,
                    input: _vm.onMessageContentChanged,
                    configUrl: _vm.configUrl,
                    changeShortenUrlUsage: _vm.changeShortenUrlUsage
                  }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: _vm.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "btn btn-success mw-120",
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.message_id ? "保存" : "メッセージ登録") +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("message-preview")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("基本設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("配信タイミング設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("メッセージ本文")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }