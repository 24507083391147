var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mt-2" },
        [
          _c("div", { staticClass: "input-group" }, [
            _c("div", { staticClass: "custom-file" }, [
              _c("input", {
                staticClass: "custom-file-input",
                attrs: {
                  type: "file",
                  id: "inputFile",
                  accept: "image/*",
                  name: "answers[" + _vm.qnum + "][answer]"
                },
                on: { change: _vm.onFileChange }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-file-label",
                  attrs: { for: "inputFile" }
                },
                [_vm._v(_vm._s(_vm.fileName || "ファイルを選択"))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("ValidationProvider", {
            attrs: { name: "答え", rules: { required: _vm.isRequired } },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fileName,
                          expression: "fileName"
                        }
                      ],
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.fileName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.fileName = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", { attrs: { message: errors[0] } })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }