var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages.length > 0
    ? _c(
        "div",
        _vm._l(_vm.messages, function(message, index) {
          return _c(
            "div",
            { key: index, staticClass: "text-left" },
            [
              _c("message-content", { attrs: { data: message } }),
              _vm._v(" "),
              index < _vm.messages.length - 1 ? _c("divider") : _vm._e()
            ],
            1
          )
        }),
        0
      )
    : _c("div", [_vm._v("メッセージなし")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }