var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c(
              "form",
              {
                ref: "form",
                attrs: {
                  action: _vm.formAction,
                  method: "post",
                  enctype: "multipart/form-data"
                },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    validate().then(_vm.onSubmit)
                  }
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "contact[friend_line_id]" },
                  domProps: { value: _vm.friendLineId }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card-header border-bottom border-success"
                      },
                      [_c("h4", [_vm._v("お問い合わせフォーム")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-lg-4" },
                          [_vm._v("電話番号"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-lg-8" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "電話番号",
                                rules: "required|length:11"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.contactFormData.phoneNumber,
                                              expression:
                                                "contactFormData.phoneNumber"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "電話番号を入力してください",
                                            name: "contact[phone_number]"
                                          },
                                          domProps: {
                                            value:
                                              _vm.contactFormData.phoneNumber
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.contactFormData,
                                                "phoneNumber",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("error-message", {
                                          attrs: { message: errors[0] }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-footer border-top border-success text-center py-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success fw-120",
                            attrs: { type: "submit" }
                          },
                          [_vm._v("送信")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("loading-indicator", { attrs: { loading: _vm.loading } })
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }