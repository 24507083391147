var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.actions.length > 0
    ? _c(
        "div",
        { staticClass: "d-inline-block chose-actions-presentor align-middle" },
        [
          _c("div", {
            staticClass: "fw-200 text-truncate chose-actions-presentor-main",
            domProps: { innerHTML: _vm._s(_vm.willBeShowedActions) }
          })
        ]
      )
    : _c("div", [_vm._v("なし")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }