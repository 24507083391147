var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.contentKey,
      ref: "modalUploadMedia",
      staticClass: "modal fade",
      attrs: {
        id: "modalSelectMedia",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "close",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body overflow-hidden position-relative" },
              [
                _c("media-upload", {
                  ref: "mediaUpload",
                  attrs: { types: _vm.types },
                  on: {
                    upload: function($event) {
                      return _vm.onUploadFinished(_vm.event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "modal-title" }, [
      _c("label", [_vm._v("アップロードする")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }