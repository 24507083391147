var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-preview" },
    _vm._l(_vm.messages, function(message, index) {
      return _c("div", { key: index }, [
        _vm.isDisplay(message)
          ? _c(
              "div",
              {
                staticClass:
                  "template-message template-message-text-light template-message-secondary"
              },
              [
                _c("div", { staticClass: "template-message-content w-min-0" }, [
                  _c("div", { staticClass: "template-message-body" }, [
                    _c(
                      "div",
                      { staticClass: "template-message-main w-min-0" },
                      [_c("message-content", { attrs: { data: message } })],
                      1
                    )
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }