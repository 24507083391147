var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.packageId && _vm.stickerId
        ? _c("div", { staticClass: "border" }, [
            _c(
              "div",
              {
                staticClass:
                  "figure figure-grid figure-grid-sm mr-3 mb-3 position-relative"
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
                      _vm.stickerId +
                      "/PC/sticker.png",
                    alt: "tabImage"
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "position-absolute close",
                    staticStyle: { top: "10px", right: "0px" },
                    attrs: { type: "button" },
                    on: { click: _vm.removeSticker }
                  },
                  [_c("i", { staticClass: "mdi mdi-close-outline" })]
                )
              ]
            )
          ])
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "border text-center",
                  class: _vm.errors.first("sticker-id" + _vm.index)
                    ? "invalid-box"
                    : ""
                },
                [
                  _c("div", { staticClass: "figure figure-grid-sm my-4" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-primary",
                        attrs: {
                          href: "#",
                          "data-toggle": "modal",
                          "data-target": "#stickerModalCenter" + _vm.index
                        },
                        on: {
                          click: function($event) {
                            return _vm.showStickerModal()
                          }
                        }
                      },
                      [_vm._v("スタンプを選択\n        ")]
                    ),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ]
              ),
              _vm._v(" "),
              _c("error-message", {
                attrs: { message: _vm.errors.first("sticker-id" + _vm.index) }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("modal-select-sticker", {
        ref: "modalSticker" + _vm.index,
        attrs: { id: "stickerModalCenter" + _vm.index },
        on: { input: _vm.selectSticker }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.stickerId,
            expression: "stickerId"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        attrs: {
          type: "hidden",
          name: "sticker-id" + _vm.index,
          "data-vv-as": "スタンプ"
        },
        domProps: { value: _vm.stickerId },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.stickerId = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-muted opacity-30" }, [
      _c("i", { staticClass: "mdi mdi-sticker-emoji mdi-3x" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }