var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tags.length > 0
    ? _c(
        "div",
        _vm._l(_vm.tags, function(tag, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "badge badge-pill badge-warning mr-1 pt-1"
            },
            [_vm._v("\n    " + _vm._s(tag.name) + "\n  ")]
          )
        }),
        0
      )
    : _c("div", [_vm._v("なし")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }