var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status === "enabled"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-success" }),
        _vm._v(" 稼働中 ")
      ])
    : _vm.status === "disabled"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle" }),
        _vm._v(" 停止中 ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }