var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c("ValidationProvider", {
        attrs: { name: "答え", rules: { required: _vm.isRequired, max: 2000 } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c("textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:255",
                      expression: "'required|max:255'"
                    },
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.answer,
                      expression: "answer",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control mt-2",
                  attrs: {
                    rows: "3",
                    name: "answers[" + _vm.qnum + "][answer]",
                    placeholder: "入力してください",
                    "data-vv-as": "答え"
                  },
                  domProps: { value: _vm.answer },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.answer = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", { attrs: { message: errors[0] } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }