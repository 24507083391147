var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        {
          staticClass: "btn btn-primary mw-150",
          attrs: {
            "data-toggle": "modal",
            "data-target": "#modalSelectTemplate"
          }
        },
        [_vm._v("テンプレートを追加")]
      ),
      _vm._v(" "),
      _c("modal-select-template", {
        attrs: { id: "modalSelectTemplate" },
        on: { selectTemplate: _vm.onSelectTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }