var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var validate = ref.validate
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: {
                      action: _vm.getAction(),
                      method: "post",
                      enctype: "multipart/form-data"
                    },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        validate().then(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: "authenticity_token" },
                      domProps: { value: _vm.csrfToken }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "hidden", name: "_method", value: "put" }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "admin[reset_password_token]"
                      },
                      domProps: { value: _vm.reset_password_token }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group mb-3" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "password" } },
                          [_vm._v("パスワード"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "パスワード",
                            type: "password",
                            rules: "required|min:8|max:128",
                            vid: "password"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.settingFormData.password,
                                          expression:
                                            "settingFormData.password",
                                          modifiers: { trim: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "admin[password]",
                                        placeholder: "入力してください",
                                        maxlength: "129"
                                      },
                                      domProps: {
                                        value: _vm.settingFormData.password
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.settingFormData,
                                            "password",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "error-explanation" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group mb-3" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "password_confirmation" } },
                          [_vm._v("パスワード（確認用）"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "パスワード（確認用）",
                            type: "password",
                            rules: "required|min:8|max:128|confirmed:password"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value:
                                            _vm.settingFormData
                                              .password_confirmation,
                                          expression:
                                            "settingFormData.password_confirmation",
                                          modifiers: { trim: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "admin[password_confirmation]",
                                        placeholder: "入力してください",
                                        maxlength: "129"
                                      },
                                      domProps: {
                                        value:
                                          _vm.settingFormData
                                            .password_confirmation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.settingFormData,
                                            "password_confirmation",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "error-explanation" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info",
                          attrs: { type: "submit", disabled: invalid }
                        },
                        [_vm._v("\n          再設定\n        ")]
                      )
                    ])
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }