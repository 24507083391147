var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("staff-selection", {
        attrs: { selected: _vm.actionData.staff_id },
        on: { select: _vm.changeValue }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content.staff_id,
            expression: "content.staff_id"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        staticClass: "d-none",
        attrs: {
          name: _vm.name + "_postback_staff_id",
          "data-vv-as": "担当者"
        },
        domProps: { value: _vm.content.staff_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.content, "staff_id", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("error-message", {
        attrs: { message: _vm.errors.first(_vm.name + "_postback_staff_id") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }