var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey" }, [
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c(
        "label",
        { staticClass: "fw-200" },
        [_vm._v("項目名"), _c("required-mark")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.questionContentData.text,
                expression: "questionContentData.text",
                modifiers: { trim: true }
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:255",
                expression: "'required|max:255'"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: _vm.name + "-text",
              maxlength: "256",
              placeholder: "項目名を入力してください",
              "data-vv-as": "項目名"
            },
            domProps: { value: _vm.questionContentData.text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.questionContentData,
                  "text",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "-text") }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c("div", { staticClass: "fw-200 d-flex align-items-center" }, [
        _c("span", [_vm._v("補足文")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "ml-2",
            attrs: { title: "回答入力欄の下に表示されます" }
          },
          [_c("i", { staticClass: "text-md far fa-question-circle" })]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.questionContentData.sub_text,
                expression: "questionContentData.sub_text",
                modifiers: { trim: true }
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "max:255",
                expression: "'max:255'"
              }
            ],
            staticClass: "form-control",
            attrs: {
              name: _vm.name + "-subtext",
              type: "text",
              placeholder: "補足文を入力してください",
              maxlength: "256",
              "data-vv-as": "補足文"
            },
            domProps: { value: _vm.questionContentData.sub_text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.questionContentData,
                  "sub_text",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "-subtext") }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c("span", { staticClass: "fw-200" }, [_vm._v("回答の情報登録")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("survey-variable-config", {
            attrs: {
              type: "text",
              field: _vm.questionContentData.variable
                ? _vm.questionContentData.variable.name
                : null,
              name: _vm.name + "-infomation"
            },
            on: {
              input: function($event) {
                _vm.questionContentData.variable = $event
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _c("span", { staticClass: "fw-200" }, [_vm._v("選択肢")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _vm._l(_vm.questionContentData.options, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "card border-info border" },
              [
                _c("div", { staticClass: "card-header d-flex" }, [
                  _c("div", [_vm._v("選択肢 " + _vm._s(index + 1))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-auto" }, [
                    index > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-sm btn-light",
                            on: {
                              click: function($event) {
                                return _vm.moveUpObject(index)
                              }
                            }
                          },
                          [_c("i", { staticClass: "dripicons-chevron-up" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    index < _vm.questionContentData.options.length - 1
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-sm btn-light",
                            on: {
                              click: function($event) {
                                return _vm.moveDownObject(index)
                              }
                            }
                          },
                          [_c("i", { staticClass: "dripicons-chevron-down" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionContentData.options.length > 1
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-sm btn-light",
                            on: {
                              click: function($event) {
                                return _vm.removeObject(index)
                              }
                            }
                          },
                          [_c("i", { staticClass: "mdi mdi-delete" })]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "form-group d-flex" }, [
                    _c(
                      "span",
                      { staticClass: "fw-200" },
                      [_vm._v("ラベル"), _c("required-mark")],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        },
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: item.value,
                          expression: "item.value",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control flex-grow-1",
                      attrs: {
                        type: "text",
                        "aria-label": "Option Label",
                        "aria-describedby": "basic-addon1",
                        name: _vm.name + "-value-" + index,
                        placeholder: "ラベルを入力してください"
                      },
                      domProps: { value: item.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "value", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group d-flex mt-2" }, [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.contentKey,
                        staticStyle: { width: "calc(100% - 200px)" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "action-postback" },
                          [
                            _c("action-postback", {
                              attrs: {
                                showTitle: false,
                                value: item.action,
                                name: _vm.name + "-postback-" + index,
                                requiredLabel: false
                              },
                              on: {
                                input: function($event) {
                                  item.action = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _vm.questionContentData.options.length < _vm.max
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        return _vm.addItem()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "uil-plus" }),
                    _vm._v(" 選択肢追加\n        ")
                  ]
                )
              : _vm._e()
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fw-200 pr-2" }, [
      _c("span", { staticClass: "fw-200" }, [_vm._v("選択時のアクション")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }