var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showTitle
      ? _c("div", [
          _c(
            "label",
            { staticClass: "w-100 d-flex align-items-center" },
            [
              _vm._v("\n      ラベル\n      "),
              _vm.requiredLabel ? _c("required-mark") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.value.label,
                    expression: "value.label",
                    modifiers: { trim: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.requiredLabel && _vm.showTitle },
                    expression: "{ required: requiredLabel && showTitle }"
                  }
                ],
                staticClass: "w-100 form-control",
                attrs: {
                  name: _vm.name + "_label",
                  placeholder: "ラベルを入力してください",
                  type: "text",
                  maxlength: "12",
                  "data-vv-as": "ラベル"
                },
                domProps: { value: _vm.value.label },
                on: {
                  keyup: _vm.changeValue,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.value, "label", $event.target.value.trim())
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c("error-message", {
                attrs: { message: _vm.errors.first(_vm.name + "_label") }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-2" },
      [
        _c(
          "label",
          { staticClass: "d-flex align-items-center" },
          [_vm._v("URL"), _c("required-mark")],
          1
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.value.uri,
              expression: "value.uri",
              modifiers: { trim: true }
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: { url: { require_protocol: true }, required: true },
              expression: "{ url: { require_protocol: true }, required: true }"
            }
          ],
          staticClass: "w-100 form-control",
          attrs: {
            name: _vm.name + "_value",
            type: "text",
            maxlength: "1000",
            placeholder: "https://example.com",
            "data-vv-as": "URL"
          },
          domProps: { value: _vm.value.uri },
          on: {
            keyup: _vm.changeValue,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.value, "uri", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: { message: _vm.errors.first(_vm.name + "_value") }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }