var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-common01",
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
        id: _vm.id
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered modal-md modal-rg",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content h-100 p-0" }, [
            _c("div", { staticClass: "modal-header flex-shrink-0 d-flex" }, [
              _c("h5", { staticClass: "mb-0 title" }, [
                _vm._v(_vm._s(_vm.model.name))
              ]),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "close", attrs: { "data-dismiss": "modal" } },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body mh-100 overflow-hidden" }, [
              _c("div", { staticClass: "preview" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.model.html_template) }
                })
              ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: " modal-footer", staticStyle: { "text-align": "center" } },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button", "data-dismiss": "modal" }
          },
          [_vm._v("キャンセル")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }