var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.contentKey, staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group d-flex align-items-center" }, [
            _c("label", { staticClass: "fw-200" }, [_vm._v("フォルダー")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.autoResponseData.folder_id,
                      expression: "autoResponseData.folder_id"
                    }
                  ],
                  staticClass: "form-control fw-300",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.autoResponseData,
                        "folder_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.folders, function(folder, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: folder.id } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(folder.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c(
              "label",
              { staticClass: "fw-200" },
              [_vm._v("自動応答名"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow-1" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.autoResponseData.name,
                      expression: "autoResponseData.name",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:64",
                      expression: "'required|max:64'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "name",
                    placeholder: "自動応答名を入力してください",
                    maxlength: "65",
                    "data-vv-as": "自動応答名"
                  },
                  domProps: { value: _vm.autoResponseData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.autoResponseData,
                        "name",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first("name") }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c("label", { staticClass: "fw-200" }, [_vm._v("自動応答 ON/OFF")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1 d-flex" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.autoResponseData.status,
                    expression: "autoResponseData.status"
                  }
                ],
                ref: "status",
                attrs: {
                  type: "checkbox",
                  id: "scenario-onoff",
                  checked: "",
                  "data-switch": "success",
                  "true-value": "enabled",
                  "false-value": "disabled"
                },
                domProps: {
                  checked: Array.isArray(_vm.autoResponseData.status)
                    ? _vm._i(_vm.autoResponseData.status, null) > -1
                    : _vm._q(_vm.autoResponseData.status, "enabled")
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.autoResponseData.status,
                      $$el = $event.target,
                      $$c = $$el.checked ? "enabled" : "disabled"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.autoResponseData,
                            "status",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.autoResponseData,
                            "status",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.autoResponseData, "status", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", {
                attrs: {
                  for: "scenario-onoff",
                  "data-on-label": "オン",
                  "data-off-label": "オフ"
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "form-group d-flex flex-column m-0" },
            [
              _c(
                "label",
                { staticClass: "mb10" },
                [_vm._v("キーワード"), _c("required-mark")],
                1
              ),
              _vm._v(" "),
              _c("b-form-tags", {
                staticClass: "bot-tag",
                class: _vm.errors.first("bot-tag") ? "invalid-box" : "",
                attrs: {
                  size: "md",
                  limit: 10,
                  "input-id": "tags-limit",
                  placeholder: "キーワードを入力してください",
                  separator: " ,;",
                  "tag-validator": _vm.tagValidator,
                  "invalid-tag-text": "無効なタグ",
                  duplicateTagText: "タグはすでに存在します",
                  limitTagsText: "キーワード数が上限に達しました",
                  "add-on-change": "",
                  "add-button-text": "追加"
                },
                model: {
                  value: _vm.autoResponseData.keywords,
                  callback: function($$v) {
                    _vm.$set(_vm.autoResponseData, "keywords", $$v)
                  },
                  expression: "autoResponseData.keywords"
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.autoResponseData.keywords,
                    expression: "autoResponseData.keywords"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                attrs: {
                  type: "hidden",
                  name: "keywords",
                  "data-vv-as": "キーワード"
                },
                domProps: { value: _vm.autoResponseData.keywords },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.autoResponseData,
                      "keywords",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm.error
                ? _c("span", { staticClass: "invalid-box-label" }, [
                    _c("b", [_vm._v(_vm._s(_vm.error.keyword))]),
                    _vm._v("のキーワードが"),
                    _c("b", [_vm._v(_vm._s(_vm.error.name))]),
                    _vm._v("で設定されているため設定できません。")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("error-message", {
                staticClass: "w-100",
                attrs: { message: _vm.errors.first("keywords") }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(3),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "custom-control custom-radio custom-control-inline"
              },
              [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "bizHourEnabledOn",
                    name: "bizHourEnabled",
                    value: "default"
                  },
                  domProps: { checked: _vm.autoResponseData.biz_hours.enabled },
                  on: {
                    change: function($event) {
                      _vm.autoResponseData.biz_hours.enabled = true
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "bizHourEnabledOn" }
                  },
                  [_vm._v("指定する")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "custom-control custom-radio custom-control-inline"
              },
              [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "bizHourEnabledOff",
                    name: "bizHourEnabled",
                    value: "basic"
                  },
                  domProps: {
                    checked: !_vm.autoResponseData.biz_hours.enabled
                  },
                  on: {
                    change: function($event) {
                      _vm.autoResponseData.biz_hours.enabled = false
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "bizHourEnabledOff" }
                  },
                  [_vm._v("指定しない")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("label", [_vm._v("曜日")]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "checkbox-inline" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isCheckedAllWeekday,
                        expression: "isCheckedAllWeekday"
                      }
                    ],
                    staticClass: "week_checkbox",
                    attrs: {
                      type: "checkbox",
                      value: "1",
                      autocomplete: "off",
                      disabled: !_vm.autoResponseData.biz_hours.enabled
                        ? "disabled"
                        : false
                    },
                    domProps: {
                      checked: Array.isArray(_vm.isCheckedAllWeekday)
                        ? _vm._i(_vm.isCheckedAllWeekday, "1") > -1
                        : _vm.isCheckedAllWeekday
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.isCheckedAllWeekday,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.isCheckedAllWeekday = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isCheckedAllWeekday = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isCheckedAllWeekday = $$c
                          }
                        },
                        _vm.selectAllWeekdays
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [_vm._v("全選択")])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _vm._l(_vm.weekdays, function(weekday, index) {
                    return [
                      _c(
                        "div",
                        { key: index, staticClass: "mr-2 checkbox-inline" },
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.autoResponseData.biz_hours.weekdays,
                                  expression:
                                    "autoResponseData.biz_hours.weekdays"
                                }
                              ],
                              staticClass: "week_checkbox",
                              attrs: {
                                type: "checkbox",
                                name: "bizHourWeekday",
                                autocomplete: "off",
                                disabled: !_vm.autoResponseData.biz_hours
                                  .enabled
                                  ? "disabled"
                                  : false
                              },
                              domProps: {
                                value: weekday.value,
                                checked: Array.isArray(
                                  _vm.autoResponseData.biz_hours.weekdays
                                )
                                  ? _vm._i(
                                      _vm.autoResponseData.biz_hours.weekdays,
                                      weekday.value
                                    ) > -1
                                  : _vm.autoResponseData.biz_hours.weekdays
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.autoResponseData.biz_hours.weekdays,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = weekday.value,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.autoResponseData.biz_hours,
                                          "weekdays",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.autoResponseData.biz_hours,
                                          "weekdays",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.autoResponseData.biz_hours,
                                      "weekdays",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-1" }, [
                              _vm._v(_vm._s(weekday.name))
                            ])
                          ])
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("divider"),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("label", [_vm._v("時間帯")]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex mb-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-radio custom-control-inline"
                },
                [
                  _c("input", {
                    staticClass: "custom-control-input",
                    attrs: {
                      type: "radio",
                      id: "bizHourFullTimeOn",
                      name: "bizHourFullTime",
                      value: "default",
                      disabled: !_vm.autoResponseData.biz_hours.enabled
                        ? "disabled"
                        : false
                    },
                    domProps: {
                      checked: _vm.autoResponseData.biz_hours.is_all_day
                    },
                    on: {
                      change: function($event) {
                        return _vm.selectAllDay(true)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "bizHourFullTimeOn" }
                    },
                    [_vm._v("全ての時間")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-radio custom-control-inline"
                },
                [
                  _c("input", {
                    staticClass: "custom-control-input",
                    attrs: {
                      type: "radio",
                      id: "bizHourFullTimeOff",
                      name: "bizHourFullTime",
                      value: "basic",
                      disabled: !_vm.autoResponseData.biz_hours.enabled
                        ? "disabled"
                        : false
                    },
                    domProps: {
                      checked: !_vm.autoResponseData.biz_hours.is_all_day
                    },
                    on: {
                      change: function($event) {
                        return _vm.selectAllDay(false)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "bizHourFullTimeOff" }
                    },
                    [_vm._v("時間帯を指定")]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("datetime", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    name: "bizHourFullTimeStart",
                    "input-class": "form-control",
                    type: "time",
                    phrases: { ok: "確定", cancel: "閉じる" },
                    placeholder: "日付を選択してください",
                    "value-zone": "Asia/Tokyo",
                    "data-vv-as": "開始日時",
                    disabled: _vm.showInputDateTime ? "disabled" : false
                  },
                  on: {
                    input: function($event) {
                      return _vm.onBizHoursStartTimeChanged()
                    }
                  },
                  model: {
                    value: _vm.autoResponseData.biz_hours.time.start,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.autoResponseData.biz_hours.time,
                        "start",
                        $$v
                      )
                    },
                    expression: "autoResponseData.biz_hours.time.start"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mx-2 my-auto" }, [_vm._v("から")]),
                _vm._v(" "),
                _c("datetime", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    name: "bizHourFullTimeEnd",
                    "input-class": "form-control",
                    type: "time",
                    phrases: { ok: "確定", cancel: "閉じる" },
                    placeholder: "日付を選択してください",
                    "value-zone": "Asia/Tokyo",
                    "min-datetime": _vm.autoResponseData.biz_hours.time.start,
                    "data-vv-as": "終了日時",
                    disabled: _vm.showInputDateTime ? "disabled" : false
                  },
                  on: {
                    input: function($event) {
                      return _vm.onBizHoursEndTimeChanged()
                    }
                  },
                  model: {
                    value: _vm.autoResponseData.biz_hours.time.end,
                    callback: function($$v) {
                      _vm.$set(_vm.autoResponseData.biz_hours.time, "end", $$v)
                    },
                    expression: "autoResponseData.biz_hours.time.end"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-border" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("メッセージ本文")]),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _c("modal-select-template", {
                  attrs: { id: "modal-template" },
                  on: { selectTemplate: _vm.onSelectTemplate }
                }),
                _vm._v(" "),
                _vm._l(_vm.autoResponseData.messages, function(item, index) {
                  return _c("message-editor", {
                    key: index,
                    attrs: {
                      allowCreateFromTemplate: true,
                      data: item,
                      index: index,
                      messagesCount: _vm.autoResponseData.messages.length
                    },
                    on: {
                      input: _vm.onMessageContentChanged,
                      selectTemplate: _vm.selectTemplate,
                      remove: _vm.removeContent,
                      moveUp: _vm.moveUp,
                      moveDown: _vm.moveDown
                    }
                  })
                }),
                _vm._v(" "),
                _c("div", [
                  _vm.autoResponseData.messages.length <
                  _vm.MAX_AUTO_RESPONSE_MESSAGE
                    ? _c(
                        "div",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.addMoreMessageContentDistribution }
                        },
                        [
                          _c("i", { staticClass: "uil-plus" }),
                          _c("span", [_vm._v(" メッセージ追加")])
                        ]
                      )
                    : _vm._e()
                ])
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "div",
            {
              staticClass: "btn btn-success fw-120",
              on: {
                click: function($event) {
                  return _vm.submitCreate()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(!_vm.auto_response_id ? "登録" : "保存") +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } }),
        _vm._v(" "),
        _c("message-preview")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("基本設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", [_vm._v("反応するキーワードを設定する")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-1" }, [
      _c("small", { staticClass: "text-muted font-12" }, [
        _vm._v(
          "キーワードはコンマ(半角)区切りで複数設定可能です。【例】キーワード01,キーワード02,キーワード03"
        ),
        _c("br"),
        _vm._v("\n            タグの長さは1〜20文字です")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", [_vm._v("反応する時間帯を設定する")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", [_vm._v("反応時のアクションを設定する")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "btn btn-primary",
          attrs: { "data-toggle": "modal", "data-target": "#modal-template" }
        },
        [_vm._v("テンプレートから作成")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }