var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-template modal-common01",
      attrs: {
        id: _vm.id,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body mh-100 overflow-hidden" }, [
            _c("div", { staticClass: "bg-light sticker-picker m-n3" }, [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("sticker-select-package", {
                    ref: "stickerSelected",
                    on: { input: _vm.changePackageId }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bg-white", staticStyle: { height: "400px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-100 overflow-y-scroll overflow-auto-shadow overflow-scroll-touch start",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c("div", { staticClass: "sticker-list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              margin: "0px auto",
                              "flex-wrap": "wrap",
                              width: "540px"
                            }
                          },
                          _vm._l(_vm.stickers, function(sticker, index) {
                            return _c("sticker", {
                              key: index,
                              attrs: {
                                sticker: sticker,
                                animation: _vm.animation
                              },
                              on: { input: _vm.selectSticker }
                            })
                          }),
                          1
                        )
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("スタンプを選択")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }