var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "text-right",
        on: {
          click: function($event) {
            return _vm.expand()
          }
        }
      },
      [
        _c("span", { staticClass: "float-left" }, [_vm._v("画像")]),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-arrow-expand",
          attrs: { "aria-hidden": "true" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-container",
        class: _vm.name + "-expand",
        staticStyle: { "margin-top": "10px", display: "none" }
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "card-header" }, [_vm._v("Image")]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.url,
                  expression: "url"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "hidden" },
              domProps: { value: _vm.url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.url = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                _vm._v("画像")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-submit btn-block",
                    attrs: {
                      type: "button",
                      "data-toggle": "modal",
                      "data-target": "#modalSelectMedia-" + _vm.name
                    }
                  },
                  [_vm._v("\n              画像を選択する\n            ")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                _vm._v("サイズ変更")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.aspectModeParams,
                        expression: "aspectModeParams"
                      }
                    ],
                    staticClass: "w-100 form-control",
                    attrs: { type: "text" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.aspectModeParams = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeAspectMode
                      ]
                    }
                  },
                  _vm._l(_vm.aspectModesOption, function(val, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: val } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(val) +
                            "\n              "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "card-header" }, [_vm._v("アクション設定")]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("action-editor", {
                  attrs: {
                    supports: [
                      "postback",
                      "uri",
                      "message",
                      "datetimepicker",
                      "survey"
                    ],
                    name: _vm.name,
                    value: _vm.action,
                    showTitle: false,
                    requiredLabel: false
                  },
                  on: {
                    input: function($event) {
                      _vm.action = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("modal-select-media", {
          attrs: {
            data: { type: "image" },
            id: "modalSelectMedia-" + _vm.name
          },
          on: { input: _vm.uploadImage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }