var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-wrapper" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.currentValue,
            expression: "currentValue",
            modifiers: { trim: true }
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: _vm.validate,
            expression: "validate"
          }
        ],
        class: _vm.classes,
        attrs: { type: _vm.type, placeholder: _vm.placeholder, name: _vm.name },
        domProps: { value: _vm.currentValue },
        on: {
          focus: _vm.showSuggest,
          blur: [
            function($event) {
              _vm.onFocus = false
            },
            function($event) {
              return _vm.$forceUpdate()
            }
          ],
          keyup: _vm.showSuggest,
          change: _vm.change,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentValue = $event.target.value.trim()
          }
        }
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.show || _vm.onFocus
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.hideSuggest,
                    expression: "hideSuggest"
                  }
                ],
                staticClass: "recent-contents"
              },
              [
                _c(
                  "div",
                  { staticClass: "recent-contents-list" },
                  _vm._l(_vm.suggestList, function(v, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        staticClass: "content suggest-contents",
                        on: {
                          click: function($event) {
                            return _vm.inputFromSuggest(v)
                          }
                        }
                      },
                      [_vm._v(_vm._s(v))]
                    )
                  }),
                  0
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }