var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "carousel-list" }, [
      _c(
        "div",
        { staticClass: "d-inline-flex align-items-center" },
        _vm._l(_vm.data, function(item, index) {
          return _c("div", { key: index, staticClass: "carousel-content" }, [
            item.imageUrl
              ? _c("div", {
                  staticClass: "carousel-thumb",
                  style: { backgroundImage: "url(" + item.imageUrl + ")" }
                })
              : _c("div", { staticClass: "carousel-thumb-default" }, [
                  _vm._v("(画像未登録)")
                ]),
            _vm._v(" "),
            item.action && item.action.label
              ? _c("div", { staticClass: "carousel-label" }, [
                  _vm._v(_vm._s(item.action.label))
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }