var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showTitle
      ? _c("div", [
          _c(
            "label",
            { staticClass: "w-100" },
            [
              _vm._v("\n      ラベル\n      "),
              _vm.requiredLabel ? _c("required-mark") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.label,
                    expression: "label",
                    modifiers: { trim: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.requiredLabel && _vm.showTitle },
                    expression: "{ required: requiredLabel && showTitle }"
                  }
                ],
                staticClass: "w-100 form-control",
                attrs: {
                  name: _vm.name + "_label",
                  placeholder: "ラベルを入力してください",
                  type: "text",
                  maxlength: "12",
                  "data-vv-as": "ラベル"
                },
                domProps: { value: _vm.label },
                on: {
                  keyup: _vm.onValueChanged,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.label = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c("error-message", {
                attrs: { message: _vm.errors.first(_vm.name + "_label") }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "mt4" },
      [_vm._v("\n    電話番号\n    "), _c("required-mark")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.uri,
              expression: "uri"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|numeric|min:10|max:11",
              expression: "'required|numeric|min:10|max:11'"
            }
          ],
          staticClass: "form-control",
          attrs: {
            name: _vm.name + "_value",
            type: "text",
            maxlength: "15",
            placeholder: "09044445555",
            "data-vv-as": "電話番号"
          },
          domProps: { value: _vm.uri },
          on: {
            blur: _vm.onValueChanged,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.uri = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: { message: _vm.errors.first(_vm.name + "_value") }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }