var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.status === "wait"
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle text-info" }),
          _vm._v(" 空室待ち ")
        ])
      : _vm.status === "cancelled"
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle" }),
          _vm._v(" キャンセル済 ")
        ])
      : _c("span", [
          _c("i", { staticClass: "mdi mdi-circle text-success" }),
          _vm._v(" 完了")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }