var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xl-12" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        { staticClass: "tab-pane show active", attrs: { id: "users" } },
        [_c("survey-friends", { attrs: { survey_id: _vm.id } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-pane", attrs: { id: "answers" } },
        [_c("survey-responses", { attrs: { survey_id: _vm.id } })],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: {
              href: "#users",
              "data-toggle": "tab",
              "aria-expanded": "true"
            }
          },
          [
            _c("i", { staticClass: "mdi mdi-home-variant d-md-none d-block" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-block" }, [
              _vm._v("回答者一覧")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "#answers",
              "data-toggle": "tab",
              "aria-expanded": "false"
            }
          },
          [
            _c("i", {
              staticClass: "mdi mdi-account-circle d-md-none d-block"
            }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-block" }, [
              _vm._v("回答一覧")
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }