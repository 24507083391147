var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var validate = ref.validate
              var invalid = ref.invalid
              return [
                _c(
                  "div",
                  { staticClass: "card" },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3" },
                          [_vm._v("氏名"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "氏名",
                                rules: "required|max:255"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.staffFormData.name,
                                              expression: "staffFormData.name",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[name]",
                                            placeholder: "入力してください",
                                            maxlength: "256"
                                          },
                                          domProps: {
                                            value: _vm.staffFormData.name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "name",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "col-xl-3" }, [
                          _vm._v("住所")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "住所", rules: "max:255" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.staffFormData.address,
                                              expression:
                                                "staffFormData.address",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[address]",
                                            placeholder: "入力してください",
                                            maxlength: "256"
                                          },
                                          domProps: {
                                            value: _vm.staffFormData.address
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "address",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "col-xl-3" }, [
                          _vm._v("電話番号")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "電話番号",
                                rules: "numeric|min:10|max:11"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value:
                                                _vm.staffFormData.phone_number,
                                              expression:
                                                "staffFormData.phone_number",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[phone_number]",
                                            placeholder: "入力してください",
                                            maxlength: "12"
                                          },
                                          domProps: {
                                            value:
                                              _vm.staffFormData.phone_number
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "phone_number",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3" },
                          [_vm._v("メールアドレス"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "メールアドレス",
                                rules: "required|email|max:255"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.staffFormData.email,
                                              expression: "staffFormData.email",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[email]",
                                            placeholder: "入力してください",
                                            maxlength: "256"
                                          },
                                          domProps: {
                                            value: _vm.staffFormData.email
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "email",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3" },
                          [_vm._v("パスワード"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "パスワード",
                                rules: "required|min:8|max:128",
                                vid: "password"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.staffFormData.password,
                                              expression:
                                                "staffFormData.password",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[password]",
                                            placeholder: "入力してください",
                                            maxlength: "256"
                                          },
                                          domProps: {
                                            value: _vm.staffFormData.password
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "password",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3" },
                          [_vm._v("パスワード（確認用）"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-9" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "パスワード（確認用）",
                                rules: "required|confirmed:password"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value:
                                                _vm.staffFormData
                                                  .password_confirmation,
                                              expression:
                                                "staffFormData.password_confirmation",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "user[password_confirmation]",
                                            placeholder: "入力してください",
                                            maxlength: "256"
                                          },
                                          domProps: {
                                            value:
                                              _vm.staffFormData
                                                .password_confirmation
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.staffFormData,
                                                "password_confirmation",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.$forceUpdate()
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "error-explanation" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("label", { staticClass: "col-xl-3" }, [
                          _vm._v("有効化")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xl-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.staffFormData.status,
                                expression: "staffFormData.status"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "enabledCheck",
                              checked: "",
                              "data-switch": "success",
                              name: "user[status]",
                              "true-value": "active",
                              "false-value": "blocked"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.staffFormData.status)
                                ? _vm._i(_vm.staffFormData.status, null) > -1
                                : _vm._q(_vm.staffFormData.status, "active")
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.staffFormData.status,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "active" : "blocked"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffFormData,
                                        "status",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffFormData,
                                        "status",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.staffFormData, "status", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", {
                            attrs: {
                              for: "enabledCheck",
                              "data-on-label": "有",
                              "data-off-label": "無"
                            }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-footer row-form-btn d-flex" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-success fw-120",
                            attrs: { disabled: invalid },
                            on: {
                              click: function($event) {
                                validate().then(_vm.onSubmit)
                              }
                            }
                          },
                          [_vm._v("登録")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("loading-indicator", { attrs: { loading: _vm.loading } })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }