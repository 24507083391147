var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ckeditor", {
        attrs: {
          config: _vm.editorConfig,
          editor: _vm.editor,
          disabled: _vm.disabled ? true : false
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }