var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modalMessagePreview",
      staticClass: "modal fade",
      attrs: {
        id: "modalMessagePreview",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "info-header-modalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { key: _vm.contentKey, staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "info-header-modalLabel" }
              },
              [_vm._v(_vm._s(_vm.primaryLabel) + "プレビュー")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body modal-body-main" }, [
            _c(
              "div",
              { staticClass: "message-area" },
              [
                _c("TextMessage", {
                  attrs: {
                    data:
                      "" + (_vm.message.content ? _vm.message.content.text : "")
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("h5", [_vm._v("URLクリック測定")]),
            _vm._v(" "),
            _c("table", { staticClass: "table table-striped" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("サイト")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("この" + _vm._s(_vm.primaryLabel))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "hidden-xs" })
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("th", [
                    _c("b", [_vm._v(_vm._s(_vm.message.real_site_name))]),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.message.real_site_url,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.message.real_site_url))]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("b", [_vm._v(_vm._s(_vm.message.visitor_count))]),
                    _vm._v("人訪問 / "),
                    _c("b", [_vm._v(_vm._s(_vm.message.receiver_count))]),
                    _vm._v("人"),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "(計" + _vm._s(_vm.message.click_count) + "クリック)"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "hidden-xs" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-warning btn-sm",
                        attrs: { href: "/user/sites/" + _vm.message.site_id }
                      },
                      [_vm._v("サイトCV")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }