var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("folder-left", {
              attrs: {
                type: "template_message",
                data: _vm.folders,
                isPc: _vm.isPc,
                selectedFolder: _vm.selectedFolderIndex
              },
              on: {
                changeSelectedFolder: _vm.onSelectedFolderChanged,
                submitUpdateFolder: _vm.submitUpdateFolder,
                submitCreateFolder: _vm.submitCreateFolder
              }
            }),
            _vm._v(" "),
            _c("div", { key: _vm.contentKey, staticClass: "flex-grow-1" }, [
              _vm.folders && _vm.folders.length && _vm.curFolder
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        href:
                          _vm.rootPath +
                          "/user/templates/new?folder_id=" +
                          _vm.curFolder.id
                      }
                    },
                    [
                      _c("i", { staticClass: "uil-plus" }),
                      _vm._v(" 新規作成\n        ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.curFolder
                ? _c("div", { staticClass: "mt-2" }, [
                    _c("table", { staticClass: "table table-centered mb-0" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.curFolder.templates, function(
                          template,
                          index
                        ) {
                          return _c("tr", { key: template.id }, [
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "fw-300 text-truncate" },
                                [_vm._v(_vm._s(template.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "fw-150 d-flex justify-content-center align-items-center template-message-count-wrapper"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "template-message-count" },
                                  [
                                    _vm._v(
                                      _vm._s(template.template_messages_count)
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn btn-sm btn-light my-1",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#modalTemplatePreview"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.curTemplateIndex = index
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    プレビュー\n                  "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-menu" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        href:
                                          _vm.rootPath +
                                          "/user/templates/" +
                                          template.id +
                                          "/edit",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("テンプレートを編集")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#modalCopyTemplate"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.curTemplateIndex = index
                                        }
                                      }
                                    },
                                    [_vm._v("テンプレートをコピー")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#modalDeleteTemplate"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.curTemplateIndex = index
                                        }
                                      }
                                    },
                                    [_vm._v("テンプレートを削除")]
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "mxw-200 text-truncate" },
                                  [_vm._v(_vm._s(_vm.curFolder.name))]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-sm" }, [
                                _vm._v(
                                  _vm._s(_vm.formattedDate(template.created_at))
                                )
                              ])
                            ])
                          ])
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.curFolder.templates.length === 0
                      ? _c("div", { staticClass: "text-center mt-5" }, [
                          _c("b", [_vm._v("テンプレートはありません。")])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このフォルダーを削除してもよろしいですか？",
          id: "modalDeleteFolder",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteFolder },
        scopedSlots: _vm._u(
          [
            _vm.curFolder
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("フォルダー名：" + _vm._s(_vm.curFolder.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このテンプレートを削除してもよろしいですか？",
          id: "modalDeleteTemplate",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteTemplate },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curTemplate
                  ? _c("div", [
                      _vm._v("\n        テンプレート名："),
                      _c("b", [_vm._v(_vm._s(_vm.curTemplate.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このテンプレートをコピーしてもよろしいですか？",
          id: "modalCopyTemplate",
          type: "confirm"
        },
        on: { confirm: _vm.submitCopyTemplate },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curTemplate
                  ? _c("div", [
                      _vm._v("\n        テンプレート名："),
                      _c("b", [_vm._v(_vm._s(_vm.curTemplate.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.curTemplate
        ? _c("modal-template-preview", {
            attrs: { templateId: _vm.curTemplate.id }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("テンプレート名")]),
        _vm._v(" "),
        _c("th", [_vm._v("メッセージ数")]),
        _vm._v(" "),
        _c("th", [_vm._v("操作")]),
        _vm._v(" "),
        _c("th", [_vm._v("フォルダー")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                      操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }