var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.contentKey, staticClass: "mt-2" },
    [
      _vm._l(_vm.objectLists, function(object, index) {
        return _c(
          "div",
          { key: index, staticClass: "card border-primary border" },
          [
            _c("div", { staticClass: "card-header d-flex" }, [
              _c("h5", { staticClass: "survey-title" }, [
                _vm._v("質問 " + _vm._s(index + 1))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ml-auto" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn btn-sm btn-light",
                    on: {
                      click: function($event) {
                        return _vm.copyObject(index)
                      }
                    }
                  },
                  [_c("i", { staticClass: "mdi mdi-content-copy" })]
                ),
                _vm._v(" "),
                index > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: {
                          click: function($event) {
                            return _vm.moveUpObject(index)
                          }
                        }
                      },
                      [_c("i", { staticClass: "dripicons-chevron-up" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.objectLists.length - 1
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-light",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.moveDownObject(index)
                          }
                        }
                      },
                      [_c("i", { staticClass: "dripicons-chevron-down" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.objectLists.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: {
                          click: function($event) {
                            return _vm.removeObject(index)
                          }
                        }
                      },
                      [_c("i", { staticClass: "mdi mdi-delete" })]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group d-flex mt-2 align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fw-200",
                      on: {
                        click: function($event) {
                          object.editing = !object.editing
                        }
                      }
                    },
                    [_vm._v("質問タイプ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: object.type,
                          expression: "object.type"
                        }
                      ],
                      staticClass: "form-control flex-grow-1",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              object,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            object.content = null
                            object.editing = true
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "text" } }, [
                        _vm._v("記述式（１行回答）")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "textarea" } }, [
                        _vm._v("段落（複数行回答）")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "pulldown" } }, [
                        _vm._v("プルダウン")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "radio" } }, [
                        _vm._v("ラジオボタン")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "checkbox" } }, [
                        _vm._v("チェックボックス")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "image" } }, [
                        _vm._v("画像")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "pdf" } }, [
                        _vm._v("PDF")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "date" } }, [
                        _vm._v("日付")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "time" } }, [
                        _vm._v("時刻")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "datetime" } }, [
                        _vm._v("日付・時刻")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-checkbox ml-2 text-nowrap"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: object.required,
                            expression: "object.required"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "checkbox",
                          id: "questionRequiredCheck" + index
                        },
                        domProps: {
                          checked: Array.isArray(object.required)
                            ? _vm._i(object.required, null) > -1
                            : object.required
                        },
                        on: {
                          change: function($event) {
                            var $$a = object.required,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    object,
                                    "required",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    object,
                                    "required",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(object, "required", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "questionRequiredCheck" + index }
                        },
                        [_vm._v(" 必須")]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  object.type === "text"
                    ? _c("survey-question-editor-text", {
                        attrs: {
                          name: _vm.name + "-text-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "textarea"
                    ? _c("survey-question-editor-textarea", {
                        attrs: {
                          name: _vm.name + "-textarea-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "radio"
                    ? _c("survey-question-editor-radio", {
                        attrs: {
                          name: _vm.name + "-radio-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "checkbox"
                    ? _c("survey-question-editor-checkbox", {
                        attrs: {
                          name: _vm.name + "-checkbox-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "pulldown"
                    ? _c("survey-question-editor-pulldown", {
                        attrs: {
                          name: _vm.name + "-pulldown-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "image"
                    ? _c("survey-question-editor-image", {
                        attrs: {
                          name: _vm.name + "-image-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "pdf"
                    ? _c("survey-question-editor-pdf", {
                        attrs: {
                          name: _vm.name + "-pdf-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "date"
                    ? _c("survey-question-editor-date", {
                        attrs: {
                          name: _vm.name + "-date-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "datetime"
                    ? _c("survey-question-editor-datetime", {
                        attrs: {
                          name: _vm.name + "-date-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : object.type === "time"
                    ? _c("survey-question-editor-time", {
                        attrs: {
                          name: _vm.name + "-date-" + index,
                          content: object.content
                        },
                        on: {
                          input: function($event) {
                            object.content = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      }),
      _vm._v(" "),
      _vm.objectLists.length < _vm.maxObject
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.addNewObject()
                  }
                }
              },
              [_c("i", { staticClass: "uil-plus" }), _vm._v(" 質問追加")]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }