var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.contentKey, staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-3" }, [
        _c(
          "div",
          {
            staticClass: "btn btn-secondary ml-1 d-block",
            attrs: {
              "data-toggle": "modal",
              "data-target": "#modalSelectReminder"
            }
          },
          [
            _c("span", { staticClass: "max-1-lines" }, [
              _vm._v(
                _vm._s(
                  _vm.reminder.id ? _vm.reminder.name : "リマインダを選択する"
                )
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reminder.id,
                  expression: "reminder.id"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                type: "hidden",
                name: "reminder_id",
                "data-vv-as": "リマインダ"
              },
              domProps: { value: _vm.reminder.id },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.reminder, "id", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("error-message", {
              staticClass: "w-100",
              attrs: { message: _vm.errors.first("reminder_id") }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-9 d-flex flex-start" }, [
        _c(
          "div",
          { staticClass: "flex-grow-1" },
          [
            _c("datetime", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "mr-1",
              attrs: {
                name: "reminder_goal",
                "input-class": "form-control",
                type: "date",
                phrases: { ok: "確定", cancel: "閉じる" },
                placeholder: "日付を選択してください",
                "min-datetime": _vm.currentDate,
                "value-zone": "Asia/Tokyo",
                "data-vv-as": "ゴール日",
                zone: "Asia/Tokyo"
              },
              model: {
                value: _vm.goal,
                callback: function($$v) {
                  _vm.goal = $$v
                },
                expression: "goal"
              }
            }),
            _vm._v(" "),
            _c("error-message", {
              attrs: { message: _vm.errors.first("reminder_goal") }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn btn-light mr-1 mb-auto",
            attrs: {
              "data-toggle": "modal",
              "data-target": "#modalReminderPreview",
              hidden: ""
            }
          },
          [_vm._v("\n      プレビュー\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn btn-success fw-120 mb-auto",
            on: {
              click: function($event) {
                return _vm.submit()
              }
            }
          },
          [_vm._v("開始")]
        )
      ]),
      _vm._v(" "),
      _c("modal-select-reminder", {
        attrs: { id: "modalSelectReminder" },
        on: {
          selectReminder: function($event) {
            return _vm.onSelectReminder($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.reminder
        ? _c("modal-reminder-preview", {
            attrs: { reminder_id: _vm.reminder.id }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }