var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card upload-container my-auto d-flex flex-column",
      on: {
        drop: function($event) {
          $event.preventDefault()
          return _vm.addMedia($event, "drop")
        },
        dragover: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card-body flex-grow-1 d-flex flex-column justify-content-center align-items-center position-relative",
          on: {
            drop: function($event) {
              $event.preventDefault()
              return _vm.addFile.apply(null, arguments)
            },
            dragover: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _vm.isPreview
            ? _c("div", { staticClass: "text-center my-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-delete-media",
                    on: {
                      click: function($event) {
                        return _vm.deleteMedia()
                      }
                    }
                  },
                  [_c("span", { staticClass: "close" }, [_vm._v("×")])]
                ),
                _vm._v(" "),
                _vm.mediaData.type === "pdf"
                  ? _c("img", {
                      staticClass: "fw-120 fh-120",
                      attrs: { src: _vm.fileURL }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.mediaData.type === "image" ||
                _vm.mediaData.type === "richmenu" ||
                _vm.mediaData.type === "imagemap"
                  ? _c("img", { attrs: { src: _vm.fileURL } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.mediaData.type === "audio"
                  ? _c(
                      "audio",
                      {
                        ref: "audio",
                        attrs: { controls: "" },
                        on: { loadedmetadata: _vm.onTimeUpdate }
                      },
                      [_c("source", { attrs: { src: _vm.fileURL } })]
                    )
                  : _vm.mediaData.type === "video"
                  ? _c(
                      "video",
                      {
                        attrs: {
                          width: "320",
                          height: "240",
                          controls: "",
                          autoplay: ""
                        }
                      },
                      [
                        _c("source", {
                          attrs: { src: _vm.fileURL, type: "video/mp4" }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            : _c(
                "div",
                { staticClass: "text-center text-md my-auto" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-file fw-200" }, [
                    _c(
                      "div",
                      { staticClass: "custom-file-input h-100 w-100" },
                      [
                        _c("input", {
                          ref: "file",
                          staticClass: "fh-50",
                          attrs: {
                            accept: _vm.getAcceptedMineTypes(),
                            maxsize: _vm.getMaxSize(),
                            type: "file"
                          },
                          on: {
                            change: function($event) {
                              return _vm.addMedia($event, "input")
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "custom-file-label text-left" },
                      [_vm._v("ファイルを選択")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.errorMessage
                    ? _c("span", { staticClass: "w-100 error" }, [
                        _vm._v(_vm._s(_vm.errorMessage))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("media-upload-hint", {
                    staticClass: "m-4",
                    attrs: { type: _vm.uploadRequiredType }
                  })
                ],
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "text-right w-100 mt-auto" }, [
            _vm.isPreview
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-info fw-120",
                    on: { click: _vm.handleUpload }
                  },
                  [_vm._v("確認する")]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("label", [
        _vm._v("ここにファイルをドラッグ＆ドロップ"),
        _c("br"),
        _vm._v("または")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }