var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass:
          "row-ttl01 flex ai_center mb40 flex-wrap justify-content-between"
      },
      [
        _c("h3", { staticClass: "hdg3" }, [_vm._v("回答内容詳細")]),
        _vm._v(" "),
        _c("div", { staticClass: "row-ttl02 flex" }, [
          _c("div", { staticClass: "btn-common02 fz14" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    _vm.MIX_ROOT_PATH +
                    "/friends/" +
                    _vm.answer.line_customer.friend.id +
                    "/detail"
                }
              },
              [_vm._v("友だち詳細")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-common02 fz14" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    _vm.MIX_ROOT_PATH + "/talks/to/" + _vm.answer.channel_alias
                }
              },
              [_vm._v("トーク画面")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-common02 fz14" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    _vm.MIX_ROOT_PATH +
                    "/surveys/" +
                    _vm.answer.survey_id +
                    "/answer/" +
                    _vm.answer.id +
                    "/pdf"
                }
              },
              [_vm._v("PDFダウンロード")]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row mb40" }, [
      _c("div", { staticClass: "col-md-6 col-xs-12" }, [
        _c("h3", { staticClass: "hdg3" }, [
          _vm._v(_vm._s(_vm.answer.survey_title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [_vm._v("回答日時")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8" }, [
            _vm._v(_vm._s(_vm.answer.created))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [_vm._v("回答者名")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8" }, [
            _vm._v(
              _vm._s(
                _vm.answer.display_name
                  ? _vm.answer.display_name
                  : _vm.answer.line_name
              )
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", {}, [
      _c("h3", { staticClass: "hdg3" }, [_vm._v("回答内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "tag-scroll" }, [
        _c(
          "div",
          {
            staticClass:
              "tbl-admin01 tbl-linebot01 table-responsive fz14 text-center"
          },
          [
            _c(
              "table",
              { staticClass: "table table-hover table-message-content" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.answer.questions, function(question, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(question.content.text) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        question.answers.length > 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "vertical-align": "top",
                                  padding: "10px",
                                  position: "relative"
                                }
                              },
                              [
                                question.answers[0].content.type === "file"
                                  ? _c("div", [
                                      question.answers[0].content.content.mine_type.includes(
                                        "image/"
                                      )
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "150px",
                                              "margin-bottom": "10px"
                                            },
                                            attrs: {
                                              src:
                                                _vm.MIX_SERVEY_MEDIA_FLEXA_URL +
                                                "/" +
                                                question.answers[0].content
                                                  .content.alias
                                            }
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "150px",
                                                "font-size": "60px"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-file"
                                              })
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#00b900" },
                                            attrs: {
                                              href:
                                                _vm.MIX_SERVEY_MEDIA_FLEXA_URL +
                                                "/" +
                                                question.answers[0].content
                                                  .content.alias,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-download"
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.trimMidString(
                                                    question.answers[0].content
                                                      .content.name,
                                                    25,
                                                    10
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ])
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "max-width": "250px",
                                          "word-break": "break-word",
                                          "text-align": "center"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.autoText(
                                                question.answers[0].content
                                                  .content
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  "vertical-align": "top",
                                  padding: "10px",
                                  position: "relative",
                                  color: "#a9a9a9"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  No answer\n                "
                                )
                              ]
                            )
                      ])
                    ])
                  }),
                  0
                )
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w10" }, [_vm._v("質問")]),
        _vm._v(" "),
        _c("th", { staticClass: "w15" }, [_vm._v("回答")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }