var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "div",
              { staticClass: "card-header d-flex align-items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn btn-success mr-2",
                    on: {
                      click: function($event) {
                        return _vm.openNew()
                      }
                    }
                  },
                  [_c("i", { staticClass: "uil-plus" }), _vm._v(" 新規作成")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto d-flex" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.queryParams.status_eq,
                          expression: "queryParams.status_eq"
                        }
                      ],
                      staticClass: "form-control fw-150 mr-1",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.queryParams,
                              "status_eq",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            return _vm.search()
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("すべて")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "enabled" } }, [
                        _vm._v("稼働中")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "disabled" } }, [
                        _vm._v("停止中")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "draft" } }, [
                        _vm._v("下書き")
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body mvh-50" }, [
              _c("div", { staticClass: "table-responsive mh-155" }, [
                _c("table", { staticClass: "table table-centered mb-0" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.scenarios, function(scenario, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              scenario.mode === "elapsed_time"
                                ? "経過時間"
                                : "時刻"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", { staticClass: "scenario_title" }, [
                            _vm._v(_vm._s(scenario.title))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(scenario.sending_friend_count) +
                              "人 "
                          ),
                          _c("span", { staticClass: "font-13" }, [
                            _vm._v("(購読中)")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(scenario.sent_friend_count) +
                              "人 "
                          ),
                          _c("span", { staticClass: "font-13" }, [
                            _vm._v("(購読済み)")
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("scenario-status", {
                              attrs: { status: scenario.status }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "btn btn-light",
                              on: {
                                click: function($event) {
                                  return _vm.openMessageIndex(scenario)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    メッセージ一覧（" +
                                  _vm._s(
                                    scenario.scenario_messages_count || 0
                                  ) +
                                  "）\n                  "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "btn-group" }, [
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    role: "button",
                                    href:
                                      _vm.rootPath +
                                      "/user/scenarios/" +
                                      scenario.id +
                                      "/edit"
                                  }
                                },
                                [_vm._v("シナリオを編集する")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    role: "button",
                                    "data-toggle": "modal",
                                    "data-target": "#modalCopyScenario"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curScenarioIndex = index
                                    }
                                  }
                                },
                                [_vm._v("シナリオをコピー")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    role: "button",
                                    "data-toggle": "modal",
                                    "data-target": "#modalSendScenarioToTesters"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curScenarioIndex = index
                                    }
                                  }
                                },
                                [_vm._v("テスト配信")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    role: "button",
                                    "data-toggle": "modal",
                                    "data-target": "#modalDeleteScenario"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curScenarioIndex = index
                                    }
                                  }
                                },
                                [_vm._v("シナリオを削除")]
                              )
                            ])
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-center mt-3 text-center"
                },
                [
                  _vm.totalRows > _vm.perPage
                    ? _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          "aria-controls": "my-table"
                        },
                        on: { change: _vm.loadPage },
                        model: {
                          value: _vm.queryParams.page,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "page", $$v)
                          },
                          expression: "queryParams.page"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.loading && _vm.totalRows === 0
                ? _c(
                    "div",
                    { staticClass: "text-center my-5 font-weight-bold" },
                    [_vm._v("シナリオはありません。")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: _vm.loading } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このシナリオを削除してもよろしいですか？",
          id: "modalDeleteScenario",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteScenario },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curScenario
                  ? _c("p", [
                      _vm._v("\n        シナリオ名："),
                      _c("b", [_vm._v(_vm._s(_vm.curScenario.title))]),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v(
                    "こちらのシナリオの配信予約したメッセージは停止されます。"
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このシナリオをコピーしてもよろしいですか？",
          id: "modalCopyScenario",
          type: "confirm"
        },
        on: { confirm: _vm.submitCopyScenario },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curScenario
                  ? _c("div", [
                      _vm._v("\n        シナリオ名："),
                      _c("b", [_vm._v(_vm._s(_vm.curScenario.title))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "シナリオのテスト配信のため、テスターを選択してください。",
          id: "modalSendScenarioToTesters",
          type: "confirm",
          confirmButtonDisabled: _vm.selectedTesterIds.length === 0,
          confirmButtonLabel: "テスト配信"
        },
        on: { confirm: _vm.submitSendScenarioToTesters },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curScenario
                  ? _c("div", [
                      _vm.testers && _vm.testers.length
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.testers, function(tester) {
                              return _c(
                                "div",
                                {
                                  key: "tester_" + tester.id,
                                  staticClass:
                                    "flex-1 custom-control custom-checkbox mr-2"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedTesterIds,
                                        expression: "selectedTesterIds"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "tester_" + tester.id
                                    },
                                    domProps: {
                                      value: tester.id,
                                      checked: Array.isArray(
                                        _vm.selectedTesterIds
                                      )
                                        ? _vm._i(
                                            _vm.selectedTesterIds,
                                            tester.id
                                          ) > -1
                                        : _vm.selectedTesterIds
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.selectedTesterIds,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = tester.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedTesterIds = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedTesterIds = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedTesterIds = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "tester_" + tester.id }
                                    },
                                    [_vm._v(_vm._s(tester.display_name))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", [
                            _c("span", [_vm._v("テスターはありません。")])
                          ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "mw-120" }, [_vm._v("配信方式")]),
        _vm._v(" "),
        _c("th", [_vm._v("シナリオ名")]),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center mw-230", attrs: { colspan: "2" } },
          [_vm._v("統計")]
        ),
        _vm._v(" "),
        _c("th", { staticClass: "mw-120" }, [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-200" }, [_vm._v("メッセージ")]),
        _vm._v(" "),
        _c("th", [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                      操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }