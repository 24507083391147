var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left" }, [
    _vm.type === "image"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v(" ファイル形式：JPG、JPEG、PNG ファイルサイズ：10MB以下 ")
        ])
      : _vm.type === "video"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v("\n    ファイル形式：MP4"),
          _c("br"),
          _vm._v("\n    ファイルサイズ：200MB以下\n  ")
        ])
      : _vm.type === "audio"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v("\n    ファイル形式：M4A、MP3、WAV、Ogg"),
          _c("br"),
          _vm._v("\n    ファイルサイズ：200MB以下\n  ")
        ])
      : _vm.type === "richmenu"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v("\n    ファイル形式：JPG、JPEG、PNG"),
          _c("br"),
          _vm._v("\n    ファイルサイズ：1MB以下"),
          _c("br"),
          _vm._v(
            "\n    画像サイズ：2500px × 1686px、2500px × 843px、1200px × 810px、1200px × 405px、800px × 540px、800px × 270px\n  "
          )
        ])
      : _vm.type === "pdf"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v("\n    ファイル形式：PDF"),
          _c("br"),
          _vm._v("\n    ファイルサイズ：10MB以下\n  ")
        ])
      : _vm.type === "imagemap"
      ? _c("span", { staticClass: "text-sm" }, [
          _vm._v("\n    ファイル形式：JPG、JPEG、PNG"),
          _c("br"),
          _vm._v("\n    ファイルサイズ：10MB以下"),
          _c("br"),
          _vm._v("\n    画像の幅: 1040px\n  ")
        ])
      : _c("span", [
          _vm._v(" ファイル形式：JPG、JPEG、PNG、M4A、MP3、MP4、WAV、Ogg"),
          _c("br")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }