var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("ValidationObserver", {
          ref: "observer",
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var validate = ref.validate
                var invalid = ref.invalid
                return [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("メールアドレス"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "メールアドレス",
                              rules: "required|email|max:255"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.userFormData.email,
                                            expression: "userFormData.email",
                                            modifiers: { trim: true }
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[email]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.email
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "email",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("氏名"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: { name: "氏名", rules: "required|max:255" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.userFormData.name,
                                            expression: "userFormData.name",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[name]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "name",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("住所"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: { name: "住所", rules: "required|max:255" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.userFormData.address,
                                            expression: "userFormData.address",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[address]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.address
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "address",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("電話番号"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "電話番号",
                              rules: "required|numeric|min:10|max:11"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.userFormData.phone_number,
                                            expression:
                                              "userFormData.phone_number",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[phone_number]",
                                          placeholder: "入力してください",
                                          maxlength: "12"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.phone_number
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "phone_number",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-xl-3" }, [
                        _vm._v("会社名")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: { name: "会社名", rules: "max:255" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.userFormData.company_name,
                                            expression:
                                              "userFormData.company_name",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[company_name]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.company_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "company_name",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("パスワード"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "パスワード",
                              rules: "required|min:8|max:128",
                              vid: "password"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.userFormData.password,
                                            expression: "userFormData.password",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[password]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value: _vm.userFormData.password
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "password",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-xl-3" },
                        [_vm._v("パスワード（確認用）"), _c("required-mark")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-9" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "パスワード（確認用）",
                              rules: "required|confirmed:password"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.userFormData
                                                .password_confirmation,
                                            expression:
                                              "userFormData.password_confirmation",
                                            modifiers: { trim: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "user[password_confirmation]",
                                          placeholder: "入力してください",
                                          maxlength: "256"
                                        },
                                        domProps: {
                                          value:
                                            _vm.userFormData
                                              .password_confirmation
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.userFormData,
                                              "password_confirmation",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "error-explanation" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-xl-3" }, [
                        _vm._v("有効化")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userFormData.status,
                              expression: "userFormData.status"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "enabledCheck",
                            checked: "",
                            "data-switch": "info",
                            name: "user[status]",
                            "true-value": "active",
                            "false-value": "blocked"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.userFormData.status)
                              ? _vm._i(_vm.userFormData.status, null) > -1
                              : _vm._q(_vm.userFormData.status, "active")
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.userFormData.status,
                                $$el = $event.target,
                                $$c = $$el.checked ? "active" : "blocked"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.userFormData,
                                      "status",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.userFormData,
                                      "status",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.userFormData, "status", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", {
                          attrs: {
                            for: "enabledCheck",
                            "data-on-label": "有",
                            "data-off-label": "無"
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-footer row-form-btn d-flex" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-info fw-120",
                          attrs: { disabled: invalid },
                          on: {
                            click: function($event) {
                              validate().then(_vm.onSubmit)
                            }
                          }
                        },
                        [_vm._v("登録")]
                      )
                    ]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }