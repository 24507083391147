var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group clearfix" },
    [
      _c("label", { staticClass: "col-sm-3" }, [_vm._v("画像")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-9 border-bottom" }, [
        _vm.url
          ? _c("img", {
              staticStyle: {
                width: "150px",
                "margin-bottom": "10px",
                height: "150px",
                "object-fit": "contain"
              },
              attrs: { src: _vm.url }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.url,
                expression: "url"
              }
            ],
            attrs: { type: "hidden" },
            domProps: { value: _vm.url },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.url = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-upload",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.openAddFileModal()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-upload" }),
              _vm._v(" ファイルをアップロード\n          ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-cancel",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.removeImage()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-trash" }),
              _vm._v(" Remove\n          ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("media-modal", {
        attrs: { data: _vm.media_data, id: _vm.name },
        on: { input: _vm.uploadedImg }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }