var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-delete modal-common01",
      attrs: {
        id: _vm.id ? _vm.id : "modal-alert",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content p-0" }, [
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "mb10 fz14" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer flex center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-common01 btn-modal-cancel",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: { click: _vm.ok }
              },
              [_vm._v("OK")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }