var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.avatarImgObj,
                expression: "avatarImgObj"
              }
            ],
            staticClass: "rounded-circle avatar-lg img-thumbnail",
            attrs: { alt: "profile-image" }
          })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "profile-username text-center" }, [
          _vm._v(_vm._s(_vm.friend.line_name))
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "card-header border-bottom border-primary" }, [
          _c("h4", [
            _vm._v("\n        回答一覧"),
            _c("span", { staticClass: "font-16 font-weight-normal" }, [
              _vm._v("（全" + _vm._s(_vm.responses.length) + "回）")
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          _vm._l(_vm.responses, function(response, index) {
            return _c(
              "div",
              { key: index },
              [_c("survey-response", { attrs: { response: response } })],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }