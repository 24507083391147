var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("folder-left", {
              attrs: {
                type: "stream_route",
                data: _vm.folders,
                isPc: _vm.isPc,
                selectedFolder: _vm.selectedFolderIndex
              },
              on: {
                changeSelectedFolder: _vm.onSelectedFolderChanged,
                submitUpdateFolder: _vm.submitUpdateFolder,
                submitCreateFolder: _vm.submitCreateFolder
              }
            }),
            _vm._v(" "),
            _c("div", { key: _vm.contentKey, staticClass: "flex-grow-1" }, [
              _vm.folders && _vm.folders.length && _vm.curFolder
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        href:
                          _vm.rootPath +
                          "/user/stream_routes/new?folder_id=" +
                          _vm.curFolder.id
                      }
                    },
                    [
                      _c("i", { staticClass: "uil-plus" }),
                      _vm._v(" 新規登録\n        ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.curFolder
                ? _c("div", { staticClass: "mt-2 table-responsive" }, [
                    _c(
                      "table",
                      { staticClass: "table table-centered mb-0 text-nowrap" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.curFolder.stream_routes, function(
                            streamRoute,
                            index
                          ) {
                            return _c("tr", { key: streamRoute.id }, [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "mw-300 stream-route-name-column-value"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "stream-url-text-wrap" },
                                    [
                                      _c(
                                        "label",
                                        { staticStyle: { display: "block" } },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(streamRoute.name) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group input-group-sm d-flex flex-column flex-xl-row stream-route-url-container"
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "form-control input-sm stream-route-url-input",
                                            attrs: {
                                              type: "text",
                                              disabled: ""
                                            },
                                            domProps: {
                                              value:
                                                streamRoute.stream_route_url
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "input-group-btn" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-default copy-btn",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyUrl(
                                                        streamRoute.stream_route_url
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "glyphicon glyphicon-copy uil-copy"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-default qr-btn",
                                                  attrs: {
                                                    href: "",
                                                    target: "_blank",
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      "#modalShowQRCode"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showQrCodeOfStreamRoute = streamRoute
                                                    }
                                                  }
                                                },
                                                [_vm._v("QR")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  streamRoute.actions
                                    ? _c("ChoseActionsPresentor", {
                                        attrs: {
                                          actions:
                                            streamRoute.actions[0] &&
                                            streamRoute.actions[0].data
                                              ? streamRoute.actions[0].data
                                                  .actions
                                              : {}
                                        }
                                      })
                                    : _c("span", [_vm._v("-")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-nowrap run-add-friend-action-label"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        streamRoute.run_add_friend_actions
                                          ? "無視しない"
                                          : "無視する"
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "statistic-info-container d-flex align-items-center"
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-nowrap mb-0" },
                                      [
                                        _vm._v(
                                          _vm._s(streamRoute.friend_count) +
                                            "人"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-default border p-1",
                                        attrs: {
                                          href:
                                            _vm.rootPath +
                                            "/user/stream_routes/" +
                                            streamRoute.id
                                        }
                                      },
                                      [_vm._v("詳細")]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "d-none d-xl-table-cell" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-nowrap stream-router-created-at"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatted_time")(
                                            streamRoute.created_at
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "d-none d-xl-table-cell" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-nowrap stream-route-folder"
                                    },
                                    [_vm._v(_vm._s(_vm.curFolder.name))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group stream-route-actions"
                                  },
                                  [
                                    _vm._m(1, true),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-menu" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: {
                                              href:
                                                _vm.rootPath +
                                                "/user/stream_routes/" +
                                                streamRoute.id +
                                                "/edit"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      編集する\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: {
                                              role: "button",
                                              "data-toggle": "modal",
                                              "data-target":
                                                "#modalCopyStreamRoute"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.curStreamRouteIndex = index
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        複製する\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: {
                                              role: "button",
                                              "data-toggle": "modal",
                                              "data-target":
                                                "#modalDeleteStreamRoute"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.curStreamRouteIndex = index
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        削除する\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.curFolder.stream_routes &&
                    _vm.curFolder.stream_routes.length === 0
                      ? _c("div", { staticClass: "text-center mt-5" }, [
                          _c("b", [_vm._v("流入経路はありません。")])
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このフォルダーを削除してもよろしいですか？",
          id: "modalDeleteFolder",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteFolder },
        scopedSlots: _vm._u(
          [
            _vm.curFolder
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("フォルダー名：" + _vm._s(_vm.curFolder.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "この流入経路を削除してもよろしいですか？",
          id: "modalDeleteStreamRoute",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteStreamRoute },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curStreamRoute
                  ? _c("div", [
                      _vm._v("\n        流入経路名："),
                      _c("b", [_vm._v(_vm._s(_vm.curStreamRoute.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "この流入経路をコーピーしてもよろしいですか？",
          id: "modalCopyStreamRoute",
          type: "confirm"
        },
        on: { confirm: _vm.submitCopyStreamRoute },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curStreamRoute
                  ? _c("div", [
                      _vm._v("\n        流入経路名："),
                      _c("b", [_vm._v(_vm._s(_vm.curStreamRoute.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("show-qr-code-modal", {
        attrs: { streamRoute: _vm.showQrCodeOfStreamRoute }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "fw-200 stream-route-name-column-label" }, [
          _vm._v("流入経路名称")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("アクション")]),
        _vm._v(" "),
        _c("th", [_vm._v("友達追加時設定")]),
        _vm._v(" "),
        _c("th", [_vm._v("集計")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-xl-table-cell" }, [
          _vm._v("登録日時")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "d-none d-xl-table-cell" }, [
          _vm._v("フォルダー")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                      操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }