var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalAnnouncementDetail",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-lg modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.announcement
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h3", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.announcement.title))
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "page-content" }, [
                    _c("div", { staticClass: "page-content__inner c-grid" }, [
                      _c("div", {
                        attrs: { id: "output" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.normalize(_vm.announcement.body)
                          )
                        }
                      })
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "info-header-modalLabel" } },
        [_vm._v("お知らせ詳細")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("閉じる")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }