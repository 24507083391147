var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: _vm.getClassName() },
      [
        _c("media-preview", {
          attrs: {
            type: _vm.data.type,
            src: _vm.data.originalContentUrl,
            duration: _vm.getDuration(),
            showMedia: true
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }