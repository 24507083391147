var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c(
              "form",
              {
                ref: "form",
                attrs: {
                  action: _vm.formAction,
                  method: "post",
                  enctype: "multipart/form-data"
                },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    validate().then(_vm.onSubmit)
                  }
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken }
                }),
                _vm._v(" "),
                _vm.survey
                  ? _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("survey-form-content", {
                              attrs: { survey: _vm.survey, preview: false }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-footer border-top border-success text-align-center"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success fw-120",
                                attrs: { type: "submit" }
                              },
                              [_vm._v("送信")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("loading-indicator", {
                          attrs: { loading: _vm.loading }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.survey && !_vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-warning",
                        attrs: { role: "alert" }
                      },
                      [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v("エラー")
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v("アクセスが拒否されました。")])
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }