var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "media-content d-flex align-items-center justify-content-center"
    },
    [
      _vm.isImage
        ? _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "" + _vm.src,
                expression: "`${src}`"
              }
            ],
            staticClass: "image"
          })
        : _vm.isVideo
        ? _c("div", [
            _c("video", { attrs: { width: 200, height: 150, controls: "" } }, [
              _c("source", { attrs: { src: _vm.src } })
            ])
          ])
        : _vm.isAudio
        ? _c("div", [
            _c("div", { staticClass: "chat-item-voice" }, [
              _c(
                "audio",
                { staticClass: "audio-player", attrs: { controls: "" } },
                [_c("source", { attrs: { src: _vm.src } })]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }