var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-common01",
      attrs: {
        id: "modalTagFriends",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _vm.tag
        ? _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg modal-dialog-scrollable",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "info-header-modalLabel" }
                    },
                    [
                      _vm._v(
                        "つけたタグ「" +
                          _vm._s(_vm.tag.name || "未設定") +
                          "」の友達一覧"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "aria-hidden": "true"
                      }
                    },
                    [_vm._v("×")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body mvh-40" },
                  [
                    _c(
                      "table",
                      { staticClass: "table table-centered mt-2" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._l(_vm.friends, function(friend, index) {
                          return _c("tbody", { key: index }, [
                            _c("tr", [
                              _c("td", { staticClass: "table-user" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.avatarImgObj,
                                      expression: "avatarImgObj"
                                    }
                                  ],
                                  staticClass: "mr-2 rounded-circle",
                                  attrs: { alt: "table-user" }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(friend.line_name) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formattedDatetime(friend.created_at)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("friend-status", {
                                    attrs: {
                                      status: friend.status,
                                      locked: friend.locked,
                                      visible: friend.visible
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-sm btn-light",
                                    attrs: {
                                      href:
                                        _vm.rootUrl +
                                        "/user/friends/" +
                                        friend.id
                                    }
                                  },
                                  [_vm._v("詳細")]
                                )
                              ])
                            ])
                          ])
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center mt-4 text-center"
                      },
                      [
                        _vm.totalRows > _vm.perPage
                          ? _c("b-pagination", {
                              attrs: {
                                "total-rows": _vm.totalRows,
                                "per-page": _vm.perPage,
                                "aria-controls": "my-table"
                              },
                              on: { change: _vm.loadPage },
                              model: {
                                value: _vm.curPage,
                                callback: function($$v) {
                                  _vm.curPage = $$v
                                },
                                expression: "curPage"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.loading && _vm.totalRows === 0
                          ? _c("b", [_vm._v("友達はありません。")])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("loading-indicator", { attrs: { loading: _vm.loading } })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("名前")]),
        _vm._v(" "),
        _c("th", [_vm._v("登録日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-150" }, [_vm._v("操作")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }