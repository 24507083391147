var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "confirm-content" }, [
      _c("div", { staticClass: "confirm-header" }, [
        _c("b", { staticClass: "buttons-title" }, [
          _vm._v(_vm._s(_vm.data.text) + " ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        _vm._l(_vm.data.actions, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "d-flex button-item align-items-center justify-content-center btn-content-break"
            },
            [_vm._v("\n        " + _vm._s(item.label) + " \n      ")]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }