var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.componentKey, staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group d-flex" }, [
            _c(
              "label",
              { staticClass: "fw-200" },
              [_vm._v("シナリオ名"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow-1" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.scenarioData.title,
                      expression: "scenarioData.title",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:255",
                      expression: "'required|max:255'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "name",
                    placeholder: "シナリオ名を入力してください",
                    maxlength: "256",
                    "data-vv-as": "シナリオ名"
                  },
                  domProps: { value: _vm.scenarioData.title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.scenarioData,
                        "title",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first("name") }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c("label", { staticClass: "fw-200" }, [_vm._v("シナリオ説明")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow-1" },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scenarioData.description,
                      expression: "scenarioData.description"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:2000",
                      expression: "'max:2000'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "description",
                    rows: "3",
                    placeholder: "シナリオ説明を入力してください",
                    maxlength: "2001",
                    "data-vv-as": "シナリオ説明"
                  },
                  domProps: { value: _vm.scenarioData.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.scenarioData,
                        "description",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first("description") }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group d-flex" }, [
            _c("label", { staticClass: "fw-200" }, [_vm._v("配信 ON/OFF")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1 d-flex" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scenarioData.status,
                    expression: "scenarioData.status"
                  }
                ],
                ref: "status",
                attrs: {
                  type: "checkbox",
                  id: "scenario-onoff",
                  checked: "",
                  "data-switch": "success",
                  "true-value": "enabled",
                  "false-value": "disabled"
                },
                domProps: {
                  checked: Array.isArray(_vm.scenarioData.status)
                    ? _vm._i(_vm.scenarioData.status, null) > -1
                    : _vm._q(_vm.scenarioData.status, "enabled")
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.scenarioData.status,
                      $$el = $event.target,
                      $$c = $$el.checked ? "enabled" : "disabled"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.scenarioData,
                            "status",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.scenarioData,
                            "status",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.scenarioData, "status", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", {
                attrs: {
                  for: "scenario-onoff",
                  "data-on-label": "オン",
                  "data-off-label": "オフ"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c("div", { staticClass: "w-100 form-group d-flex" }, [
              _c("label", { staticClass: "fw-200" }, [_vm._v("配信先")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.target,
                          expression: "target"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "targetAll",
                        name: "target",
                        value: "all"
                      },
                      domProps: { checked: _vm._q(_vm.target, "all") },
                      on: {
                        change: function($event) {
                          _vm.target = "all"
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "targetAll" }
                      },
                      [_vm._v("全員")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.target,
                          expression: "target"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "targetTags",
                        name: "target",
                        value: "tags"
                      },
                      domProps: { checked: _vm._q(_vm.target, "tags") },
                      on: {
                        change: function($event) {
                          _vm.target = "tags"
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "targetTags" }
                      },
                      [_vm._v("タグで絞り込む")]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.target === "tags"
                  ? _c("div", { staticClass: "w-100 mt-2" }, [
                      _c(
                        "div",
                        [
                          _c("input-tag", {
                            attrs: { tags: _vm.scenarioData.tags },
                            on: { input: _vm.addListTag }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c("div", { staticClass: "form-group d-flex" }, [
              _c("label", { staticClass: "fw-200" }, [_vm._v("配信開始")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenarioData.type,
                          expression: "scenarioData.type"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "typeManual",
                        value: "manual"
                      },
                      domProps: {
                        checked: _vm._q(_vm.scenarioData.type, "manual")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.scenarioData, "type", "manual")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "typeManual" }
                      },
                      [_vm._v("選択なし")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenarioData.type,
                          expression: "scenarioData.type"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: { type: "radio", id: "typeAuto", value: "auto" },
                      domProps: {
                        checked: _vm._q(_vm.scenarioData.type, "auto")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.scenarioData, "type", "auto")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "typeAuto" }
                      },
                      [_vm._v("友達追加時")]
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c("div", { staticClass: "d-flex flex-start" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenarioData.mode,
                          expression: "scenarioData.mode"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "modeElapsedTime",
                        value: "elapsed_time",
                        disabled: _vm.scenario_id
                      },
                      domProps: {
                        checked: _vm._q(_vm.scenarioData.mode, "elapsed_time")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.scenarioData,
                            "mode",
                            "elapsed_time"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "modeElapsedTime" }
                      },
                      [_vm._v("経過時間で指定")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio custom-control-inline"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.scenarioData.mode,
                          expression: "scenarioData.mode"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "modeAuto",
                        value: "time",
                        disabled: _vm.scenario_id
                      },
                      domProps: {
                        checked: _vm._q(_vm.scenarioData.mode, "time")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.scenarioData, "mode", "time")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "modeAuto" }
                      },
                      [_vm._v("時刻で指定")]
                    )
                  ]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("action-editor-custom", {
              attrs: {
                name: "after_action",
                value: _vm.scenarioData.after_action,
                requiredLabel: false,
                showTitle: false,
                showLaunchMessage: false
              },
              on: { input: _vm.updateAction }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        {
          staticClass: "btn btn-success fw-120 mr-2",
          on: {
            click: function($event) {
              return _vm.saveScenario()
            }
          }
        },
        [_vm._v("保存")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "btn btn-outline-success fw-120",
          on: {
            click: function($event) {
              return _vm.saveScenario("draft")
            }
          }
        },
        [_vm._v("下書き保存")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("基本設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("配信設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fw-200" }, [
      _c("label", { staticClass: "w-100" }, [_vm._v("配信タイミング")]),
      _vm._v(" "),
      _c("span", { staticClass: "text-sm text-muted font-12" }, [
        _vm._v("作成した後、変更不可")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("配信終了アクション設定")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }