var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal d-block",
      staticStyle: { background: "rgba(0, 0, 0, 0.4)" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
        id: "modalRichmenuImageEditor"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header flex-shrink-0" }, [
              _c("h5", { staticClass: "mb-0" }, [
                _vm.editor === "richmenu"
                  ? _c("span", [_vm._v("リッチメニュー")])
                  : _c("span", [_vm._v("画像を作成")])
              ]),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body mh-100 overflow-y-auto" },
              [
                _c("rich-menu-image-editor", {
                  attrs: { templateId: _vm.templateId, editor: _vm.editor },
                  on: { save: _vm.saveImage }
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalConfirmCloseEditor",
          type: "confirm",
          title: "確認"
        },
        on: {
          confirm: function($event) {
            return _vm.closeEditor()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm._v(
                  "\n      画像は保存されません。よろしいでしょうか。\n    "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
          "data-toggle": "modal",
          "data-target": "#modalConfirmCloseEditor"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }