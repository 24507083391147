var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm.flexMessage != null
      ? _c(
          "div",
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "form-group row d-flex" }, [
                _c("label", { staticClass: "col-sm-3 text-right m-auto" }, [
                  _vm._v("Flexメッセージ名")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.flexMessage.name,
                        expression: "flexMessage.name",
                        modifiers: { trim: true }
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "flex-title",
                      placeholder: "Flex ッセージ名を入力してください",
                      type: "text"
                    },
                    domProps: { value: _vm.flexMessage.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.flexMessage,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.first("flex-title")
                    ? _c("span", { staticClass: "invalid-box-label" }, [
                        _vm._v("Flexメッセージ名は必須です")
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("flexmessage-editor", {
              staticStyle: { "margin-top": "30px" },
              attrs: { data: _vm.flexMessage, altTextData: _vm.altText },
              on: {
                input: function($event) {
                  _vm.flexMessageEditable = $event
                },
                altText: function($event) {
                  _vm.altText = $event
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row-form-btn d-flex justify-content-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn btn-submit btn-block",
                    on: { click: _vm.save }
                  },
                  [_vm._v("保存")]
                )
              ]
            )
          ],
          1
        )
      : _c("div", [_vm._v("LOADING..")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row-ttl01 flex ai_center mb40 flex-wrap justify-content-between"
      },
      [_c("h3", { staticClass: "hdg3" }, [_vm._v("Flexメッセージ新規")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }