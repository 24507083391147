var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "editor-content" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            background: "rgb(245, 245, 245)",
            "max-width": "300px"
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.editor === "richmenu"
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-content-center",
                  staticStyle: { height: "400px !important" }
                },
                [
                  _vm.templateId === 201
                    ? _c(
                        "table",
                        { staticStyle: { width: "300px", height: "200px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    attrs: { id: _vm.objectLists[3].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(3)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[3].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    attrs: { id: _vm.objectLists[4].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(4)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[4].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    attrs: { id: _vm.objectLists[5].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(5)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[5].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 202
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[2].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(2)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[2].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  attrs: { id: _vm.objectLists[3].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(3)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[3].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 203
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: { width: "300px" },
                                attrs: { colspan: "3" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  attrs: { id: _vm.objectLists[2].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(2)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[2].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  attrs: { id: _vm.objectLists[3].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(3)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[3].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 204
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: { width: "200px", height: "100%" },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[2].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(2)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[2].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 205
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "300px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticStyle: { width: "300px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 206
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "150px",
                                  height: "200px"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "150px",
                                  height: "200px"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 207
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "300px",
                                  height: "200px"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 1001
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[2].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(2)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[2].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 1002
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "200px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 1003
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "200px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 1004
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[1].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(1)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[1].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 1005
                    ? _c("table", { staticStyle: { width: "300px" } }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticStyle: { width: "300px" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-area active",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100px"
                                  },
                                  attrs: { id: _vm.objectLists[0].name },
                                  on: {
                                    click: function($event) {
                                      return _vm.chooseActive(0)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      id: "preview_" + _vm.objectLists[0].name
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editor === "imagemap"
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-center",
                  staticStyle: { height: "400px", width: "300px" }
                },
                [
                  _vm.templateId === 201
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[3].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(3)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[3].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[4].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(4)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[4].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[5].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(5)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[5].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 202
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "135px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "135px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[3].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(3)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[3].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 203
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "270px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "90px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "270px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "90px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "270px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "90px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 204
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticStyle: { width: "270px" },
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "preview-area active",
                                      staticStyle: {
                                        width: "100%",
                                        height: "135px"
                                      },
                                      attrs: { id: _vm.objectLists[0].name },
                                      on: {
                                        click: function($event) {
                                          return _vm.chooseActive(0)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          id:
                                            "preview_" + _vm.objectLists[0].name
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 208
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "75px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "75px"
                                    },
                                    attrs: { id: _vm.objectLists[2].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(2)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[2].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 205
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "100%",
                                      height: "135px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 206
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "135px",
                                      height: "270px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area",
                                    staticStyle: {
                                      width: "135px",
                                      height: "270px"
                                    },
                                    attrs: { id: _vm.objectLists[1].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(1)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[1].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateId === 207
                    ? _c(
                        "table",
                        { staticStyle: { width: "270px", height: "270px" } },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticStyle: { width: "90px" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-area active",
                                    staticStyle: {
                                      width: "270px",
                                      height: "270px"
                                    },
                                    attrs: { id: _vm.objectLists[0].name },
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseActive(0)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        id: "preview_" + _vm.objectLists[0].name
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "editor-divider" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column editor-container w-100" },
        [
          _c("div", { staticClass: "d-flex mb-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-xs ml-auto mr-1",
                on: {
                  click: function($event) {
                    return _vm.imageGeneration()
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-download" }),
                _vm._v("\n          ダウンロード\n        ")
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-xs btn-info",
                on: {
                  click: function($event) {
                    return _vm.imageGeneration(false)
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-save" }), _vm._v(" 保存")]
            )
          ]),
          _vm._v(" "),
          _c("image-editor", {
            attrs: {
              data: _vm.objectLists[_vm.selectIndex],
              preview: "#preview_" + _vm.objectLists[_vm.selectIndex].name,
              width: _vm.width,
              height: _vm.height
            },
            on: {
              input: function($event) {
                _vm.objectLists[_vm.selectIndex] = $event
              }
            }
          }),
          _vm._v(" "),
          _c("div", {
            staticStyle: { "text-align": "center", flex: "1", height: "50px" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-nav-bar" }, [
      _c("p", [_vm._v("プレビュー")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }