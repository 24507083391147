var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c(
          "table",
          { staticClass: "table table-bordered table-striped mt-3" },
          [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("登録日")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("formatted_time")(_vm.siteDetail.created_at))
                  )
                ])
              ])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "mb-4" }),
    _vm._v(" "),
    _c("h4", { staticClass: "font-weight-normal" }, [_vm._v("統計(配信)")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("URL送信回数")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.siteDetail.sending_count) + "回")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("総クリック数")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.siteDetail.click_count) + "回")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("クリック数／送信回数")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("number_to_percent")(
                      _vm.siteDetail.click_count / _vm.siteDetail.sending_count
                    )
                  )
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("table", { staticClass: "table table-striped" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("URL送信人数")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.siteDetail.receiver_count) + "人")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("訪問人数")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.siteDetail.visitor_count) + "人")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("訪問人数／URL送信人数")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("number_to_percent")(
                      _vm.siteDetail.visitor_count /
                        _vm.siteDetail.receiver_count
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("訪問人数／友だち総数")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("number_to_percent")(
                      _vm.siteDetail.visitor_count / _vm.siteDetail.friendTotal
                    )
                  )
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("URLクリック数")]), _vm._v(" "), _c("td")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("クリック人数")]), _vm._v(" "), _c("td")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }