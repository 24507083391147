var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "area-type mb20" },
    [
      _vm.data.type === "text"
        ? _c("message-editor-text", {
            attrs: { value: _vm.data.text, index: _vm.index },
            on: { input: _vm.onTextChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "sticker"
        ? _c("sticker-message-editor", {
            attrs: {
              packageId: _vm.data.packageId,
              stickerId: _vm.data.stickerId,
              index: _vm.index
            },
            on: { input: _vm.onStickerChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      ["image", "video", "audio"].includes(_vm.data.type)
        ? [
            _c("message-editor-media", {
              attrs: { data: _vm.data, index: _vm.index },
              on: { input: _vm.onMediaChanged }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Location
        ? _c("message-editor-location", {
            attrs: { data: _vm.data, indexParent: _vm.index },
            on: { input: _vm.onLocationChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Template &&
      _vm.data.template.type === _vm.TemplateMessageType.Buttons
        ? _c("message-editor-buttons", {
            attrs: { data: _vm.data.template, indexParent: _vm.index },
            on: { input: _vm.onTemplateContentChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Template &&
      _vm.data.template.type === _vm.TemplateMessageType.Confirm
        ? _c("message-editor-confirm", {
            attrs: { data: _vm.data.template, indexParent: _vm.index },
            on: { input: _vm.onTemplateContentChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Template &&
      _vm.data.template.type === _vm.TemplateMessageType.Carousel
        ? _c("message-editor-carousel", {
            attrs: { data: _vm.data.template, indexParent: _vm.index },
            on: { input: _vm.onTemplateContentChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Template &&
      _vm.data.template.type === _vm.TemplateMessageType.ImageCarousel
        ? _c("message-editor-carousel-image", {
            attrs: { data: _vm.data.template, indexParent: _vm.index },
            on: { input: _vm.onTemplateContentChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Imagemap
        ? _c("message-editor-imagemap", {
            attrs: { data: _vm.data, index: _vm.index, indexParent: _vm.index },
            on: { input: _vm.onImagemapChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === _vm.MessageType.Flex
        ? _c("flex-message-editor", {
            attrs: { data: _vm.data, index: _vm.index, indexParent: _vm.index },
            on: { input: _vm.onFlexChanged }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }