var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", [_vm._v("アクション")]),
    _vm._v(" "),
    _c("div", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.postbackType,
              expression: "postbackType"
            }
          ],
          staticClass: "w-100 form-control",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.postbackType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.changeActionType
            ]
          }
        },
        _vm._l(_vm.types, function(val, key) {
          return _c("option", { key: key, domProps: { value: key } }, [
            _vm._v(_vm._s(val))
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-2" },
      [
        _vm.postbackType === "text"
          ? _c("action-postback-text", {
              attrs: { "action-data": _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "template"
          ? _c("action-postback-template", {
              attrs: { value: _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "scenario"
          ? _c("action-postback-scenario", {
              attrs: { value: _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "email"
          ? _c("action-postback-email", {
              attrs: { value: _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "tag"
          ? _c("action-postback-tag", {
              attrs: { value: _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "reminder"
          ? _c("action-postback-reminder", {
              attrs: { "action-data": _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "scoring"
          ? _c("action-postback-scoring", {
              attrs: { "action-data": _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.postbackType === "assign_staff"
          ? _c("action-postback-assign-staff", {
              attrs: { "action-data": _vm.content, name: _vm.name },
              on: { input: _vm.updateContent }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }