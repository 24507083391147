var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("staff-selection", {
        attrs: { selected: _vm.channel.assignee_id },
        on: {
          select: _vm.assignAgent,
          open: _vm.openStaffSelect,
          close: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }