var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card mvh-50" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex justify-content-between flex-wrap"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-end" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "filter-tester-accounts custom-control custom-checkbox mr-3 d-flex align-items-center"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectOnlyTester,
                          expression: "selectOnlyTester"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "checkbox",
                        name: "tester",
                        id: "search_tester_account"
                      },
                      domProps: {
                        value: true,
                        checked: Array.isArray(_vm.selectOnlyTester)
                          ? _vm._i(_vm.selectOnlyTester, true) > -1
                          : _vm.selectOnlyTester
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectOnlyTester,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = true,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectOnlyTester = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectOnlyTester = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectOnlyTester = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "search_tester_account" }
                      },
                      [_vm._v("テスターのみ")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-200 h-5 mr-1" },
                  [
                    _c("staff-selection", {
                      ref: "channelAssignment",
                      attrs: {
                        selected: _vm.queryParams.channel_assignee_id_eq
                      },
                      on: { select: _vm.setAssigneeParam }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex text-nowrap" }, [
                  _c("div", { staticClass: "input-group app-search" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keyword,
                          expression: "keyword"
                        }
                      ],
                      staticClass: "form-control dropdown-toggle fw-250",
                      attrs: {
                        type: "text",
                        placeholder: "検索...",
                        maxlength: "64"
                      },
                      domProps: { value: _vm.keyword },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.keyword = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "mdi mdi-magnify search-icon" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.loadFriend }
                        },
                        [_vm._v("検索")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-primary text-nowrap ml-1 mr-1",
                      attrs: {
                        "data-backdrop": "static",
                        "data-toggle": "modal",
                        "data-target": "#modalFriendSearch"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openModal()
                        }
                      }
                    },
                    [_vm._v("\n            詳細検索\n          ")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("friend-search-status"),
              _vm._v(" "),
              _c("div", [
                _c(
                  "table",
                  { staticClass: "table table-centered mt-2 pc" },
                  [
                    _c("thead", { staticClass: "thead-light" }, [
                      _c("tr", [
                        _c("th", [_vm._v("名前")]),
                        _vm._v(" "),
                        _c("th", { staticClass: "d-none d-lg-table-cell" }, [
                          _vm._v("登録日時")
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "d-none d-lg-table-cell mw-400" },
                          [_vm._v("タグ")]
                        ),
                        _vm._v(" "),
                        _c("th", [_vm._v("状況")]),
                        _vm._v(" "),
                        _vm.isAdmin
                          ? _c(
                              "th",
                              { staticClass: "d-none d-lg-table-cell" },
                              [_vm._v("担当者")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", { staticClass: "d-none d-lg-table-cell" }, [
                          _vm._v("操作")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.friends, function(friend, index) {
                      return _c("tbody", { key: index }, [
                        _c(
                          "tr",
                          {
                            on: {
                              click: function($event) {
                                _vm.isMobile
                                  ? _vm.redirectToFriendDetail(friend)
                                  : ""
                              }
                            }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "table-user d-flex align-items-center"
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.genAvatarImgObj(
                                        friend.line_picture_url
                                      ),
                                      expression:
                                        "genAvatarImgObj(friend.line_picture_url)"
                                    }
                                  ],
                                  staticClass: "mr-2 rounded-circle",
                                  attrs: { alt: "table-user" }
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("truncate")(
                                          friend.display_name ||
                                            friend.line_name,
                                          10
                                        )
                                      )
                                  ),
                                  friend.tester
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-warning ml-1 pt-1"
                                        },
                                        [_vm._v("テスター")]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "d-none d-lg-table-cell" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formattedDatetime(friend.created_at)
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "d-none d-lg-table-cell mxw-300" },
                              [
                                _c("friend-tag", {
                                  attrs: { tags: friend.tags }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("friend-status", {
                                  attrs: {
                                    status: friend.status,
                                    locked: friend.locked,
                                    visible: friend.visible
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isAdmin
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "d-none d-lg-table-cell fw-250"
                                  },
                                  [
                                    _c("channel-assignment", {
                                      attrs: { channel: friend.channel }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "d-none d-lg-table-cell" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-sm btn-light",
                                    attrs: {
                                      href:
                                        _vm.rootUrl +
                                        "/user/friends/" +
                                        friend.id
                                    }
                                  },
                                  [_vm._v("詳細")]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center mt-4" },
                [
                  _vm.totalRows > _vm.perPage
                    ? _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          "aria-controls": "my-table"
                        },
                        on: { change: _vm.loadFriend },
                        model: {
                          value: _vm.curPage,
                          callback: function($$v) {
                            _vm.curPage = $$v
                          },
                          expression: "curPage"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.loading && _vm.totalRows === 0
                ? _c(
                    "div",
                    { staticClass: "text-center my-5 font-weight-bold" },
                    [_vm._v("データはありません。")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-friend-search", {
        ref: "modalFriendSearch",
        attrs: { selectOnlyTester: _vm.selectOnlyTester },
        on: { changeSelectOnlyTester: _vm.changeSelectOnlyTester }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-lg-100 w-xl-25 mb-lg-3 mb-xl-0" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-outline-primary text-nowrap",
          attrs: { href: "/user/friends/export" }
        },
        [
          _c("i", { staticClass: "fas fa-download" }),
          _vm._v(" CSVダウンロード")
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }