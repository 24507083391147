import { render, staticRenderFns } from "./MessageEditorConfirm.vue?vue&type=template&id=4f5a9570&scoped=true&"
import script from "./MessageEditorConfirm.vue?vue&type=script&lang=js&"
export * from "./MessageEditorConfirm.vue?vue&type=script&lang=js&"
import style0 from "./MessageEditorConfirm.vue?vue&type=style&index=0&id=4f5a9570&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5a9570",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f5a9570')) {
      api.createRecord('4f5a9570', component.options)
    } else {
      api.reload('4f5a9570', component.options)
    }
    module.hot.accept("./MessageEditorConfirm.vue?vue&type=template&id=4f5a9570&scoped=true&", function () {
      api.rerender('4f5a9570', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/message-editor/MessageEditorConfirm.vue"
export default component.exports