var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeChannel
    ? _c("div", { staticClass: "card chat-panel" }, [
        _c("div", { staticClass: "card-header d-block d-xl-none" }, [
          _c(
            "a",
            {
              staticClass: "icon-fs",
              on: {
                click: function($event) {
                  return _vm.hideUserDetail()
                }
              }
            },
            [_c("i", { staticClass: "uil-multiply" })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mt-3 text-center" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.genAvatarImgObj(_vm.friend.avatar_url),
                  expression: "genAvatarImgObj(friend.avatar_url)"
                }
              ],
              staticClass: "img-thumbnail avatar-lg rounded-circle"
            }),
            _vm._v(" "),
            _c("h4", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("truncate")(
                      _vm.friend.display_name || _vm.friend.line_name,
                      15
                    )
                  )
              ),
              _vm.friend.tester
                ? _c(
                    "span",
                    { staticClass: "ml-1 pt-1 badge badge-warning badge-sm" },
                    [_vm._v("テスター")]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3" }, [
            _vm.isAdmin
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-primary btn-sm mr-1 ml-auto fw-80",
                        attrs: { href: _vm.detailPath }
                      },
                      [_vm._v("詳細")]
                    ),
                    _vm._v(" "),
                    _vm.friend.locked
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "btn btn-sm btn-info mr-auto",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": "#modalConfirmToggleLocked"
                              }
                            },
                            [_vm._v("\n            アンブロック\n          ")]
                          )
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "btn btn-sm btn-danger mr-auto",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": "#modalConfirmToggleLocked"
                              }
                            },
                            [_vm._v("\n            ブロック\n          ")]
                          )
                        ]
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("hr", {}),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "channel-info",
                style: _vm.isStaffSelectOpened
                  ? "padding-top: " +
                    (_vm.staffCount > 0 ? _vm.staffCount - 1 : 0) * 50 +
                    "px"
                  : ""
              },
              [
                _vm.isAdmin
                  ? [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c("channel-assignment", {
                            key: _vm.contentKey,
                            attrs: { channel: _vm.activeChannel },
                            on: {
                              open: _vm.openStaffSelect,
                              close: function($event) {
                                _vm.isStaffSelectOpened = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("p", { staticClass: "text-prewrap max-3-lines" }, [
                  _vm._v(_vm._s(_vm.friend.note || "なし"))
                ]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "p",
                  [_c("friend-tag", { attrs: { tags: _vm.friend.tags } })],
                  1
                ),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.friendAddedAt))])
              ],
              2
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-1 font-12" }, [
      _c("strong", [
        _c("i", { staticClass: "uil uil-user" }),
        _vm._v(" 担当者:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-1 font-12" }, [
      _c("strong", [
        _c("i", { staticClass: "uil uil-notes" }),
        _vm._v(" メモ欄:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-1 font-12" }, [
      _c("strong", [_c("i", { staticClass: "uil uil-tag" }), _vm._v(" タグ:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-4 mb-1 font-12" }, [
      _c("strong", [
        _c("i", { staticClass: "uil uil-clock" }),
        _vm._v(" 登録日時:")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }