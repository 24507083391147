var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.locked
        ? [
            _c(
              "div",
              {
                staticClass: "btn btn-sm btn-info",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalConfirmToggleLocked"
                }
              },
              [_vm._v("アンブロック")]
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "btn btn-sm btn-danger",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalConfirmToggleLocked"
                }
              },
              [_vm._v("ブロック")]
            )
          ],
      _vm._v(" "),
      [
        _c("modal-confirm", {
          attrs: {
            id: "modalConfirmToggleLocked",
            title: "友達状況の変更してもよろしいですか？",
            type: "confirm"
          },
          on: {
            confirm: function($event) {
              return _vm.toggle()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c("b", [
                    _vm._v(_vm._s(_vm.locked ? "ブロックした" : "有効"))
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                  _vm._v(" "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.locked ? "有効" : "ブロックした"))
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }