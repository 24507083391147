var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("folder-left", {
              attrs: {
                type: "auto_response",
                data: _vm.folders,
                isPc: _vm.isPc,
                selectedFolder: _vm.selectedFolderIndex
              },
              on: {
                changeSelectedFolder: _vm.onSelectedFolderChanged,
                submitUpdateFolder: _vm.submitUpdateFolder,
                submitCreateFolder: _vm.submitCreateFolder
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1 folder-right" }, [
              _c("div", { staticClass: "col-r" }, [
                _vm.folders &&
                _vm.folders.length &&
                _vm.folders[_vm.selectedFolderIndex]
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          href:
                            _vm.rootPath +
                            "/user/auto_responses/new?folder_id=" +
                            _vm.folders[_vm.selectedFolderIndex].id
                        }
                      },
                      [
                        _c("i", { staticClass: "uil-plus" }),
                        _vm._v(" 新規作成\n          ")
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table mb-0 table-centered" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.autoResponses, function(autoResponse) {
                        return _c("tr", { key: autoResponse.id }, [
                          _c("td", [
                            _c("div", { staticClass: "mxw-400 max-2-lines" }, [
                              _vm._v(_vm._s(autoResponse.name))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(autoResponse.keywords, function(
                                keyword,
                                index
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass:
                                      "badge badge-pill badge-warning mr-1 pt-1"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mxw-200 text-truncate" },
                                      [_vm._v(_vm._s(keyword))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            _vm._l(autoResponse.messages, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "text-left" },
                                [
                                  _c("message-content", {
                                    attrs: { data: item }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              autoResponse.status === "enabled"
                                ? [
                                    _c("i", {
                                      staticClass: "mdi mdi-circle text-success"
                                    }),
                                    _vm._v(" 有効\n                    ")
                                  ]
                                : [
                                    _c("i", { staticClass: "mdi mdi-circle" }),
                                    _vm._v(" 無効 ")
                                  ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(autoResponse.hit_count) +
                                "\n                  "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "btn-group" }, [
                              _vm._m(2, true),
                              _vm._v(" "),
                              _c("div", { staticClass: "dropdown-menu" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { role: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openEdit(autoResponse)
                                      }
                                    }
                                  },
                                  [_vm._v("自動応答を編集")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { role: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateAutoResponseStatus(
                                          autoResponse
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        autoResponse.status === "enabled"
                                          ? "OFF"
                                          : "ON"
                                      ) + "にする"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      role: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#modalCopyAutoResponse"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showConfirmCopyModal(
                                          autoResponse
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("自動応答をコピー")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      role: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#modalDeleteAutoResponse"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showConfirmDeleteModal(
                                          autoResponse
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("自動応答を削除")]
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm.curFolder
                              ? _c("div", [_vm._v(_vm._s(_vm.curFolder.name))])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "font-12" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formattedDate(autoResponse.created_at)
                                )
                              )
                            ])
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                !_vm.autoResponses || _vm.autoResponses.length === 0
                  ? _c("div", { staticClass: "text-center mt-5" }, [
                      _c("b", [_vm._v("自動応答はありません。")])
                    ])
                  : _vm._e()
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalDeleteFolder",
          title: "このフォルダーを削除してもよろしいですか？",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteFolder },
        scopedSlots: _vm._u(
          [
            _vm.folders[_vm.selectedFolderIndex]
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(
                          "フォルダ名：" +
                            _vm._s(_vm.folders[_vm.selectedFolderIndex].name)
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalDeleteAutoResponse",
          title: "自動応答を削除してもよろしいですか？",
          type: "delete"
        },
        on: {
          confirm: function($event) {
            return _vm.submitDeleteAutoResponse(_vm.autoResponse)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.autoResponse
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("div", [
                        _c("span", [
                          _vm._v("自動応答: " + _vm._s(_vm.autoResponse.name))
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalCopyAutoResponse",
          type: "confirm",
          title: "自動応答をコピーしてもよろしいですか？"
        },
        on: {
          confirm: function($event) {
            return _vm.submitCopyAutoResponse()
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.autoResponse
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("div", [
                        _c("span", [
                          _vm._v("自動応答: " + _vm._s(_vm.autoResponse.name))
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "mw-120" }, [_vm._v("自動応答名")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-120" }, [_vm._v("キーワード")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-100" }, [_vm._v("メッセージ")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-100" }, [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-100" }, [_vm._v("ヒット数")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-100" }, [_vm._v("操作")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-150" }, [_vm._v("フォルダー")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("small", [_vm._v("どれか1つにマッチ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }