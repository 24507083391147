var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modalSelectVariable",
      staticClass: "modal fade",
      attrs: {
        id: _vm.id ? _vm.id : "modal-template",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-lg vh-90 modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("folder-left", {
                    attrs: {
                      type: "variable",
                      isPerview: true,
                      data: _vm.folders,
                      selectedFolder: _vm.selectedFolderIndex
                    },
                    on: { changeSelectedFolder: _vm.changeSelectedFolder }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      key: _vm.contentKey,
                      staticClass: "flex-grow-1 scroll-table"
                    },
                    [
                      _c("table", { staticClass: "table" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.variables, function(variable, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(variable.name))]),
                              _vm._v(" "),
                              _c("td", { staticClass: "fw-120" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn btn-sm btn-light",
                                    attrs: { "data-dismiss": "modal" },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectVariable(variable)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      選択\n                    "
                                    )
                                  ]
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("友達情報名を選択してください")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [_c("th", [_vm._v("名称")]), _vm._v(" "), _c("th")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }