var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "carousel-list" }, [
      _c(
        "div",
        { staticClass: "d-inline-flex align-items-center" },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "carousel-content" },
            [
              item.thumbnailImageUrl
                ? _c("div", {
                    staticClass: "carousel-thumb",
                    style: {
                      backgroundImage: "url(" + item.thumbnailImageUrl + ")"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "carousel-heading" }, [
                _c("b", [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(item.text))])
              ]),
              _vm._v(" "),
              _vm._l(item.actions, function(action, indexAction) {
                return _c(
                  "div",
                  { key: indexAction, staticClass: "carousel-action" },
                  [
                    _c("div", { staticClass: "carousel-action-label" }, [
                      _vm._v(
                        "\n            " + _vm._s(action.label) + "\n          "
                      )
                    ])
                  ]
                )
              })
            ],
            2
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }