var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-preview-el" }, [
    _c(
      "div",
      {
        staticClass: "card card-sliding",
        style: _vm.isHidden ? "margin-bottom: -50vh;" : "margin-bottom: 0px;"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-dark d-flex justify-content-between align-items-center",
            attrs: { role: "button" },
            on: { click: _vm.changePreview }
          },
          [
            _c(
              "div",
              { staticClass: "text-white", attrs: { role: "button" } },
              [_vm._v("プレビュー")]
            ),
            _vm._v(" "),
            !_vm.isHidden
              ? _c("i", {
                  staticClass:
                    "dripicons-chevron-down text-white font-weight-bold",
                  attrs: { id: "gijvq97" }
                })
              : _c("i", {
                  staticClass:
                    "dripicons-chevron-up text-white font-weight-bold",
                  attrs: { id: "gijvq97" }
                })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "card-body bg-white overflow-x-hidden p-0 cursor-not-allowed no-select preview-container"
          },
          [
            _c(
              "div",
              { staticClass: "bg-white preview-content" },
              [
                _c("survey-form-content", {
                  attrs: { survey: _vm.survey, preview: true }
                })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }