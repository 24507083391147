var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container",
          staticStyle: { "max-width": "1000px", margin: "0" }
        },
        [
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-4 text-right m-auto" }, [
              _vm._v("Flexメッセージ名")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.name,
                    expression: "name",
                    modifiers: { trim: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.isValidate },
                    expression: "{ required: isValidate }"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "flex-title",
                  placeholder: "Flex メッセージ名を入力してください",
                  type: "text"
                },
                domProps: { value: _vm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.first("flex-title")
                ? _c("span", { staticClass: "invalid-box-label" }, [
                    _vm._v("Flexメッセージ名は必須です")
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c("label", { staticClass: "col-sm-4 text-right m-auto" }, [
              _vm._v("フォルダー")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.folderId,
                      expression: "folderId"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "folder_id", type: "text" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.folderId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "", disabled: "" } }, [
                    _vm._v("- フォルダー -")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.folderLists, function(folder, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: folder.id } },
                      [_vm._v(_vm._s(folder.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]
      ),
      _vm._v(" "),
      _vm.flexMessage != null
        ? _c("flexmessage-editor", {
            staticStyle: { "margin-top": "30px" },
            attrs: {
              data: _vm.flexMessage,
              altTextData: _vm.altText,
              isValidate: _vm.isValidate
            },
            on: {
              input: function($event) {
                _vm.flexMessageEditable = $event
              },
              altText: function($event) {
                _vm.altText = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row-form-btn d-flex justify-content-center" }, [
        _c(
          "button",
          { staticClass: "btn btn-submit btn-block", on: { click: _vm.save } },
          [_vm._v("保存")]
        )
      ]),
      _vm._v(" "),
      _c("flexmessage-modal-pick-template", {
        attrs: {
          flexMessageTemplates: _vm.flexMessageTemplates,
          name: "flexMessageModalPickTemplate"
        },
        on: {
          input: function($event) {
            _vm.flexMessageId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row-ttl01 flex ai_center mb40 flex-wrap justify-content-between"
      },
      [
        _c("h3", { staticClass: "hdg3" }, [
          _vm._v("Flexメッセージテンプレート一覧")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row" }, [
      _c("label", { staticClass: "col-sm-4 text-right m-auto" }, [
        _vm._v(" テンプレート")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-8 align-middle" }, [
        _c(
          "a",
          {
            staticClass: "btn-block btn-color",
            attrs: {
              "data-toggle": "modal",
              "data-target": "#flexMessageModalPickTemplate"
            }
          },
          [_vm._v("Flexメッセージのテンプレートを選ぶ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }