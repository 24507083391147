var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.showTitle
        ? _c("div", { staticStyle: { display: "contents" } }, [
            _c(
              "label",
              { staticClass: "mt-2" },
              [
                _vm._v("ラベル"),
                _vm.requiredLabel ? _c("required-mark") : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.actionData.label,
                      expression: "actionData.label",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: _vm.requiredLabel && _vm.showTitle,
                        max: 12
                      },
                      expression:
                        "{ required: requiredLabel && showTitle, max: 12 }"
                    }
                  ],
                  staticClass: "w-100 form-control",
                  attrs: {
                    name: _vm.name + "_label",
                    placeholder: "ラベルを入力してください",
                    type: "text",
                    maxlength: "12",
                    "data-vv-as": "ラベル"
                  },
                  domProps: { value: _vm.actionData.label },
                  on: {
                    keyup: _vm.changeValue,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.actionData,
                        "label",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first(_vm.name + "_label") }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "w-100 mt-2" },
        [_vm._v("\n    回答フォーム\n    "), _c("required-mark")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.actionData.content.id
            ? _c(
                "div",
                {
                  staticClass: "btn btn-secondary",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.name
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.actionData.content.name))])]
              )
            : _c(
                "div",
                {
                  staticClass: "btn btn-secondary",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.name
                  }
                },
                [_vm._v("回答フォームを選択する")]
              ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.actionData.content.id,
                expression: "actionData.content.id"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "hidden",
              name: _vm.name + "_survey_code",
              "data-vv-as": "回答フォーム"
            },
            domProps: { value: _vm.actionData.content.id },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.actionData.content, "id", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "_survey_code") }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-select-survey", {
        attrs: { id: _vm.name },
        on: {
          selectSurvey: function($event) {
            return _vm.selectSurvey($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }