var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "text-right",
        on: {
          click: function($event) {
            return _vm.expand()
          }
        }
      },
      [
        _c("span", { staticClass: "float-left" }, [_vm._v("テキスト")]),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-arrow-expand",
          attrs: { "aria-hidden": "true" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-container",
        class: _vm.name + "-expand",
        staticStyle: { "margin-top": "10px", display: "none" }
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "card-header" }, [_vm._v("Text")]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                _vm._v(" Text ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.text,
                      expression: "text",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "text_" + _vm.name },
                  domProps: { value: _vm.text },
                  on: {
                    change: _vm.input,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.text = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                _vm._v("サイズ変更")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8" },
                [
                  _c("input-with-suggestion", {
                    attrs: {
                      type: "text",
                      name: "size_" + _vm.name,
                      validate: { regex: _vm.getRegexFontSize() },
                      list: _vm.FontSizeClass.map(function(item) {
                        return item.toLowerCase()
                      }),
                      value: _vm.size,
                      classes: "form-control"
                    },
                    on: { change: _vm.changeSize }
                  }),
                  _vm._v(" "),
                  _vm.errors.first("size_" + _vm.name)
                    ? _c("span", { staticClass: "invalid-box-label" }, [
                        _vm._v("サイズが無効です")
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "card-header" }, [_vm._v("アクション設定")]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "col-form-label" }, [
                  _vm._v(" タイプ ")
                ]),
                _vm._v(" "),
                _c("action-editor", {
                  staticClass: "form-group",
                  attrs: {
                    title: "Action Type",
                    supports: [
                      "postback",
                      "uri",
                      "message",
                      "datetimepicker",
                      "survey"
                    ],
                    showTitle: false,
                    name: _vm.name,
                    value: _vm.action
                  },
                  on: {
                    input: function($event) {
                      _vm.action = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }