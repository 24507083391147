import { render, staticRenderFns } from "./FlexMessageEditor.vue?vue&type=template&id=130cdce3&scoped=true&"
import script from "./FlexMessageEditor.vue?vue&type=script&lang=js&"
export * from "./FlexMessageEditor.vue?vue&type=script&lang=js&"
import style0 from "./FlexMessageEditor.vue?vue&type=style&index=0&id=130cdce3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130cdce3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('130cdce3')) {
      api.createRecord('130cdce3', component.options)
    } else {
      api.reload('130cdce3', component.options)
    }
    module.hot.accept("./FlexMessageEditor.vue?vue&type=template&id=130cdce3&scoped=true&", function () {
      api.rerender('130cdce3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/message-editor/flex-message/FlexMessageEditor.vue"
export default component.exports