var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("label", [_vm._v("シナリオ配信")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-template mb20 fz14" },
        [
          _c(
            "div",
            {
              staticClass: "btn btn-secondary btn-block",
              attrs: { "data-toggle": "modal", "data-target": "#" + _vm.name }
            },
            [_vm._v("\n      " + _vm._s(_vm.currentTemplate.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentTemplate.scenario_id,
                expression: "currentTemplate.scenario_id"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "hidden",
              name: _vm.name + "_scenario_id",
              "data-vv-as": "シナリオ配信"
            },
            domProps: { value: _vm.currentTemplate.scenario_id },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.currentTemplate,
                  "scenario_id",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "_scenario_id") }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-select-scenario", {
        attrs: { id: _vm.name, type: "normal" },
        on: { selectScenario: _vm.onSelectScenario }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }