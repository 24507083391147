var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-common01",
      attrs: {
        id: _vm.id ? _vm.id : "modalSelectTemplate",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-lg vh-90 modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm.folders && _vm.folders.length
                ? _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("folder-left", {
                        attrs: {
                          type: "template_message",
                          isPerview: true,
                          data: _vm.folders,
                          isPc: _vm.isPc,
                          selectedFolder: _vm.selectedFolder
                        },
                        on: {
                          changeSelectedFolder: _vm.changeSelectedFolderTemplate
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-grow-1 scroll-table" }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", { staticClass: "thead-light" }, [
                            _c("tr", [
                              _c("th", [
                                _vm.folders[_vm.selectedFolder]
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.folders[_vm.selectedFolder].name
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.folders[this.selectedFolder].templates &&
                          _vm.folders[this.selectedFolder].templates.length
                            ? _c(
                                "tbody",
                                _vm._l(
                                  _vm.folders[this.selectedFolder].templates,
                                  function(item, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        staticClass: "folder-item"
                                      },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "d-flex w-100" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "box-item-name" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 vw-10 item-name"
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "btn btn-info btn-sm ml-auto my-auto",
                                                attrs: {
                                                  "data-dismiss": "modal"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectTemplate(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      選択\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("tbody", [_vm._m(1)])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("テンプレートを選択してください")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "text-center pt40" }, [
        _vm._v("データーがありません")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }