var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showTitle
      ? _c("div", [
          _c(
            "label",
            { staticClass: "w-100" },
            [
              _vm._v("\n      ラベル\n      "),
              _vm.requiredLabel ? _c("required-mark") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.label,
                    expression: "data.label"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.requiredLabel && _vm.showTitle },
                    expression: "{ required: requiredLabel && showTitle }"
                  }
                ],
                staticClass: "w-100 form-control",
                attrs: {
                  type: "text",
                  name: "datetime-label",
                  placeholder: "ラベルを入力してください",
                  maxlength: "12",
                  "data-vv-as": "ラベル"
                },
                domProps: { value: _vm.data.label },
                on: {
                  keyup: _vm.changeValue,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "label", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("error-message", {
                attrs: { message: _vm.errors.first("datetime-label") }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("label", { staticClass: "w-100 mt20" }, [_vm._v(" モード ")]),
    _vm._v(" "),
    _c("div", { staticClass: "w-100" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data.mode,
              expression: "data.mode"
            }
          ],
          staticClass: "form-control w-100 form-control",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.data,
                  "mode",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.changeValue
            ]
          }
        },
        _vm._l(_vm.modes, function(item, index) {
          return _c("option", { key: index, domProps: { value: item.value } }, [
            _vm._v("\n        " + _vm._s(item.text) + "\n      ")
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }