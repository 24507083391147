var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-header d-flex align-items-center" },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success mr-2",
                    attrs: {
                      href:
                        _vm.rootUrl +
                        "/user/scenarios/" +
                        _vm.scenario.id +
                        "/messages/new"
                    }
                  },
                  [
                    _c("i", { staticClass: "uil-plus" }),
                    _vm._v(" メッセージを追加")
                  ]
                ),
                _vm._v(" "),
                _c("scenario-select-template", {
                  attrs: { scenario_id: _vm.scenario.id }
                }),
                _vm._v(" "),
                _vm.messages.length > 0
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-info text-white ml-2",
                        attrs: {
                          role: "button",
                          "data-toggle": "modal",
                          "data-target": "#modalSendScenarioToTesters"
                        }
                      },
                      [_vm._v("テスト配信")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-centered mb-0" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._l(_vm.messages, function(message, index) {
                        return _c("tbody", { key: index }, [
                          _c("tr", [
                            _c("td", [
                              message.status == "enabled"
                                ? _c("span", [
                                    _vm._v(_vm._s(message.step) + "通目")
                                  ])
                                : _c("span", [_vm._v("未設定")])
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.scheduleTimeFor(message)) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(message.name ? message.name : "未設定")
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("message-type-label", {
                                  attrs: { data: message.content }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("message-content", {
                                  attrs: { data: message }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("scenario-message-status", {
                                  attrs: { status: message.status }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-menu" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        href:
                                          _vm.rootUrl +
                                          "/user/scenarios/" +
                                          _vm.scenario.id +
                                          "/messages/" +
                                          message.id +
                                          "/edit"
                                      }
                                    },
                                    [_vm._v("メッセージを編集")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        "data-toggle": "modal",
                                        "data-target":
                                          "#modalDeleteScenarioMessage"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.curMessageIndex = index
                                        }
                                      }
                                    },
                                    [_vm._v("メッセージを削除")]
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center mt-4 text-center"
                    },
                    [
                      _vm.totalRows > _vm.perPage
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              "aria-controls": "my-table"
                            },
                            on: { change: _vm.loadPage },
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.loading && _vm.totalRows === 0
                    ? _c(
                        "div",
                        { staticClass: "text-center my-5 font-weight-bold" },
                        [
                          _vm._v(
                            "\n              シナリオメッセージはありません。\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("loading-indicator", { attrs: { loading: _vm.loading } })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "本当に削除してよろしですか?",
          id: "modalDeleteScenarioMessage",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteMessage },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curMessage
                  ? _c("div", [
                      _vm._v("\n        メッセージ名：: "),
                      _c("b", [_vm._v(_vm._s(_vm.curMessage.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "シナリオのテスト配信のため、テスターを選択してください。",
          id: "modalSendScenarioToTesters",
          type: "confirm",
          confirmButtonDisabled: _vm.selectedTesterIds.length === 0,
          confirmButtonLabel: "テスト配信"
        },
        on: { confirm: _vm.submitSendScenarioToTesters },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.testers && _vm.testers.length
                  ? _c(
                      "div",
                      { staticClass: "d-flex" },
                      _vm._l(_vm.testers, function(tester) {
                        return _c(
                          "div",
                          {
                            key: "tester_" + tester.id,
                            staticClass:
                              "flex-1 custom-control custom-checkbox mr-2"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedTesterIds,
                                  expression: "selectedTesterIds"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "tester_" + tester.id
                              },
                              domProps: {
                                value: tester.id,
                                checked: Array.isArray(_vm.selectedTesterIds)
                                  ? _vm._i(_vm.selectedTesterIds, tester.id) >
                                    -1
                                  : _vm.selectedTesterIds
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectedTesterIds,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = tester.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectedTesterIds = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectedTesterIds = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectedTesterIds = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "tester_" + tester.id }
                              },
                              [_vm._v(_vm._s(tester.display_name))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [_c("span", [_vm._v("テスターはありません。")])])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("通目")]),
        _vm._v(" "),
        _c("th", [_vm._v("時刻")]),
        _vm._v(" "),
        _c("th", [_vm._v("メッセージ名")]),
        _vm._v(" "),
        _c("th", [_vm._v("タイプ")]),
        _vm._v(" "),
        _c("th", [_vm._v("メッセージプレビュー")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-120" }, [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-200" }, [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }