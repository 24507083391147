var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.staff.status == "active"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-success" }),
        _vm._v(" 有効 ")
      ])
    : _vm.staff.status == "blocked"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-warning" }),
        _vm._v(" 無効 ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }