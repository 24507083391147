var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-2" }, [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mw-200",
          attrs: { disabled: _vm.notUseShorternUrl },
          on: { click: _vm.searchSites }
        },
        [_vm._v("読み込み")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "checkbox-inline ml-3" }, [
        _c(
          "div",
          {
            staticClass: "custom-control custom-checkbox mr-4",
            on: { click: _vm.changeShortenUrlUsage }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.notUseShorternUrl,
                  expression: "notUseShorternUrl"
                }
              ],
              staticClass: "custom-control-input",
              attrs: { type: "checkbox" },
              domProps: {
                value: true,
                checked: Array.isArray(_vm.notUseShorternUrl)
                  ? _vm._i(_vm.notUseShorternUrl, true) > -1
                  : _vm.notUseShorternUrl
              },
              on: {
                change: function($event) {
                  var $$a = _vm.notUseShorternUrl,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = true,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.notUseShorternUrl = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.notUseShorternUrl = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.notUseShorternUrl = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "custom-control-label text-nowrap" }, [
              _vm._v("このメッセージではURLを短縮しない")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.sitesInMessageContent && _vm.sitesInMessageContent.length,
            expression: "sitesInMessageContent && sitesInMessageContent.length"
          }
        ],
        staticClass: "row"
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.notUseShorternUrl,
                expression: "!notUseShorternUrl"
              }
            ],
            class: "mt-2 " + (_vm.showConfigUrlPanel ? "col-sm-6" : "col")
          },
          [
            _c("table", { staticClass: "table table-centered mb-0" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticStyle: { width: "50%" } }, [
                    _vm._v("サイト名")
                  ]),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showConfigUrlPanel,
                          expression: "!showConfigUrlPanel"
                        }
                      ],
                      class:
                        "d-none " +
                        (_vm.showConfigUrlPanel ? "" : "d-md-table-cell"),
                      staticStyle: { width: "30%" }
                    },
                    [_vm._v("訪問時")]
                  ),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.sitesInMessageContent, function(site, index) {
                  return _c(
                    "tr",
                    {
                      key: site.id,
                      class:
                        "site-row " +
                        (_vm.selectedSiteIndex === index ? "selected" : "")
                    },
                    [
                      _c("td", [
                        _c("p", { staticClass: "mxw-400" }, [
                          _c("strong", [_vm._v(_vm._s(site.name))])
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(site.url))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showConfigUrlPanel,
                              expression: "!showConfigUrlPanel"
                            }
                          ],
                          class:
                            "d-none " +
                            (_vm.showConfigUrlPanel ? "" : "d-md-table-cell")
                        },
                        [
                          _c("ChoseActionsPresentor", {
                            attrs: { actions: _vm.getActionOfSite(site) }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        !_vm.showConfigUrlPanel
                          ? _c(
                              "div",
                              { staticClass: "float-right d-flex flex-column" },
                              [
                                !_vm.mutationSiteMeasurements.find(function(
                                  siteMeasurement
                                ) {
                                  return siteMeasurement.site_id === site.id
                                })
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-secondary mw-100 mb-1",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectSite(index)
                                          }
                                        }
                                      },
                                      [_vm._v("登録")]
                                    )
                                  : _c("span", [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-primary mw-100",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectSite(index)
                                            }
                                          }
                                        },
                                        [_vm._v("設定")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-danger mw-100",
                                          attrs: {
                                            "data-toggle": "modal",
                                            "data-target":
                                              "#modalDeleteSiteMeasurement_" +
                                              site.id
                                          }
                                        },
                                        [_vm._v("削除")]
                                      )
                                    ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("modal-confirm", {
                        attrs: {
                          title: "このサイト設定を削除してもよろしいですか？",
                          id: "modalDeleteSiteMeasurement_" + site.id,
                          type: "delete"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.removeSite(site)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm.sitesInMessageContent[_vm.selectedSiteIndex]
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showConfigUrlPanel,
                    expression: "showConfigUrlPanel"
                  }
                ],
                staticClass: "col-sm-6"
              },
              [
                _c("ValidationObserver", {
                  ref: "observer",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var validate = ref.validate
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "panel panel-default border p-2 rounded"
                              },
                              [
                                _c("div", { staticClass: "mb-2" }, [
                                  _c("b", [_vm._v("サイト設定")]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v("URL:"),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sitesInMessageContent[
                                            _vm.selectedSiteIndex
                                          ].url
                                        )
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "panel-body" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "サイト登録名",
                                          rules: "max:255"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("サイト登録名")
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.siteName,
                                                            expression:
                                                              "siteName"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "255"
                                                        },
                                                        domProps: {
                                                          value: _vm.siteName
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.siteName =
                                                              $event.target.value
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error-explanation"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("b", [_vm._v("リダイレクト設定")]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(
                                            "\n                (元)\n                "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                href:
                                                  _vm.sitesInMessageContent[
                                                    _vm.selectedSiteIndex
                                                  ].url
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.sitesInMessageContent[
                                                    _vm.selectedSiteIndex
                                                  ].url
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "リダイレクトURL",
                                              rules: "url"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "input-group-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "(変更) ->"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.redirectUrl,
                                                                expression:
                                                                  "redirectUrl"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                "http://example.com"
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.redirectUrl
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.redirectUrl =
                                                                  $event.target.value
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error-explanation"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _c(
                                              "small",
                                              { staticClass: "form-help" },
                                              [
                                                _vm._v(
                                                  "\n                  転送先を変更します。"
                                                ),
                                                _c("br"),
                                                _c(
                                                  "b",
                                                  {
                                                    staticStyle: {
                                                      color: "red"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "記録は元(変更前)のURLへのアクセスとしてカウントされます"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "div",
                                          { staticClass: "has-modal-xl" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c("action-editor-custom", {
                                                    key: _vm.contentKey,
                                                    attrs: {
                                                      requiredLabel:
                                                        _vm.requiredLabel,
                                                      showTitle: _vm.showTitle,
                                                      showLaunchMessage: false,
                                                      value: _vm.actionData
                                                    },
                                                    on: {
                                                      input: _vm.updateAction
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "panel-footer" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger float-left",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.showConfigUrlPanel = false
                                          _vm.selectedSiteIndex = null
                                        }
                                      }
                                    },
                                    [_vm._v("閉じる")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success float-right",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          validate().then(_vm.configUrl)
                                        }
                                      }
                                    },
                                    [_vm._v("設定する")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "clearfix" })
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2477231943
                  )
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }