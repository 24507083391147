var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modalSurveyPreview",
      staticClass: "modal fade",
      attrs: {
        id: "modalSurveyPreview",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "info-header-modalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { key: _vm.contentKey, staticClass: "modal-body" },
            [
              _c("survey-form-content", {
                attrs: { survey: _vm.survey, preview: true }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "info-header-modalLabel" } },
        [_vm._v("回答フォームプレビュー")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }