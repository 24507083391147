var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fw-300" }, [
    !_vm.isPerview
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "btn btn-success",
              on: { click: _vm.addMoreFolder }
            },
            [
              _c("i", { staticClass: "uil-plus" }),
              _vm._v("\n      新しいフォルダー\n    ")
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "mt-2",
        class: !_vm.isPerview ? "" : "tag-content-preview"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex m-0 overflow-auto flex-column folder-list" },
          [
            _vm.isAddMoreFolder
              ? _c(
                  "div",
                  { staticClass: "folder-item new-folder" },
                  [
                    _c("div", { staticClass: "input-group d-flex" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.folderData.name,
                            expression: "folderData.name",
                            modifiers: { trim: true }
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:32",
                            expression: "'required|max:32'"
                          }
                        ],
                        ref: "folderName",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "フォルダー名",
                          name: "folder_name",
                          maxlength: "33",
                          "data-vv-as": "フォルダー名"
                        },
                        domProps: { value: _vm.folderData.name },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.enterSubmitAddNewFolder.apply(
                              null,
                              arguments
                            )
                          },
                          compositionend: function($event) {
                            return _vm.compositionend($event)
                          },
                          compositionstart: function($event) {
                            return _vm.compositionstart($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.folderData,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-auto" }, [
                        _c(
                          "div",
                          {
                            ref: "buttonChange",
                            staticClass: "btn btn-default",
                            on: { click: _vm.submitCreateFolder }
                          },
                          [_vm._v("決定")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: { message: _vm.errors.first("folder_name") }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.data || [], function(item, index) {
              return _c("folder-left-item", {
                key: index,
                attrs: {
                  type: _vm.type,
                  isPerview: _vm.isPerview,
                  data: item,
                  active: _vm.selectedFolder == index,
                  index: index
                },
                on: {
                  changeSelected: function($event) {
                    return _vm.changeSelectedFolder(index)
                  },
                  editTag: _vm.submitUpdateFolder
                }
              })
            }),
            _vm._v(" "),
            _vm.data.length === 0
              ? _c("div", { staticClass: "text-center mt-4" }, [
                  _vm._v("データはありません")
                ])
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "table table-centered mb-0" }, [
      _c("thead", { staticClass: "thead-light" }, [
        _c("tr", [
          _c("th", { staticClass: "fh-42", attrs: { scope: "col" } }, [
            _vm._v("フォルダー")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }