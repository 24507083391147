var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mxw-1200" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group d-flex align-items-center" }, [
              _c("label", { staticClass: "fw-300" }, [_vm._v("フォルダー")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.richMenuData.folder_id,
                        expression: "richMenuData.folder_id"
                      }
                    ],
                    staticClass: "form-control fw-300",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.richMenuData,
                          "folder_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.folders, function(folder, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: folder.id } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(folder.name) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group d-flex align-items-start" }, [
              _c(
                "div",
                { staticClass: "d-flex fw-300 align-items-center" },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold" },
                    [_vm._v("リッチメニュー名"), _c("required-mark")],
                    1
                  ),
                  _vm._v(" "),
                  _c("tool-tip", {
                    attrs: {
                      title:
                        "タイトルは管理画面のみで使用され、ユーザーには表示されません。"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-grow-1" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.richMenuData.name,
                        expression: "richMenuData.name",
                        modifiers: { trim: true }
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:255",
                        expression: "'required|max:255'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "name",
                      placeholder: "リッチメニュー（ホーム）",
                      "data-vv-as": "リッチメニュー名",
                      maxlength: "256"
                    },
                    domProps: { value: _vm.richMenuData.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.richMenuData,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-message", {
                    attrs: { message: _vm.errors.first("name") }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group d-flex align-items-start mt-2" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex fw-300 align-items-center" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold" },
                      [_vm._v("トークルームメニュー"), _c("required-mark")],
                      1
                    ),
                    _vm._v(" "),
                    _c("tool-tip", {
                      attrs: {
                        title:
                          "チャットルームの下部にあるメニューバーに表示するテキストです。"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.richMenuData.chat_bar_text,
                          expression: "richMenuData.chat_bar_text",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:14",
                          expression: "'required|max:14'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "richmenu-title",
                        placeholder: "トップメニュー",
                        maxlength: "15",
                        "data-vv-as": "トークルームメニュー"
                      },
                      domProps: { value: _vm.richMenuData.chat_bar_text },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.richMenuData,
                            "chat_bar_text",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: { message: _vm.errors.first("richmenu-title") }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group d-flex align-items-start mt-2" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex fw-300 align-items-center" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold" },
                      [_vm._v("メニューの初期状態"), _c("required-mark")],
                      1
                    ),
                    _vm._v(" "),
                    _c("tool-tip", {
                      attrs: {
                        title:
                          "チャットルームを開いたときに、リッチメニューを表示するかしないかを選択します。"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "radio-group flex-grow-1 flex-column" },
                  [
                    _c("div", { staticClass: "radio" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.richMenuData.selected,
                              expression: "richMenuData.selected"
                            }
                          ],
                          attrs: { name: "selected", type: "radio" },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.richMenuData.selected, true)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.richMenuData,
                                "selected",
                                true
                              )
                            }
                          }
                        }),
                        _vm._v("\n              表示する\n            ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.richMenuData.selected,
                              expression: "richMenuData.selected"
                            }
                          ],
                          attrs: { name: "selected", type: "radio" },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.richMenuData.selected, false)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.richMenuData,
                                "selected",
                                false
                              )
                            }
                          }
                        }),
                        _vm._v("\n              表示しない\n            ")
                      ])
                    ])
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("rich-menu-content-editor", {
                key: _vm.contentKey,
                attrs: {
                  background: _vm.backgroundUrl,
                  templateId: _vm.richMenuData.template_id,
                  piecesCount: _vm.templateValue,
                  templateType: _vm.templateType,
                  areas: _vm.richMenuData.areas
                },
                on: {
                  input: _vm.richMenu,
                  onMediaChanged: function($event) {
                    return _vm.onMediaChanged($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "radio-group" }, [
              _c("label", { attrs: { role: "button" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.richMenuData.target,
                      expression: "richMenuData.target"
                    }
                  ],
                  staticClass: "mr-1",
                  attrs: { type: "radio", name: "target", value: "all" },
                  domProps: { checked: _vm._q(_vm.richMenuData.target, "all") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.richMenuData, "target", "all")
                    }
                  }
                }),
                _vm._v("全員")
              ]),
              _vm._v(" "),
              _c("label", { attrs: { role: "button" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.richMenuData.target,
                      expression: "richMenuData.target"
                    }
                  ],
                  staticClass: "mr-1",
                  attrs: { type: "radio", name: "target", value: "condition" },
                  domProps: {
                    checked: _vm._q(_vm.richMenuData.target, "condition")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.richMenuData, "target", "condition")
                    }
                  }
                }),
                _vm._v("タグで絞り込む")
              ])
            ]),
            _vm._v(" "),
            _vm.richMenuData.target === "condition"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "list-checkbox-tag" },
                    [
                      _c("input-tag", {
                        attrs: { tags: _vm.tags },
                        on: { input: _vm.onTagsChanged }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          { staticClass: "btn btn-success fw-120", on: { click: _vm.submit } },
          [_vm._v("保存")]
        )
      ]),
      _vm._v(" "),
      _c("modal-rich-menu-template-selection", {
        attrs: { selectionId: _vm.richMenuData.template_id },
        on: { accept: _vm.onTemplateChanged }
      }),
      _vm._v(" "),
      _c("modal-select-media", {
        attrs: { types: ["richmenu"], filterable: false },
        on: {
          select: function($event) {
            return _vm.onMediaChanged($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("基本設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("本文設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("配信先設定")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }