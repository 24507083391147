var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.id ? _vm.id : "modalSelectReminder",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-lg vh-90 modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm.folders && _vm.folders.length
                ? _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("folder-left", {
                        attrs: {
                          type: "reminder",
                          isPerview: true,
                          data: _vm.folders,
                          isPc: _vm.isPc,
                          selectedFolder: _vm.selectedFolder
                        },
                        on: { changeSelectedFolder: _vm.changeSelectedReminder }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", { staticClass: "thead-light" }, [
                            _c("tr", [
                              _c("th", [
                                _vm.curFolder
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.curFolder.name))
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.curFolder.reminders &&
                          _vm.curFolder.reminders.length
                            ? _c(
                                "tbody",
                                _vm._l(_vm.curFolder.reminders, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "tr",
                                    { key: index, staticClass: "folder-item" },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "d-flex w-100" },
                                        [
                                          _c("div", { staticClass: "mr-1" }, [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn btn-info btn-sm ml-auto my-auto fw-80",
                                              attrs: {
                                                "data-dismiss": "modal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectReminder(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      選択\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("tbody", [_vm._m(1)])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("リマインダを選択してください")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "text-center my-5" }, [
        _vm._v("データーがありません")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }