var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var validate = ref.validate
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: {
                      action: _vm.getAction(),
                      method: "post",
                      enctype: "multipart/form-data"
                    },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        validate().then(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: "authenticity_token" },
                      domProps: { value: _vm.csrfToken }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group mb-3" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "emailaddress" } },
                          [_vm._v("メールアドレス"), _c("required-mark")],
                          1
                        ),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "メールアドレス",
                            rules: "required|email|max:255"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.settingFormData.email,
                                          expression: "settingFormData.email",
                                          modifiers: { trim: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "agency[email]",
                                        placeholder: "入力してください",
                                        maxlength: "256"
                                      },
                                      domProps: {
                                        value: _vm.settingFormData.email
                                      },
                                      on: {
                                        keyup: function($event) {
                                          _vm.server_errors = []
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.settingFormData,
                                            "email",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "error-explanation" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            errors[0] || _vm.server_errors[0]
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit", disabled: invalid }
                        },
                        [_vm._v("\n          メールを送信\n        ")]
                      )
                    ])
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }