var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.episode.is_initial
    ? _c("div", [_vm._v("開始時")])
    : _c("div", [
        _c("span", [
          _vm._v(
            _vm._s(_vm.episode.date === 0 ? "当日" : _vm.episode.date + "日前")
          )
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.episode.time))])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }