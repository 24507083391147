var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border rounded" }, [
    _c(
      "div",
      { staticClass: "comment-area-box" },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "max:5000",
              expression: "'max:5000'"
            }
          ],
          staticClass: "form-control border-0 resize-none",
          attrs: {
            disabled: !_vm.isEnabled,
            rows: "4",
            name: "message",
            placeholder: "Enterで改行、Shift+Enterで送信",
            "data-vv-as": "メッセージ",
            maxlength: "5001"
          },
          domProps: { value: _vm.message },
          on: {
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (!$event.shiftKey) {
                  return null
                }
                if ($event.ctrlKey || $event.altKey || $event.metaKey) {
                  return null
                }
                $event.preventDefault()
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (!$event.shiftKey) {
                  return null
                }
                if ($event.ctrlKey || $event.altKey || $event.metaKey) {
                  return null
                }
                return _vm.sendTextMessage.apply(null, arguments)
              }
            ],
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.message = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.first("message")
          ? _c("error-message", {
              staticClass: "ml-2",
              attrs: { message: _vm.errors.first("message") }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "p-2 bg-light d-flex justify-content-between align-items-center"
          },
          [
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "btn btn-sm px-2 btn-light",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#modalSelectSticker"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showStickerModal()
                    }
                  }
                },
                [_c("i", { staticClass: "uil font-20 uil-smile" })]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1)
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success",
                attrs: { disabled: !_vm.isEnabled || !_vm.message.trim() },
                on: { click: _vm.sendTextMessage }
              },
              [
                _c("i", { staticClass: "uil uil-message mr-1" }),
                _vm._v("送信\n      ")
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-sm px-2 font-20 btn-light",
        attrs: { "data-toggle": "modal", "data-target": "#modalSendMedia" }
      },
      [_c("i", { staticClass: "uil uil-file" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-group dropup" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm px-2 btn-light dropdown-toggle",
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [_c("i", { staticClass: "uil-plus font-20" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu" }, [
        _c(
          "div",
          {
            staticClass: "dropdown-item",
            attrs: {
              role: "button",
              "data-toggle": "modal",
              "data-target": "#modalSendTemplate"
            }
          },
          [_vm._v("\n              テンプレート配信\n            ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown-divider" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-item",
            attrs: {
              role: "button",
              "data-toggle": "modal",
              "data-target": "#modalSendScenario"
            }
          },
          [_vm._v("\n              シナリオ配信\n            ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }