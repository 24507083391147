import { render, staticRenderFns } from "./RichMenuTarget.vue?vue&type=template&id=2251961a&scoped=true&"
import script from "./RichMenuTarget.vue?vue&type=script&lang=js&"
export * from "./RichMenuTarget.vue?vue&type=script&lang=js&"
import style0 from "./RichMenuTarget.vue?vue&type=style&index=0&id=2251961a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2251961a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2251961a')) {
      api.createRecord('2251961a', component.options)
    } else {
      api.reload('2251961a', component.options)
    }
    module.hot.accept("./RichMenuTarget.vue?vue&type=template&id=2251961a&scoped=true&", function () {
      api.rerender('2251961a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/rich-menu/RichMenuTarget.vue"
export default component.exports