var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "buttons-content" },
      [
        _c("div", { staticClass: "buttons-header" }, [
          _c("b", { staticClass: "buttons-title" }, [
            _vm._v(_vm._s(_vm.data.title))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "buttons-text" }, [
            _vm._v("\n        " + _vm._s(_vm.data.text) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.data.actions, function(item, index) {
          return _c("div", { key: index, staticClass: "selector-button" }, [
            _vm._v(
              "\n      " + _vm._s(item.label ? item.label : " ") + "\n    "
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }