var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey" }, [
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c(
        "span",
        { staticClass: "fw-200" },
        [_vm._v("項目名"), _c("required-mark")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.value.text,
                expression: "value.text",
                modifiers: { trim: true }
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:255",
                expression: "'required|max:255'"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: _vm.name + "-text",
              maxlength: "256",
              placeholder: "項目名を入力してください",
              "data-vv-as": "項目名"
            },
            domProps: { value: _vm.value.text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "text", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "-text") }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c("div", { staticClass: "fw-200 d-flex align-items-center" }, [
        _c("span", [_vm._v("補足文")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "ml-2",
            attrs: { title: "回答入力欄の下に表示されます" }
          },
          [_c("i", { staticClass: "text-md far fa-question-circle" })]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.value.sub_text,
                expression: "value.sub_text",
                modifiers: { trim: true }
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "max:255",
                expression: "'max:255'"
              }
            ],
            staticClass: "form-control",
            attrs: {
              name: _vm.name + "-subtext",
              type: "text",
              placeholder: "補足文を入力してください",
              maxlength: "256",
              "data-vv-as": "補足文"
            },
            domProps: { value: _vm.value.sub_text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "sub_text", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            attrs: { message: _vm.errors.first(_vm.name + "-subtext") }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group clearfix d-flex" }, [
      _c("span", { staticClass: "fw-200" }, [_vm._v("回答の情報登録")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("survey-variable-config", {
            attrs: {
              type: "datetime",
              field: _vm.value.variable ? _vm.value.variable.name : null,
              name: _vm.name + "-infomation"
            },
            on: {
              input: function($event) {
                _vm.value.variable = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }