import { render, staticRenderFns } from "./ChannelFriendDetail.vue?vue&type=template&id=00d8ac24&scoped=true&"
import script from "./ChannelFriendDetail.vue?vue&type=script&lang=js&"
export * from "./ChannelFriendDetail.vue?vue&type=script&lang=js&"
import style0 from "./ChannelFriendDetail.vue?vue&type=style&index=0&id=00d8ac24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d8ac24",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00d8ac24')) {
      api.createRecord('00d8ac24', component.options)
    } else {
      api.reload('00d8ac24', component.options)
    }
    module.hot.accept("./ChannelFriendDetail.vue?vue&type=template&id=00d8ac24&scoped=true&", function () {
      api.rerender('00d8ac24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/channel/ChannelFriendDetail.vue"
export default component.exports