var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v(
        "回答日時：" + _vm._s(_vm._f("formatted_time")(_vm.response.created_at))
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.response.survey_answers, function(answer, index) {
          return _c("div", { key: index }, [
            index > 0 ? _c("div", { staticClass: "divider my-2" }) : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex my-1" }, [
              _c("div", { staticClass: "mw-200 mr-2 font-weight-bold" }, [
                _vm._v(_vm._s(answer.survey_question.content["text"]))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }, [
                answer.survey_question.type === "image"
                  ? _c("div", [
                      _c("div", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy:background-image",
                            value: answer.file_url,
                            expression: "answer.file_url",
                            arg: "background-image"
                          }
                        ],
                        staticClass: "fw-120 fh-81 background-cover"
                      })
                    ])
                  : answer.survey_question.type === "pdf"
                  ? _c("div", { staticClass: "d-flex" }, [
                      _c("img", {
                        staticClass: "fw-120 fh-120 background-cover",
                        attrs: {
                          src: _vm.rootPath + "/images/messages/pdf.png"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-sm btn-light my-auto text-nowrap",
                          attrs: {
                            href: answer.file_url,
                            download: "lineinsight.pdf"
                          }
                        },
                        [_vm._v("ダウンロード")]
                      )
                    ])
                  : _c("p", { staticClass: "vw-60 item-name" }, [
                      _vm._v(_vm._s(answer.answer || "未設定"))
                    ])
              ])
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex py-2 border-bottom border-light" },
      [
        _c("div", { staticClass: "mw-200 mr-2 font-weight-bold" }, [
          _vm._v("質問")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grow-1" }, [_vm._v("回答")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }