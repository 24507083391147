var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-body" }, [
    _c("div", { class: _vm.itemClass, attrs: { role: "button" } }, [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.avatarImgObj,
            expression: "avatarImgObj"
          }
        ],
        staticClass: "mr-2 rounded-circle",
        attrs: { height: "48", alt: "User avatar" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "media-body" }, [
        _c("h5", { staticClass: "my-0 font-14" }, [
          _c("span", { staticClass: "float-right text-muted font-12" }, [
            _vm._v(_vm._s(_vm.readableTime))
          ]),
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.truncate(
                  _vm.friend.display_name || _vm.friend.line_name,
                  15
                )
              ) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-1 mb-0 text-muted font-14" }, [
          _c("span", { staticClass: "w-25 float-right text-right" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isUnread,
                    expression: "isUnread"
                  }
                ],
                staticClass: "badge badge-danger-lighten"
              },
              [_vm._v(_vm._s(_vm.unreadCountLabel))]
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            { class: _vm.isUnread ? "w-75 font-weight-bold" : "w-75" },
            [
              _c("last-message", {
                attrs: { message: _vm.channel.last_message }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }