var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { key: _vm.contentKey, staticClass: "border template-buttons row" },
      [
        _c(
          "div",
          { staticClass: "d-flex group-title col-12" },
          [
            _c(
              "label",
              { staticClass: "mt-2" },
              [_vm._v(" タイトル"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.templateData.title,
                  expression: "templateData.title",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:40",
                  expression: "'required|max:40'"
                }
              ],
              staticClass: "form-control",
              attrs: {
                placeholder: "タイトルを入力してください",
                type: "text",
                maxlength: "41",
                "data-vv-as": "タイトル",
                name: "button-title" + _vm.indexParent
              },
              domProps: { value: _vm.templateData.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.templateData,
                    "title",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("error-message", {
              attrs: {
                message: _vm.errors.first("button-title" + _vm.indexParent)
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex group-title col-12" },
          [
            _c(
              "label",
              { staticClass: "mt-2 d-flex align-items-center" },
              [_vm._v(" テキスト"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.templateData.text,
                  expression: "templateData.text",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:60",
                  expression: "'required|max:60'"
                }
              ],
              staticClass: "form-control",
              attrs: {
                placeholder: "テキストを入力してください",
                name: "button-text" + _vm.indexParent,
                type: "text",
                maxlength: "61",
                "data-vv-as": "テキスト"
              },
              domProps: { value: _vm.templateData.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.templateData, "text", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("error-message", {
              attrs: {
                message: _vm.errors.first("button-text" + _vm.indexParent)
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row col-12",
            staticStyle: { "margin-top": "15px !important" }
          },
          [
            _c("div", { staticClass: "col-sm-3" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs nav-stacked nav-buttons d-block" },
                [
                  _vm._l(_vm.templateData.actions, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: _vm.selected == index ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.changeSelected(index)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "nav-button d-flex",
                            class: _vm.errors.items.find(function(item) {
                              return item.field.includes(
                                _vm.indexParent + "template_button_" + index
                              )
                            })
                              ? "invalid-box"
                              : ""
                          },
                          [
                            _vm._v(
                              "\n              ボタン" +
                                _vm._s(index + 1) +
                                "\n              "
                            ),
                            _vm.templateData.actions.length > 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "ml-auto mr-1",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.removeAction(index)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "dripicons-trash" })]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.templateData.actions.length < 4
                    ? _c("li", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "nav-button btn btn-primary justify-content-center",
                            on: { click: _vm.addMoreAction }
                          },
                          [
                            _c("i", { staticClass: "uil-plus" }),
                            _vm._v(" 追加\n            ")
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("b", [_vm._v("選択肢" + _vm._s(_vm.selected + 1))])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  _vm._l(_vm.templateData.actions, function(item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index === _vm.selected,
                            expression: "index === selected"
                          }
                        ],
                        key: index
                      },
                      [
                        _c("action-editor", {
                          attrs: {
                            name:
                              "parent_" +
                              _vm.indexParent +
                              "template_button_" +
                              index,
                            value: item
                          },
                          on: {
                            input: function($event) {
                              return _vm.changeAction(index, $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }