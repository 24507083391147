var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.animation
    ? _c(
        "span",
        {
          staticClass: "sticker-item animation",
          attrs: { "data-dismiss": "modal" }
        },
        [
          _c("img", {
            staticClass: "sticker-animation position-absolute",
            staticStyle: { top: "0px" },
            attrs: {
              src:
                "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
                _vm.sticker.line_emoji_id +
                "/PC/sticker_animation.png"
            }
          }),
          _vm._v(" "),
          _vm.animation
            ? _c("img", {
                staticClass: "sticker-static",
                attrs: {
                  src:
                    "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
                    _vm.sticker.line_emoji_id +
                    "/PC/sticker.png"
                },
                on: { click: _vm.onSelectSticker }
              })
            : _vm._e()
        ]
      )
    : _c(
        "span",
        { staticClass: "sticker-item", attrs: { "data-dismiss": "modal" } },
        [
          _c("img", {
            staticClass: "sticker-static",
            attrs: {
              src:
                "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
                _vm.sticker.line_emoji_id +
                "/PC/sticker.png"
            },
            on: { click: _vm.onSelectSticker }
          })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }