var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card mvh-50" },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm._l(_vm.announcements, function(announcement) {
                    return _c(
                      "div",
                      {
                        key: announcement.id,
                        attrs: {
                          role: "button",
                          "data-toggle": "modal",
                          "data-target": "#modalAnnouncementDetail"
                        },
                        on: {
                          click: function($event) {
                            _vm.curAnnouncement = announcement
                          }
                        }
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "mt-0 mb-1 font-16 max-3-lines" },
                          [_vm._v(_vm._s(announcement.title))]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-muted" }, [
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.formattedDate(announcement.announced_at)
                              )
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.totalRows > _vm.perPage
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-content-center mt-4" },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              "aria-controls": "my-table"
                            },
                            on: { change: _vm.loadPage },
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.totalRows === 0
                    ? _c("div", { staticClass: "text-center mt-4" }, [
                        _c("b", [_vm._v("お知らせはありません。")])
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-announcement-detail", {
        attrs: { announcement: _vm.curAnnouncement }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-bottom border-primary text-md" },
      [_c("b", [_vm._v("お知らせ")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }