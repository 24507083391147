var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.friendData
    ? _c("div", { staticClass: "profile-detail row" }, [
        _c("div", { staticClass: "col-xl-4 d-flex" }, [
          _c(
            "div",
            { staticClass: "card card-success card-outline w-100" },
            [
              _c("div", { staticClass: "card-body box-profile" }, [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.avatarImgObj,
                        expression: "avatarImgObj"
                      }
                    ],
                    staticClass: "rounded-circle avatar-lg img-thumbnail",
                    attrs: { alt: "profile-image" }
                  })
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "profile-username text-center" }, [
                  _vm._v(_vm._s(_vm.friendData.line_name))
                ]),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c(
                      "span",
                      { staticClass: "d-flex flex-row w-100" },
                      [
                        _c("friend-toggle-visible", {
                          staticClass: "ml-auto mr-1",
                          attrs: {
                            id: _vm.friendData.id,
                            visible: _vm.friendData.visible
                          }
                        }),
                        _vm._v(" "),
                        _c("friend-toggle-locked", {
                          staticClass: "mr-auto",
                          attrs: {
                            id: _vm.friendData.id,
                            locked: _vm.friendData.locked
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group list-group-unbordered my-3" },
                  [
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("b", [_vm._v("ステータス")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "float-right" },
                        [
                          _c("friend-status", {
                            attrs: {
                              status: _vm.friendData.status,
                              locked: _vm.friendData.locked,
                              visible: _vm.friendData.visible
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("b", [_vm._v("トーク")]),
                      _c(
                        "a",
                        {
                          staticClass: "float-right",
                          attrs: { href: "/user/channels/" + _vm.channel_id }
                        },
                        [
                          _c("i", { staticClass: "uil-comment-alt-dots" }),
                          _vm._v(" メッセージ")
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("b", [_vm._v("登録日")]),
                      _c("span", { staticClass: "float-right" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatted_time")(_vm.friendData.created_at)
                          )
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("loading-indicator", { attrs: { loading: _vm.loading } })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-8" }, [
          _c(
            "div",
            { staticClass: "card card-success card-outline" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-muted mt-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.friendData.display_name,
                          expression: "friendData.display_name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:255",
                          expression: "'max:255'"
                        }
                      ],
                      ref: "displayName",
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "表示名",
                        name: "display_name",
                        disabled: !_vm.editing,
                        "data-vv-as": "表示名"
                      },
                      domProps: { value: _vm.friendData.display_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.friendData,
                            "display_name",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: { message: _vm.errors.first("display_name") }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-muted mt-2" },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.friendData.note,
                          expression: "friendData.note",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:2000",
                          expression: "'max:2000'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        rows: "2",
                        name: "note",
                        disabled: !_vm.editing,
                        "data-vv-as": "メモ欄"
                      },
                      domProps: { value: _vm.friendData.note },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.friendData,
                            "note",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: { message: _vm.errors.first("note") }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-muted mt-2" },
                  [
                    !_vm.editing
                      ? _c("friend-tag", {
                          attrs: { tags: _vm.friendData.tags }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editing
                      ? _c("input-tag", {
                          attrs: { tags: _vm.friendData.tags, allTags: true },
                          on: { input: _vm.selectTags }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-1 custom-control custom-checkbox mr-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.friendData.tester,
                          expression: "friendData.tester"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "checkbox",
                        value: "1",
                        name: "tester",
                        id: "is_tester_account",
                        disabled: !_vm.editing
                      },
                      domProps: {
                        checked: Array.isArray(_vm.friendData.tester)
                          ? _vm._i(_vm.friendData.tester, "1") > -1
                          : _vm.friendData.tester
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.friendData.tester,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.friendData,
                                  "tester",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.friendData,
                                  "tester",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.friendData, "tester", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "is_tester_account" }
                      },
                      [_vm._v("テスター")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm.isAdmin && !_vm.editing
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-success fw-120",
                        on: {
                          click: function($event) {
                            _vm.editing = true
                          }
                        }
                      },
                      [_vm._v("編集")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editing,
                        expression: "editing"
                      }
                    ],
                    staticClass: "btn btn-success fw-120",
                    on: {
                      click: function($event) {
                        return _vm.onSave()
                      }
                    }
                  },
                  [_vm._v("更新")]
                )
              ]),
              _vm._v(" "),
              _c("loading-indicator", { attrs: { loading: _vm.loading } })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane show active border border-light",
                attrs: { id: "customInfo" }
              },
              [
                _c("div", { staticClass: "card m-0 p-0" }, [
                  _c("div", { staticClass: "card-body p-0" }, [
                    _c(
                      "table",
                      { staticClass: "table table-striped table-centered" },
                      [
                        _c(
                          "tbody",
                          _vm._l(_vm.variables, function(variable, index) {
                            return _c("tr", { key: index }, [
                              _c("th", [_vm._v(_vm._s(variable.name))]),
                              _vm._v(" "),
                              variable.type === "image" && variable.value
                                ? _c("td", [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy:background-image",
                                          value: variable.value,
                                          expression: "variable.value",
                                          arg: "background-image"
                                        }
                                      ],
                                      staticClass:
                                        "fw-120 fh-81 background-cover"
                                    })
                                  ])
                                : variable.type === "pdf" && variable.value
                                ? _c("td", [
                                    _c("img", {
                                      staticClass:
                                        "fw-120 fh-120 background-cover",
                                      attrs: {
                                        src:
                                          _vm.rootPath +
                                          "/images/messages/pdf.png"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-sm btn-light",
                                        attrs: {
                                          href: variable.value,
                                          download: "lineinsight.pdf"
                                        }
                                      },
                                      [_vm._v("ダウンロード")]
                                    )
                                  ])
                                : _c("td", [
                                    _vm._v(_vm._s(variable.value || "未設定"))
                                  ])
                            ])
                          }),
                          0
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.variables.length === 0
                      ? _c(
                          "div",
                          { staticClass: "text-center py-5 font-weight-bold" },
                          [_vm._v("データはありません。")]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane show border border-light",
                attrs: { id: "reminder" }
              },
              [
                _vm.isAdmin
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("friend-assign-reminder", {
                            attrs: { friend_id: _vm.friend_id }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "card mt-2" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body py-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.reminders, function(reminder, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "mw-200 max-2-lines" }, [
                              _vm._v(_vm._s(reminder.name))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "mw-150" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatted_time")(reminder.created_at)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "mw-200" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatted_date")(reminder.goal))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "mw-150" },
                              [
                                _c("reminding-status", {
                                  attrs: { status: reminder.status }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.reminders.length === 0
                      ? _c("div", { staticClass: "text-center my-3" }, [
                          _vm._v("リマインダがありません。")
                        ])
                      : _vm._e()
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header d-flex align-items-center" }, [
      _c("h4", [_vm._v("詳細情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-1" }, [_c("strong", [_vm._v("表示名")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-1" }, [
      _c("strong", [_vm._v("メモ欄")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-1" }, [
      _c("strong", [_vm._v("タグ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: {
              href: "#customInfo",
              "data-toggle": "tab",
              "aria-expanded": "true"
            }
          },
          [
            _c("i", { staticClass: "mdi mdi-home-variant d-md-none d-block" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-block" }, [
              _vm._v("友達情報")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: {
              href: "#reminder",
              "data-toggle": "tab",
              "aria-expanded": "true"
            }
          },
          [
            _c("i", { staticClass: "mdi mdi-home-variant d-md-none d-block" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-block" }, [
              _vm._v("リマインダ")
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-lg-3" }, [_vm._v("リマインダ")]),
      _vm._v(" "),
      _c("label", { staticClass: "col-lg-9" }, [_vm._v("ゴール日")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", [_vm._v("最新リマインダ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("リマインダ名")]),
        _vm._v(" "),
        _c("th", [_vm._v("登録日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("ゴール")]),
        _vm._v(" "),
        _c("th", [_vm._v("状況")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }