var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v("本文"), _c("required-mark")], 1),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content.text,
            expression: "content.text"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|max:1000",
            expression: "'required|max:1000'"
          }
        ],
        staticClass: "form-control",
        attrs: {
          name: _vm.name + "_postback_text",
          placeholder: "本文を入力してください",
          rows: "4",
          maxlength: "1001",
          "data-vv-as": "本文"
        },
        domProps: { value: _vm.content.text },
        on: {
          keyup: function($event) {
            return _vm.changeValue($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.content, "text", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("error-message", {
        attrs: { message: _vm.errors.first(_vm.name + "_postback_text") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }