var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group d-flex align-items-center" }, [
            _c("label", { staticClass: "fw-300" }, [_vm._v("フォルダー")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reminderData.folder_id,
                      expression: "reminderData.folder_id"
                    }
                  ],
                  staticClass: "form-control fw-300",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.reminderData,
                        "folder_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.folders, function(folder, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: folder.id } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(folder.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c(
              "label",
              { staticClass: "fw-300 mb-auto" },
              [_vm._v("リマインダ名"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow-1" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.reminderData.name,
                      expression: "reminderData.name",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:64",
                      expression: "'required|max:64'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "reminder_name",
                    placeholder: "リマインダ名を入力してください",
                    maxlength: "65",
                    "data-vv-as": "リマインダ名"
                  },
                  domProps: { value: _vm.reminderData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.reminderData,
                        "name",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first("reminder_name") }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } }),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success text-nowrap mr-1",
              attrs: { disabled: _vm.invalid },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.reminder_id ? "リマインダ保存" : "リマインダ登録"
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }