var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c("ValidationProvider", {
        attrs: { name: "答え", rules: { required: _vm.isRequired } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c(
                  "div",
                  { staticClass: "form-group mt-2 position-relative" },
                  [
                    _c("datetime", {
                      attrs: {
                        "input-class":
                          "form-control btn border border-light text-left",
                        type: "time",
                        phrases: { ok: "確定", cancel: "閉じる" },
                        placeholder: "時刻を選択してください",
                        "value-zone": "Asia/Tokyo",
                        zone: "Asia/Tokyo",
                        name: "answers[" + _vm.qnum + "][answer]"
                      },
                      model: {
                        value: _vm.answer,
                        callback: function($$v) {
                          _vm.answer = $$v
                        },
                        expression: "answer"
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "dripicons-chevron-down dropdown-icon"
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("error-message", { attrs: { message: errors[0] } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }