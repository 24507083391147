var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-delete modal-common01",
      attrs: {
        id: "modal-delete",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "mb10 fz14" }, [
              _vm._v("以下のメッセージを削除してもよろしいですか？")
            ]),
            _vm._v(" "),
            _c(
              "dl",
              {
                staticClass:
                  "flex group-modal01 no-mgn flex-wrap justify-content-between"
              },
              [
                _c("dt", [_vm._v("配信日時")]),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(_vm._s(_vm.formatDateTime(_vm.data.schedule_at)))
                ]),
                _vm._v(" "),
                _c("dt", [_vm._v("タイトル")]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.data.title))]),
                _vm._v(" "),
                _c("dt", [_vm._v("配信先")]),
                _vm._v(" "),
                _c("dd", [_vm._v("全員")]),
                _vm._v(" "),
                _c("dt", [_vm._v("ステータス")]),
                _vm._v(" "),
                _c("dd", [
                  _vm.data.status === "pending"
                    ? _c("span", [_vm._v("配信待ち\n            ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.status === "sending"
                    ? _c("span", [_vm._v("配信待ち\n            ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.status === "done"
                    ? _c("span", [_vm._v("配信済み\n            ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.status === "draft"
                    ? _c("span", [_vm._v("下書き\n            ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.status === "error"
                    ? _c("span", [_vm._v("配信エラー\n            ")])
                    : _vm._e()
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer flex center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-common01 btn-modal-delete",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: { click: _vm.deleteMessage }
              },
              [_vm._v("削除")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-common01 btn-modal-cancel",
                attrs: { type: "button", "data-dismiss": "modal" }
              },
              [_vm._v("キャンセル")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }