var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      attrs: { type: "hidden", name: "answers[" + _vm.qnum + "][id]" },
      domProps: { value: _vm.question.id }
    }),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "w-100" },
      [
        _c(
          "span",
          {
            staticClass: "border-success question-title mr-2 font-weight-bold"
          },
          [_vm._v(_vm._s("Q" + _vm.qnum))]
        ),
        _vm._v(_vm._s(_vm.title) + " "),
        _vm.isRequired ? _c("required-mark") : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "w-100 text-muted text-sm my-1" }, [
      _vm._v(_vm._s(_vm.subTitle))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }