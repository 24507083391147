import { render, staticRenderFns } from "./LogMessage.vue?vue&type=template&id=383d2b18&"
import script from "./LogMessage.vue?vue&type=script&lang=js&"
export * from "./LogMessage.vue?vue&type=script&lang=js&"
import style0 from "./LogMessage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hatamoto.dev/hotel-insight-crm/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('383d2b18')) {
      api.createRecord('383d2b18', component.options)
    } else {
      api.reload('383d2b18', component.options)
    }
    module.hot.accept("./LogMessage.vue?vue&type=template&id=383d2b18&", function () {
      api.rerender('383d2b18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/src/components/message/type/LogMessage.vue"
export default component.exports