var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("label", [_vm._v(" 宛先 ")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          staticClass: "d-none",
          attrs: {
            type: "text",
            name: _vm.name + "_postback_email",
            "data-vv-as": "宛先"
          },
          domProps: { value: _vm.form.emails }
        }),
        _vm._v(" "),
        _c("b-form-tags", {
          staticClass: "form-control d-flex",
          attrs: {
            "aria-invalid": _vm.errors.first(_vm.name + "_postback_email")
              ? true
              : false,
            "input-id": "tags-basic",
            "tag-validator": _vm.tagValidator,
            "add-button-text": "追加",
            "invalid-tag-text": null,
            "duplicate-tag-text": "宛先が重複しています。",
            "limit-tags-text": "宛先の最大数に達しました",
            placeholder: "メールアドレスを入力してください。",
            separator: " ,;"
          },
          model: {
            value: _vm.form.emails,
            callback: function($$v) {
              _vm.$set(
                _vm.form,
                "emails",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "form.emails"
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: { message: _vm.errors.first(_vm.name + "_postback_email") }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("label", [_vm._v("本文")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.text,
              expression: "form.text",
              modifiers: { trim: true }
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          staticClass: "form-control",
          attrs: {
            name: _vm.name + "_postback_text_email",
            rows: "4",
            placeholder: "入力してください",
            "data-vv-as": "本文"
          },
          domProps: { value: _vm.form.text },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "text", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("error-message", {
          attrs: {
            message: _vm.errors.first(_vm.name + "_postback_text_email")
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-sm" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("{name}：お客様の名前")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }