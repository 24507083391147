var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "badge badge-md badge-danger m-1 p-1" }, [
    _vm._v("必須")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }