var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group input-group-merge" },
    [
      _c("div", { staticClass: "input-group input-group-merge" }, [
        _c("input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            },
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.password,
              expression: "password",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: _vm.inputType,
            "data-vv-as": "パースワード",
            name: _vm.name,
            placeholder: "パスワードを入力してください"
          },
          domProps: { value: _vm.password },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value.trim()
              },
              _vm.onInput
            ],
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input-group-append",
            attrs: { "data-password": "false" },
            on: {
              click: function($event) {
                return _vm.toggle()
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "input-group-text",
                class: _vm.inputType === "text" ? "show-password" : ""
              },
              [_c("span", { staticClass: "password-eye" })]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("error-message", { attrs: { message: _vm.errors.first(_vm.name) } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }