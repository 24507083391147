var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      { staticClass: "mt-4" },
      [_vm._v("ラベル"), _c("required-mark")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value.label,
              expression: "value.label"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          staticClass: "gw-100 form-control",
          attrs: {
            type: "text",
            maxlength: "20",
            placeholder: "ラベルを入力してください",
            "data-vv-as": "ラベル",
            name: "action-default"
          },
          domProps: { value: _vm.value.label },
          on: {
            keyup: _vm.changeValue,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.value, "label", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: { message: _vm.errors.first("action-default") }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }