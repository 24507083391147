var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("代替テキスト"), _c("required-mark")], 1),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.altText,
              expression: "altText"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:400",
              expression: "'required|max:400'"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "altText" + _vm.index,
            placeholder: "代替テキストを入力してください",
            "data-vv-as": "代替テキスト"
          },
          domProps: { value: _vm.altText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.altText = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: { message: _vm.errors.first("altText" + _vm.index) }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.htmlPreview !== null,
              expression: "htmlPreview !== null"
            }
          ],
          staticClass: "form-group"
        },
        [
          _c("label", [_vm._v("メッセージ通知文")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: _vm.isValidate },
                expression: "{ required: isValidate }"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.altText,
                expression: "altText"
              }
            ],
            staticClass: "form-control alt-text-input",
            attrs: {
              type: "text",
              name: "altText",
              placeholder: "メッセージ通知文を入力してください"
            },
            domProps: { value: _vm.altText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.altText = $event.target.value
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.htmlPreview !== null,
                expression: "htmlPreview !== null"
              }
            ],
            staticClass: "form-group flex-message preview"
          },
          [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.data.html_template) }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 form-group controls" },
          _vm._l(_vm.mapObject, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "group-action-flex-message",
                class: item.id,
                on: {
                  click: function($event) {
                    return _vm.changeActive(item.id)
                  }
                }
              },
              [
                item.type === "text"
                  ? _c("flexmessage-text-action", {
                      attrs: { data: item, name: item.id },
                      on: { input: _vm.editObject }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type === "button"
                  ? _c("flexmessage-button-action", {
                      attrs: { data: item, name: item.id },
                      on: { input: _vm.editObject }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type === "image"
                  ? _c("flexmessage-image-action", {
                      attrs: {
                        data: item,
                        name: item.id,
                        aspectMode: _vm.aspectMode
                      },
                      on: { input: _vm.editObject }
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.type === "box"
                  ? _c("flexmessage-box-action", {
                      attrs: { data: item, name: item.id },
                      on: { input: _vm.editObject }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }