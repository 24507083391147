var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-item" }, [
    _vm.baseUrl
      ? _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.baseUrl,
              expression: "baseUrl",
              arg: "background-image"
            }
          ],
          staticClass: "imagemap"
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }