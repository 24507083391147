var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-panel d-flex flex-column py-2 px-3 bg-white" },
    [
      _c("div", { staticClass: "app-search sticky-top fh-55 w-100" }, [
        _c("div", { staticClass: "form-group position-relative" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "LINE登録名、表示名から検索、タグ名",
              maxlength: "64"
            },
            domProps: { value: _vm.keyword },
            on: {
              keyup: _vm.debouncedSearch,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.keyword = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "mdi mdi-magnify search-icon" }),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            staticClass: "mdi mdi-close clear-icon",
            attrs: { role: "button" },
            on: {
              click: function($event) {
                return _vm.resetSearch()
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow-1 overflow-auto w-100" },
        [
          _vm._l(_vm.channels, function(channel, index) {
            return _c("channel-list-item", {
              key: index,
              attrs: {
                channel: channel,
                active: _vm.activeChannel && channel.id === _vm.activeChannel.id
              },
              nativeOn: {
                click: function($event) {
                  return _vm.switchChannel(channel, index)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm.shouldShowSpinner
            ? _c("infinity-scroll", { on: { intersect: _vm.loadMore } })
            : _vm._e(),
          _vm._v(" "),
          _vm.channels.length === 0 && !_vm.shouldShowSpinner
            ? _c("div", { staticClass: "w-100 mt-4 text-center text-sm" }, [
                _vm._v("\n      チャンネルはありません。\n    ")
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }