var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tag-list" },
      [
        _c("div", {}, [
          _c("div", { staticClass: "col-md-4 tag-content-left p-0" }, [
            _c("div", { staticClass: "tag-content" }, [
              _c("div", { staticClass: "folder-list-title" }, [
                _c("table", { staticClass: "table table-tags-header" }, [
                  _c("thead", { staticClass: "thead-light" }, [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "header-title",
                          attrs: { scope: "col" }
                        },
                        [_vm._v("フォルダー")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { scope: "col" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "add_group btn btn-sm btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.addMoreFolder()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "glyphicon glyphicon-plus"
                              }),
                              _vm._v(
                                "\n                      新しいフォルダー\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tag-scroll folder-list" },
                [
                  _vm.isAddMoreFolder
                    ? _c("div", { staticClass: "folder-item new-folder" }, [
                        _c(
                          "div",
                          { staticClass: "input-group newgroup-inputs" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.folderForm.name,
                                  expression: "folderForm.name",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "フォルダー名"
                              },
                              domProps: { value: _vm.folderForm.name },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.enterCreateFolder.apply(
                                    null,
                                    arguments
                                  )
                                },
                                compositionend: function($event) {
                                  return _vm.compositionend($event)
                                },
                                compositionstart: function($event) {
                                  return _vm.compositionstart($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.folderForm,
                                    "name",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "input-group-btn" }, [
                              _c(
                                "button",
                                {
                                  ref: "buttonChange",
                                  staticClass: "btn btn-default",
                                  attrs: { type: "button" },
                                  on: { click: _vm.createFolder }
                                },
                                [_vm._v("決定")]
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading.folderLoading
                    ? _c("div", [_vm._v("Loading...")])
                    : _vm._l(_vm.folderLists, function(item, index) {
                        return _c("flexmesasge-folder-item", {
                          key: index,
                          attrs: {
                            data: item,
                            active: _vm.folderId == item.id,
                            index: index
                          },
                          on: {
                            changeSelected: function($event) {
                              _vm.folderId = $event.folderId
                            },
                            editFolder: function($event) {
                              return _vm.editFolder(item, $event)
                            },
                            deleteFolder: function($event) {
                              return _vm.deleteFolder(item)
                            }
                          }
                        })
                      })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-8 tag-content-right",
              class: _vm.currentFolder !== null ? "show" : ""
            },
            [
              _c(
                "div",
                {
                  staticClass: "tag-content",
                  staticStyle: { background: "rgb(249, 249, 249)" }
                },
                [
                  _vm.currentFolder !== null
                    ? _c("table", { staticClass: "table table-tags-header" }, [
                        _c("thead", { staticClass: "thead-light" }, [
                          _c("tr", [
                            _c("th", {}, [
                              _c("i", {
                                staticClass: "mdi mdi-arrow-left hidden-pc",
                                on: { click: _vm.backToFolder }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              { staticClass: "header-title folder-title" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.currentFolder.name || "") +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: {
                                      href:
                                        _vm.MIX_ROOT_PATH +
                                        "/template/flex-messages/folders/" +
                                        _vm.currentFolder.id +
                                        "/flex/create"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "glyphicon glyphicon-plus"
                                    }),
                                    _vm._v(
                                      " 新しいFlexメッセージ\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "tag-scroll tag-list" }, [
                    _vm.loading.flexMessageLoading
                      ? _c("div", [_vm._v("Loading...")])
                      : _c("table", { staticClass: "table" }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.flexMessageList, function(item, index) {
                              return _c("tr", { key: index }, [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "max-width": "500px",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis",
                                      "line-height": "2em"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.name) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-flex" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn-more btn-more-linebot btn-block mr-2",
                                            attrs: {
                                              "data-toggle": "modal",
                                              "data-target":
                                                "#flexMessagePreview"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.currentFlexMessage = item
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "プレビュー\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-dropdown",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "button-content",
                                                      fn: function() {
                                                        return [
                                                          _vm._v(
                                                            "\n                            操作"
                                                          ),
                                                          _c("span", {
                                                            staticClass: "caret"
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.copyFlexMessage(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("複製")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    attrs: {
                                                      href:
                                                        _vm.MIX_ROOT_PATH +
                                                        "/template/flex-messages/folders/" +
                                                        item.folder_id +
                                                        "/flex/" +
                                                        item.id +
                                                        "/edit"
                                                    }
                                                  },
                                                  [_vm._v("編集")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteFlexMessage(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("削除")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ])
                  ])
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.currentFlexMessage != null
          ? _c("flexmessage-modal-preview", {
              attrs: { id: "flexMessagePreview", model: _vm.currentFlexMessage }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("modal-confirm", {
          attrs: {
            title: "以下のメッセージを削除してもよろしいですか？",
            id: "modal-confirm-delete-flexmessage",
            type: "delete"
          },
          on: { input: _vm.submitDeleteFlexMessage }
        }),
        _vm._v(" "),
        _c("modal-confirm", {
          attrs: {
            title: "こちらのフォルダーを削除してもよろしいですか？",
            id: "modalDeleteFolder",
            type: "delete"
          },
          on: { input: _vm.submitDeleteFolder }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row-ttl01 flex ai_center mb40 flex-wrap justify-content-between"
      },
      [_c("h3", { staticClass: "hdg3" }, [_vm._v("Flexメッセージ新規")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }