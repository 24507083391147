var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _vm._l(_vm.options, function(option, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "custom-control custom-checkbox custom-control-inline d-flex flex-start"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedOptions,
                      expression: "selectedOptions"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "checkbox",
                    id: _vm.prefix + "Option" + index
                  },
                  domProps: {
                    value: option.value,
                    checked: Array.isArray(_vm.selectedOptions)
                      ? _vm._i(_vm.selectedOptions, option.value) > -1
                      : _vm.selectedOptions
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.selectedOptions,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectedOptions = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectedOptions = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectedOptions = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: _vm.prefix + "Option" + index }
                  },
                  [_vm._v(_vm._s(option.value))]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("ValidationProvider", {
            attrs: { name: "答え", rules: { required: _vm.isRequired } },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.answer,
                          expression: "answer"
                        }
                      ],
                      attrs: {
                        type: "hidden",
                        name: "answers[" + _vm.qnum + "][answer]"
                      },
                      domProps: { value: _vm.answer },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.answer = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", { attrs: { message: errors[0] } })
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }