var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.contentKey, staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("episode-time-editor", {
              attrs: {
                is_initial: _vm.episodeData.is_initial,
                date: _vm.episodeData.date,
                time: _vm.episodeData.time
              },
              on: {
                "update:is_initial": function($event) {
                  return _vm.$set(_vm.episodeData, "is_initial", $event)
                },
                "update:date": function($event) {
                  return _vm.$set(_vm.episodeData, "date", $event)
                },
                "update:time": function($event) {
                  return _vm.$set(_vm.episodeData, "time", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v("登録したメッセージがありません。")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._l(_vm.episodeData.messages, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("message-editor", {
                      attrs: {
                        allowCreateFromTemplate: true,
                        data: item,
                        index: index,
                        messagesCount: _vm.episodeData.messages.length
                      },
                      on: {
                        input: _vm.onMessageDataChanged,
                        remove: _vm.removeMessage,
                        moveUp: _vm.moveMessageUp,
                        moveDown: _vm.moveMessageDown
                      }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.episodeData.messages.length < 5
                    ? _c(
                        "div",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.addMessage }
                        },
                        [
                          _c("i", { staticClass: "uil-plus" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("メッセージ追加")])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: {
                        "data-toggle": "modal",
                        "data-target": "#modalSelectTemplate"
                      }
                    },
                    [_vm._v("\n            テンプレートから作成\n          ")]
                  ),
                  _vm._v(" "),
                  _c("modal-select-template", {
                    attrs: { id: "modalSelectTemplate" },
                    on: { selectTemplate: _vm.onSelectTemplate }
                  })
                ],
                1
              )
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("action-editor-custom", {
              attrs: {
                name: "actions",
                value: _vm.episodeData.actions,
                requiredLabel: false,
                showTitle: false,
                limit: 10,
                showLaunchMessage: false
              },
              on: { input: _vm.updateAction }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "row-form-btn d-flex" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success fw-120 mr-1",
            attrs: { disabled: _vm.invalid },
            on: {
              click: function($event) {
                return _vm.submit()
              }
            }
          },
          [_vm._v("配信登録")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("配信日時")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("メッセージ設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("アクション設定")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }