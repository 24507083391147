var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("folder-left", {
              attrs: {
                type: "survey",
                data: _vm.folders,
                selectedFolder: _vm.selectedFolderIndex
              },
              on: {
                changeSelectedFolder: _vm.changeSelectedFolder,
                submitUpdateFolder: _vm.submitUpdateFolder,
                submitCreateFolder: _vm.submitCreateFolder
              }
            }),
            _vm._v(" "),
            _c("div", { key: _vm.contentKey, staticClass: "flex-grow-1" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    href:
                      _vm.rootPath +
                      "/user/surveys/new?folder_id=" +
                      (_vm.curFolder ? _vm.curFolder.id : null)
                  }
                },
                [
                  _c("i", { staticClass: "uil-plus" }),
                  _vm._v(" 新規作成\n        ")
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c("table", { staticClass: "table table-centered mb-0" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm.curFolder
                    ? _c(
                        "tbody",
                        _vm._l(_vm.curFolder.surveys, function(survey, index) {
                          return _c("tr", { key: index }, [
                            _c(
                              "td",
                              { staticClass: "mw-120" },
                              [
                                _c("survey-status", {
                                  attrs: { status: survey.status }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "mxvw-15 max-2-lines" },
                                [_vm._v(_vm._s(survey.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "mw-200" },
                              [
                                survey.responses_count === 0
                                  ? [_vm._v(" 未回答 ")]
                                  : [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(survey.responses_count) +
                                          "回答／"
                                      ),
                                      _c("span", { staticClass: "font-12" }, [
                                        _vm._v(
                                          _vm._s(survey.users_count) + "人"
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-light ml-2",
                                          attrs: {
                                            href:
                                              _vm.rootPath +
                                              "/user/surveys/" +
                                              survey.id
                                          }
                                        },
                                        [_vm._v("表示")]
                                      )
                                    ],
                                _vm._v(" "),
                                survey.ggsheet_url
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "fw-100",
                                        attrs: {
                                          href: survey.ggsheet_url,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/ggsheet.png",
                                            width: "35"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "mw-200" }, [
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-menu" }, [
                                  survey.status !== "published" &&
                                  survey.editable
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            role: "button",
                                            href:
                                              _vm.rootPath +
                                              "/user/surveys/" +
                                              survey.id +
                                              "/edit"
                                          }
                                        },
                                        [_vm._v("回答フォームを編集")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        href:
                                          _vm.rootPath +
                                          "/user/surveys/" +
                                          survey.id +
                                          "/export"
                                      }
                                    },
                                    [_vm._v("回答一覧DL")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        role: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#modalCopySurvey"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.curSurveyIndex = index
                                        }
                                      }
                                    },
                                    [_vm._v("回答フォームをコピー")]
                                  ),
                                  _vm._v(" "),
                                  survey.status !== "draft"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            role: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#modalToggleSurvey"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.curSurveyIndex = index
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              survey.status === "published"
                                                ? "非公開"
                                                : "公開"
                                            ) + "にする"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  survey.destroyable
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            role: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#modalDeleteSurvey"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.curSurveyIndex = index
                                            }
                                          }
                                        },
                                        [_vm._v("回答フォームを削除")]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-sm btn-light my-1",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target": "#modalSurveyPreview"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.curSurveyIndex = index
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    プレビュー\n                  "
                                  )
                                ]
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm.curFolder || _vm.curFolder.surveys.length === 0
                  ? _c("div", { staticClass: "text-center mt-5" }, [
                      _c("b", [_vm._v("回答フォームはありません。")])
                    ])
                  : _vm._e()
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalDeleteFolder",
          title: "このフォルダーを削除してもよろしいですか？",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteFolder },
        scopedSlots: _vm._u(
          [
            _vm.curFolder
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("フォルダ名：" + _vm._s(_vm.curFolder.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "この回答フォームをコピーしてもよろしいですか？",
          id: "modalCopySurvey",
          type: "confirm"
        },
        on: { confirm: _vm.submitCopySurvey },
        scopedSlots: _vm._u(
          [
            _vm.curSurvey
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("回答フォーム名：" + _vm._s(_vm.curSurvey.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "この回答フォームを削除してもよろしいですか？",
          id: "modalDeleteSurvey",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteSurvey },
        scopedSlots: _vm._u(
          [
            _vm.curSurvey
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("回答フォーム名：" + _vm._s(_vm.curSurvey.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "この回答フォームの状況を変更してもよろしいですか？",
          id: "modalToggleSurvey",
          type: "confirm"
        },
        on: { confirm: _vm.submitToggleSurvey },
        scopedSlots: _vm._u(
          [
            _vm.curSurvey
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _vm._v("\n      状況変更："),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curSurvey.status === "published"
                              ? "公開"
                              : "非公開"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curSurvey.status === "published"
                              ? "非公開"
                              : "公開"
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _vm.curSurvey
        ? _c("modal-survey-preview", { attrs: { survey_id: _vm.curSurvey.id } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", [_vm._v("フォーム名")]),
        _vm._v(" "),
        _c("th", [_vm._v("回答状態")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                      操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }