var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-template modal-common01",
      attrs: {
        id: _vm.name,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c(
          "div",
          {
            staticClass: "modal-content",
            staticStyle: { padding: "0 15px 15px 15px" }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body mh-100 overflow-hidden" }, [
              _vm.flexMessageTemplates != null
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        background: "#ededed",
                        "text-align": "center"
                      }
                    },
                    _vm._l(_vm.flexMessageTemplates, function(template, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "sample-item",
                          attrs: {
                            "data-sample-id": "restaurant",
                            "data-dismiss": "modal"
                          },
                          on: {
                            click: function($event) {
                              return _vm.pickTemplateShowCase(template)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "thumb" }, [
                            _c("img", {
                              attrs: { src: template.image_template }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "label",
                                staticStyle: {
                                  display: "block",
                                  "text-align": "center",
                                  "font-size": "16px",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(template.title) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Flexメッセージのテンプレートを選ぶ")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }