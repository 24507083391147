var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-tag modal-common01",
      staticStyle: { display: "none" },
      attrs: {
        id: "modal-tag",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("label", { staticClass: "mb5" }, [_vm._v("設定タグ一覧")]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "list-tag list-unstyled no-mgn" },
              _vm._l(_vm.tags, function(item, index) {
                return _c("li", { key: index, staticClass: "tag" }, [
                  _vm._v(_vm._s(item.name))
                ])
              }),
              0
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }