var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClassName(), on: { click: _vm.changeSelected } },
    [
      !_vm.isEdit || !_vm.active
        ? _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("i", {
              class: _vm.active ? "fas fa-folder-open" : "fas fa-folder"
            }),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [
              _vm._v("\n      " + _vm._s(_vm.data.name) + "\n    ")
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(" (" + _vm._s(_vm.data.flex_messages_count || 0) + ") ")
            ]),
            _vm._v(" "),
            _vm.active && !_vm.disableEditor && _vm.index > 0
              ? _c("div", { staticClass: "dropdown" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                    [
                      _c(
                        "li",
                        {
                          attrs: { role: "presentation" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.changeName.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { role: "menuitem", tabindex: "-1" } },
                            [_vm._v("名前を変える")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          attrs: { role: "presentation" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deleteFolder.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { role: "menuitem", tabindex: "-1" } },
                            [_vm._v("フォルダーを削除")]
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isEdit && _vm.active
        ? _c("div", [
            _c("div", { staticClass: "input-group newgroup-inputs" }, [
              _c("input", {
                ref: "folderName",
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "フォルダー名" },
                domProps: { value: _vm.data.name },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.enterSubmitChangeName.apply(null, arguments)
                  },
                  compositionend: function($event) {
                    return _vm.compositionend($event)
                  },
                  compositionstart: function($event) {
                    return _vm.compositionstart($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    ref: "buttonChange",
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.submitChangeName }
                  },
                  [_vm._v("決定")]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle btn-default action-tags",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n        編集"), _c("span", { staticClass: "caret" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }