var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "myComponent", staticClass: "mxw-1200" },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-border" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "fw-300" }, [_vm._v("フォルダー")]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.templateData.folder_id,
                          expression: "templateData.folder_id"
                        }
                      ],
                      staticClass: "form-control fw-300",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.templateData,
                            "folder_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.folders, function(folder, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: folder.id } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(folder.name) +
                              "\n              "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-border" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    [_vm._v("テンプレート名"), _c("required-mark")],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.templateData.name,
                        expression: "templateData.name",
                        modifiers: { trim: true }
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:64",
                        expression: "'required|max:64'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "template_name",
                      placeholder: "テンプレート名を入力してください",
                      maxlength: "65",
                      "data-vv-as": "テンプレート名"
                    },
                    domProps: { value: _vm.templateData.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.templateData,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-message", {
                    attrs: { message: _vm.errors.first("template_name") }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-border" }, [
              _c(
                "div",
                { key: _vm.componentKey },
                _vm._l(_vm.templateData.messages, function(item, index) {
                  return _c("message-editor", {
                    key: item.id,
                    attrs: {
                      allowCreateFromTemplate: false,
                      data: item,
                      index: index,
                      messagesCount: _vm.templateData.messages.length
                    },
                    on: {
                      input: _vm.changeContent,
                      remove: _vm.removeMessage,
                      moveUp: _vm.moveUp,
                      moveDown: _vm.moveDown
                    }
                  })
                }),
                1
              )
            ]),
            _vm._v(" "),
            _vm.templateData.messages.length < 3
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.addMessageBlock()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "uil-plus" }),
                    _c("span", [_vm._v(" メッセージ追加")])
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer d-flex" }, [
            _c(
              "div",
              {
                staticClass: "btn btn-success mw-120",
                attrs: { autofocus: "false" },
                on: { click: _vm.submitSaveTemplate }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.template_id ? "保存" : "テンプレート登録") +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("loading-indicator", { attrs: { loading: _vm.loading } })
        ],
        1
      ),
      _vm._v(" "),
      _c("message-preview"),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このメッセージを削除してもよろしいですか？",
          type: "delete"
        },
        on: { input: _vm.deleteTemplateTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }