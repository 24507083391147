var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light d-flex overflow-x-auto" },
    _vm._l(_vm.options, function(option, index) {
      return _c("sticker-package", {
        key: index,
        staticClass: "mr-1",
        attrs: { data: option },
        on: { input: _vm.changePackageId }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }