var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card border-primary border mt-4" }, [
      _c("div", { staticClass: "card-header d-flex align-items-center" }, [
        _c("span", [
          _vm._v("メッセージ" + _vm._s(_vm.index ? _vm.index + 1 : "") + "設定")
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("message-type-selection", {
                on: { input: _vm.changeSelectedMessage },
                model: {
                  value: _vm.messageData.message_type_id,
                  callback: function($$v) {
                    _vm.$set(_vm.messageData, "message_type_id", $$v)
                  },
                  expression: "messageData.message_type_id"
                }
              }),
              _vm._v(" "),
              _vm.messagesCount && _vm.messagesCount > 0
                ? _c("div", { staticClass: "group-action d-flex" }, [
                    _vm.index >= 1
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex btn btn-light btn-sm mr-1",
                            attrs: { role: "button" },
                            on: { click: _vm.moveUp }
                          },
                          [_c("i", { staticClass: "dripicons-chevron-up" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.index < _vm.messagesCount - 1
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex btn btn-light btn-sm mr-1",
                            attrs: { role: "button" },
                            on: { click: _vm.moveDown }
                          },
                          [_c("i", { staticClass: "dripicons-chevron-down" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.messagesCount != 1
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex btn btn-light btn-sm",
                            attrs: { role: "button" },
                            on: { click: _vm.removeMessage }
                          },
                          [_c("i", { staticClass: "dripicons-minus" })]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.rerender
            ? _c("message-content-editor", {
                staticClass: "mt-2",
                attrs: { index: _vm.index, data: _vm.messageData.content },
                on: { changeContent: _vm.changeContentMessage }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showUrlClickConfig &&
          _vm.messageData.message_type_id === _vm.MessageTypeIds.Text
            ? _c("url-config", {
                attrs: {
                  index: _vm.index,
                  messageContent: _vm.messageData.content,
                  siteMeasurements: _vm.siteMeasurements
                },
                on: {
                  configured: _vm.configUrl,
                  changeShortenUrlUsage: _vm.changeShortenUrlUsage
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "ml-auto btn btn-tool",
        attrs: { "data-card-widget": "collapse" }
      },
      [_c("i", { staticClass: "fas fa-minus" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }