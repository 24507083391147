var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c("ValidationProvider", {
        attrs: { name: "答え", rules: { required: _vm.isRequired } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  _vm._l(_vm.options, function(option, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "custom-control custom-radio custom-control-inline d-flex flex-start"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.answer,
                              expression: "answer"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: _vm.prefix + "Option" + index,
                            name: "answers[" + _vm.qnum + "][answer]"
                          },
                          domProps: {
                            value: option.value,
                            checked: _vm._q(_vm.answer, option.value)
                          },
                          on: {
                            change: function($event) {
                              _vm.answer = option.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: _vm.prefix + "Option" + index }
                          },
                          [_vm._v(_vm._s(option.value))]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("error-message", { attrs: { message: errors[0] } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }