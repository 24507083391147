var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("div", [_vm._v("\n  Loading...\n")])
    : _vm.isError
    ? _c("div", [
        _vm._v("\n  ERR:ERR\n  "),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.fetchData()
              }
            }
          },
          [_vm._v(" RETRY")]
        )
      ])
    : _vm.responseLists.length === 0
    ? _c("div", { staticStyle: { padding: "10px", "text-align": "center" } }, [
        _vm._v("\n    データなし\n")
      ])
    : _c("table", { staticClass: "table table-hover table-scenario" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.responseLists, function(object, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(1 + Number(index)))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(object.survey_name))]),
              _vm._v(" "),
              _c("td", [
                object.first_answer.content
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "vertical-align": "top",
                          padding: "10px",
                          position: "relative"
                        }
                      },
                      [
                        object.first_answer.content.type === "file"
                          ? _c("div", [
                              object.first_answer.content.content.mine_type.includes(
                                "image/"
                              )
                                ? _c("img", {
                                    staticStyle: {
                                      width: "150px",
                                      "margin-bottom": "10px"
                                    },
                                    attrs: {
                                      src:
                                        _vm.MIX_SERVEY_MEDIA_FLEXA_URL +
                                        "/" +
                                        object.first_answer.content.content
                                          .alias
                                    }
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "150px",
                                        "font-size": "60px"
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-file" })]
                                  ),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#ea5516" },
                                    attrs: {
                                      href:
                                        _vm.MIX_SERVEY_MEDIA_FLEXA_URL +
                                        "/" +
                                        object.first_answer.content.content
                                          .alias,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-download" }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trimMidString(
                                            object.first_answer.content.content
                                              .name,
                                            25,
                                            10
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ])
                          : _c(
                              "div",
                              { staticStyle: { "word-break": "break-word" } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.autoText(
                                        object.first_answer.content.content
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "btn-edit01" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-more btn-more-linebot btn-block",
                      attrs: {
                        href:
                          _vm.MIX_ROOT_PATH +
                          "/surveys/" +
                          object.survey_id +
                          "/answer/" +
                          object.survey_customer_id
                      }
                    },
                    [_vm._v(" 回答表示 ")]
                  )
                ])
              ])
            ])
          }),
          0
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("No.")]),
        _vm._v(" "),
        _c("th", [_vm._v("アンケート名")]),
        _vm._v(" "),
        _c("th", [_vm._v("質問の1問目の回答")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }