var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { attrs: { id: "chatItem" + _vm.message.id } },
    [
      _vm.showUnreadMarkDiv
        ? _c("chat-item-unread-mark", { key: _vm.componentKey })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowDate
        ? _c("div", { staticClass: "d-flex align-items-center mb-2" }, [
            _c("div", { staticClass: "fh-2 bg-light flex-grow-1" }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "font-12 font-weight-bold border border-light py-1 px-2 date-title"
              },
              [_vm._v(_vm._s(_vm.readableDate))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "fh-2 bg-light flex-grow-1" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isSystemMessage
        ? [_c("system-message", { attrs: { message: _vm.message } })]
        : [
            _c("div", { class: _vm.alignBubble }, [
              _c("div", { staticClass: "chat-avatar" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.avatarImgObj,
                      expression: "avatarImgObj"
                    }
                  ],
                  staticClass: "rounded",
                  attrs: { alt: "友達" }
                }),
                _vm._v(" "),
                _c("i", [_vm._v(_vm._s(_vm.readableTime))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "conversation-text d-flex flex-end" }, [
                !_vm.isFriendMessage
                  ? _c("div", { staticClass: "d-flex ml-auto" }, [
                      _c(
                        "span",
                        { staticClass: "mt-auto mr-1" },
                        [
                          _c("chat-item-send-status", {
                            attrs: { status: _vm.message.status }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "ctext-wrap" }, [
                  _c("i", [_vm._v(_vm._s(_vm.sender.name || "システム"))]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _c("message-content", {
                        attrs: { data: _vm.message, time: _vm.readableTime }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }