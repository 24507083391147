var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.added_friend_before == "true",
            expression: "added_friend_before == 'true'"
          }
        ]
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.notAddFriend,
            expression: "notAddFriend"
          }
        ]
      },
      [_vm._m(1)]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert alert-success", attrs: { role: "alert" } },
      [
        _c("h4", { staticClass: "alert-heading" }, [
          _vm._v("リンクが完了しました")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "py-2" }, [
          _vm._v("\n        ありがとうございました！\n      ")
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0" }, [
          _vm._v("上部✖︎ボタンを押して終了してください")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "alert alert-warning alert-dismissible fade show",
        attrs: { role: "alert" }
      },
      [
        _vm._v("\n      リンクに失敗しました\n      "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "alert",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }