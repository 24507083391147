var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", [
        _c("label", { staticClass: "w-100 mt-2" }, [_vm._v(" リマインダ設定")]),
        _vm._v(" "),
        _c("div", { staticClass: "m-0" }, [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "custom-control custom-radio custom-control-inline"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.actionData.type,
                      expression: "actionData.type"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: _vm.name + "typeSet",
                    name: _vm.name + "_type",
                    value: "set"
                  },
                  domProps: { checked: _vm._q(_vm.actionData.type, "set") },
                  on: {
                    change: [
                      function($event) {
                        return _vm.$set(_vm.actionData, "type", "set")
                      },
                      function($event) {
                        return _vm.onDataChanged()
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: _vm.name + "typeSet" }
                  },
                  [_vm._v("リマインダを開始")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "custom-control custom-radio custom-control-inline"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.actionData.type,
                      expression: "actionData.type"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: _vm.name + "typeUnset",
                    name: _vm.name + "_type",
                    value: "unset"
                  },
                  domProps: { checked: _vm._q(_vm.actionData.type, "unset") },
                  on: {
                    change: [
                      function($event) {
                        return _vm.$set(_vm.actionData, "type", "unset")
                      },
                      function($event) {
                        return _vm.onDataChanged()
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: _vm.name + "typeUnset" }
                  },
                  [_vm._v("リマインダをキャンセル")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2 d-flex" }, [
            _c("label", { staticClass: "fw-150" }, [_vm._v("リマインダ選択")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mw-200 mxw-400" },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn btn-secondary w-100",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#" + _vm.name + "_selectReminderModal"
                    }
                  },
                  [
                    _c("span", { staticClass: "max-1-lines" }, [
                      _vm._v(
                        _vm._s(
                          _vm.actionData.reminder.id
                            ? _vm.actionData.reminder.name
                            : "リマインダを選択する"
                        )
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.actionData.reminder.id,
                      expression: "actionData.reminder.id"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    type: "hidden",
                    name: _vm.name + "_reminder_id",
                    "data-vv-as": "リマインダ"
                  },
                  domProps: { value: _vm.actionData.reminder.id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.actionData.reminder,
                        "id",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  staticClass: "w-100",
                  attrs: {
                    message: _vm.errors.first(_vm.name + "_reminder_id")
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.actionData.type === "set"
            ? _c("div", { staticClass: "mt-2 d-flex" }, [
                _c("label", { staticClass: "fw-150" }, [_vm._v("ゴール日")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("datetime", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        name: _vm.name + "_reminder_goal",
                        "input-class": "form-control",
                        type: "date",
                        phrases: { ok: "確定", cancel: "閉じる" },
                        placeholder: "日付を選択してください",
                        "min-datetime": _vm.currentDate,
                        "value-zone": "Asia/Tokyo",
                        "data-vv-as": "ゴール日",
                        zone: "Asia/Tokyo"
                      },
                      model: {
                        value: _vm.actionData.goal,
                        callback: function($$v) {
                          _vm.$set(_vm.actionData, "goal", $$v)
                        },
                        expression: "actionData.goal"
                      }
                    }),
                    _vm._v(" "),
                    _c("error-message", {
                      attrs: {
                        message: _vm.errors.first(_vm.name + "_reminder_goal")
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("modal-select-reminder", {
        attrs: { id: _vm.name + "_selectReminderModal" },
        on: {
          selectReminder: function($event) {
            return _vm.onSelectReminder($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }