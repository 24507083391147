var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-header d-flex align-items-center" },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-info fw-120 mr-2",
                    attrs: { href: _vm.rootUrl + "/admin/announcements/new" }
                  },
                  [
                    _c("i", { staticClass: "uil-plus" }),
                    _vm._v(" 新規登録\n          ")
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table table-centered table-bordered mb-0" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.announcements, function(announcement, index) {
                      return _c("tbody", { key: announcement.id }, [
                        _c("tr", [
                          _c("td", { staticClass: "mw-200" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formattedDatetime(announcement.announced_at)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(announcement.title))]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formattedDatetime(announcement.updated_at)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "mw-150" },
                            [
                              _c("announcement-status", {
                                attrs: { announcement: announcement }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "btn-group" }, [
                              _vm._m(1, true),
                              _vm._v(" "),
                              _c("div", { staticClass: "dropdown-menu" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      href:
                                        _vm.rootUrl +
                                        "/admin/announcements/" +
                                        announcement.id +
                                        "/edit",
                                      role: "button"
                                    }
                                  },
                                  [_vm._v("お知らせを編集")]
                                ),
                                _vm._v(" "),
                                announcement.status &&
                                announcement.status !== "draft"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          role: "button",
                                          "data-toggle": "modal",
                                          "data-target":
                                            "#modalToggleStatusAnnouncement"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.curAnnouncementIndex = index
                                          }
                                        }
                                      },
                                      [
                                        announcement.status === "unpublished"
                                          ? _c("span", [_vm._v("公開にする")])
                                          : _c("span", [_vm._v("非公開にする")])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      role: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#modalDeleteAnnouncement"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.curAnnouncementIndex = index
                                      }
                                    }
                                  },
                                  [_vm._v("お知らせを削除")]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-light btn-sm",
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target": "#modalAnnouncementDetail"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.curAnnouncementIndex = index
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                      プレビュー\n                    "
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.totalRows && _vm.totalRows / 10 > 1
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": 10,
                          "aria-controls": "my-table",
                          "first-number": "",
                          "last-number": ""
                        },
                        on: { change: _vm.loadPage },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.announcements.length == 0
                ? _c("div", { staticClass: "text-center mt-4" }, [
                    _c("b", [_vm._v("データはありません。")])
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このお知らせを削除もよろしいですか？",
          id: "modalDeleteAnnouncement",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteAnnouncement },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curAnnouncement
                  ? _c("div", [
                      _vm._v("\n        お知らせタイトル："),
                      _c("b", [_vm._v(_vm._s(_vm.curAnnouncement.title))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このお知らせの状況を変更してもよろしいですか？",
          id: "modalToggleStatusAnnouncement",
          type: "confirm"
        },
        on: { confirm: _vm.submitToggleStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curAnnouncement
                  ? _c("div", [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curAnnouncement.status === "published"
                              ? "公開"
                              : "非公開"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curAnnouncement.status === "published"
                              ? "非公開"
                              : "公開"
                          )
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-announcement-detail", {
        attrs: { announcement: _vm.curAnnouncement }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("タイトル")]),
        _vm._v(" "),
        _c("th", [_vm._v("変更日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-300" }, [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }