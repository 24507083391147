var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.announcement.status == "draft"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-secondary" }),
        _vm._v(" 下書き ")
      ])
    : _vm.announcement.status == "published"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-success" }),
        _vm._v(" 公開 ")
      ])
    : _vm.announcement.status == "unpublished"
    ? _c("span", [
        _c("i", { staticClass: "mdi mdi-circle text-primary" }),
        _vm._v(" 非公開 ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }