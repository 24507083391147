var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isEdit
      ? _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("span", { staticClass: "flex-grow-1" }, [
            _vm._v("\n      " + _vm._s(_vm.data.name) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-group flex-1" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-menu", attrs: { role: "menu" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    on: { click: _vm.changeName }
                  },
                  [_vm._v("タグ名を変える")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#modalConfirmDelTag"
                    },
                    on: { click: _vm.deleteFolder }
                  },
                  [_vm._v("タグを削除")]
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isEdit
      ? _c(
          "div",
          [
            _c("div", { staticClass: "input-group newgroup-inputs" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.tagName,
                    expression: "tagName",
                    modifiers: { trim: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:32",
                    expression: "'required|max:32'"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: "タグ名",
                  name: "tag_name",
                  "data-vv-as": "タグ名",
                  maxlength: "33"
                },
                domProps: { value: _vm.tagName },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.enterSubmitChangeName.apply(null, arguments)
                  },
                  compositionend: function($event) {
                    return _vm.compositionend($event)
                  },
                  compositionstart: function($event) {
                    return _vm.compositionstart($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.tagName = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    ref: "buttonChange",
                    staticClass: "btn btn-light",
                    attrs: { type: "button" },
                    on: { click: _vm.submitChangeName }
                  },
                  [_vm._v("決定")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("error-message", {
              attrs: { message: _vm.errors.first("tag_name") }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n        操作 "), _c("span", { staticClass: "caret" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }