var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isEdit || !_vm.active
      ? _c(
          "div",
          {
            staticClass: "d-flex align-items-center w-100",
            class: _vm.getClassName,
            on: { click: _vm.changeSelected }
          },
          [
            _c("span", { staticClass: "d-flex w-100 align-items-center" }, [
              _c("i", {
                class: _vm.active ? "fas fa-folder-open" : "fas fa-folder"
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: {
                    "vertical-align": "middle",
                    "text-overflow": "ellipsis",
                    "white-space": "nowrap",
                    overflow: "hidden"
                  }
                },
                [_vm._v(_vm._s(_vm.data.name))]
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.childsCount) + ")")]),
              _vm._v(" "),
              _vm.active && _vm.data.name != "未分類" && !_vm.isPerview
                ? _c("div", { staticClass: "dropdown" }, [
                    _c("div", { staticClass: "btn-group" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-light btn-sm dropdown-toggle dropdown-icon",
                          attrs: {
                            "data-toggle": "dropdown",
                            "aria-expanded": "false"
                          }
                        },
                        [_vm._v("\n            編集\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu bg-white",
                          attrs: { role: "menu" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { role: "button" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.changeName.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("名前を変える")]
                          ),
                          _vm._v(" "),
                          _vm.canDelete(_vm.data)
                            ? _c("div", { staticClass: "dropdown-divider" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canDelete(_vm.data)
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    role: "button",
                                    "data-toggle": "modal",
                                    "data-target": "#modalDeleteFolder"
                                  },
                                  on: { click: _vm.deleteFolder }
                                },
                                [_vm._v("フォルダーを削除")]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isEdit && _vm.active
      ? _c(
          "div",
          { staticClass: "d-flex flex-column w-100", class: _vm.getClassName },
          [
            _c(
              "div",
              { staticClass: "d-flex align-items-center input-group" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.folderName,
                      expression: "folderName",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:32",
                      expression: "'required|max:32'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "フォルダー名",
                    maxlength: "33",
                    name: "folder_name",
                    "data-vv-as": "フォルダー名"
                  },
                  domProps: { value: _vm.folderName },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.enterSubmitChangeName.apply(null, arguments)
                    },
                    compositionend: function($event) {
                      return _vm.compositionend($event)
                    },
                    compositionstart: function($event) {
                      return _vm.compositionstart($event)
                    },
                    click: function($event) {
                      $event.stopPropagation()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.folderName = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "buttonChange",
                    staticClass: "btn btn-light btn-sm ml-auto",
                    on: { click: _vm.submitChangeName }
                  },
                  [_vm._v("決定")]
                )
              ]
            ),
            _vm._v(" "),
            _vm.errors.first("folder_name")
              ? _c("error-message", {
                  attrs: { message: _vm.errors.first("folder_name") }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }