var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("folder-left", {
              attrs: {
                type: "reminder",
                data: _vm.folders,
                isPc: _vm.isPc,
                selectedFolder: _vm.selectedFolderIndex
              },
              on: {
                changeSelectedFolder: _vm.onSelectedFolderChanged,
                submitUpdateFolder: _vm.submitUpdateFolder,
                submitCreateFolder: _vm.submitCreateFolder
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { key: _vm.contentKey, staticClass: "flex-grow-1 folder-right" },
              [
                _vm.folders && _vm.folders.length && _vm.curFolder
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          href:
                            _vm.rootPath +
                            "/user/reminders/new?folder_id=" +
                            _vm.curFolder.id
                        }
                      },
                      [
                        _c("i", { staticClass: "uil-plus" }),
                        _vm._v(" 新規作成\n        ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.curFolder
                  ? _c("div", { staticClass: "table-responsive mt-2" }, [
                      _c(
                        "table",
                        { staticClass: "table table-centered mb-0" },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.curFolder.reminders, function(
                              reminder,
                              index
                            ) {
                              return _c("tr", { key: reminder.id }, [
                                _c("td", [
                                  _c("p", { staticClass: "vw-20 item-name" }, [
                                    _vm._v(_vm._s(reminder.name))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-light mr-1 text-nowrap",
                                        attrs: {
                                          role: "button",
                                          href:
                                            _vm.rootPath +
                                            "/user/reminders/" +
                                            reminder.id +
                                            "/episodes"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "配信タイミング一覧（" +
                                            _vm._s(
                                              reminder.episodes_count || 0
                                            ) +
                                            "）"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "btn-group" }, [
                                      _vm._m(1, true),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-menu" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                role: "button",
                                                href:
                                                  _vm.rootPath +
                                                  "/user/reminders/" +
                                                  reminder.id +
                                                  "/edit?folder_id=" +
                                                  _vm.curFolder.id
                                              }
                                            },
                                            [_vm._v("リマインダを編集する")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                role: "button",
                                                "data-toggle": "modal",
                                                "data-target":
                                                  "#modalCopyReminder"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.curReminderIndex = index
                                                }
                                              }
                                            },
                                            [_vm._v("リマインダをコピー")]
                                          ),
                                          _vm._v(" "),
                                          reminder.destroyable
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "dropdown-item",
                                                  attrs: {
                                                    role: "button",
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      "#modalDeleteTemplate"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.curReminderIndex = index
                                                    }
                                                  }
                                                },
                                                [_vm._v("リマインダを削除")]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.curFolder.name))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-sm" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formattedDate(reminder.created_at)
                                      )
                                    )
                                  ])
                                ])
                              ])
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.curFolder.reminders.length === 0
                        ? _c("div", { staticClass: "text-center mt-5" }, [
                            _c("b", [_vm._v("リマインダはありません。")])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このフォルダーを削除してもよろしいですか？",
          id: "modalDeleteFolder",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteFolder },
        scopedSlots: _vm._u(
          [
            _vm.curFolder
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v("フォルダー名：" + _vm._s(_vm.curFolder.name))
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このリマインダを削除してもよろしいですか？",
          id: "modalDeleteTemplate",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteReminder },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curReminder
                  ? _c("div", [
                      _vm._v("\n        リマインダ名："),
                      _c("b", [_vm._v(_vm._s(_vm.curReminder.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このリマインダをコピーしてもよろしいですか？",
          id: "modalCopyReminder",
          type: "confirm"
        },
        on: { confirm: _vm.submitCopyReminder },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curReminder
                  ? _c("div", [
                      _vm._v("\n        リマインダ名："),
                      _c("b", [_vm._v(_vm._s(_vm.curReminder.name))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "mw-120" }, [_vm._v("リマインダ名")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-260" }, [_vm._v("操作")]),
        _vm._v(" "),
        _c("th", { staticClass: "mw-150" }, [_vm._v("フォルダー")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }