var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rich_menu.target === "all"
    ? _c("div", [_vm._v("全て")])
    : _vm.rich_menu.target === "condition"
    ? _c("div", [
        _vm.tagCondition &&
        _vm.tagCondition.data.tags &&
        _vm.tagCondition.data.tags.length > 0
          ? _c(
              "div",
              { staticClass: "d-flex align-items-center flex-wrap" },
              [
                _c("span", { staticClass: "text-sm mr-2" }, [_vm._v("タグ")]),
                _vm._v(" "),
                _vm._l(_vm.tagCondition.data.tags, function(tag, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass:
                        "badge badge-warning badge-pill pt-1 mr-1 mb-1"
                    },
                    [_vm._v(_vm._s(tag.name))]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }