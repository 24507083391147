var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "tag-item" }, [
    _c(
      "td",
      { staticStyle: { "min-width": "200px", "vertical-align": "middle" } },
      [
        _c("tag-item-editor", {
          attrs: { data: _vm.tag },
          on: {
            deleteTag: _vm.deleteTag,
            editTag: _vm.editTag,
            createTag: _vm.createTag
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "fw-150" }, [
      _c("span", { staticClass: "mt-2" }, [
        _vm._v(_vm._s(_vm.tag.friends_count) + "人")
      ]),
      _vm._v(" "),
      _vm.tag.friends_count > 0
        ? _c(
            "div",
            {
              staticClass: "btn btn-light btn-sm d-inline-block",
              attrs: {
                role: "button",
                "data-toggle": "modal",
                "data-target": "#modalTagFriends"
              },
              on: { click: _vm.showFriends }
            },
            [_vm._v("\n      表示\n    ")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "fw-200" }, [
      _vm._v(_vm._s(_vm.getCreatedAt(_vm.tag.created_at)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }