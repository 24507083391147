var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c(
              "form",
              {
                ref: "form",
                attrs: {
                  action: _vm.formAction,
                  method: "post",
                  enctype: "multipart/form-data"
                },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    validate().then(_vm.onSubmit)
                  }
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "review[friend_line_id]" },
                  domProps: { value: _vm.friendLineId }
                }),
                _vm._v(" "),
                _vm._l(_vm.questions, function(question, questionIndex) {
                  return _c(
                    "div",
                    {
                      key: "question_" + question.id,
                      staticClass: "form-group review-question"
                    },
                    [
                      _c("h3", { staticClass: "review-question-title mt-0" }, [
                        _vm._v("\n        " + _vm._s(question.title) + " "),
                        question.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name:
                            "review[review_answers_attributes][" +
                            questionIndex +
                            "][review_question_id]"
                        },
                        domProps: { value: question.id }
                      }),
                      _vm._v(" "),
                      question.type == "rating"
                        ? _c(
                            "div",
                            { staticClass: "review-answer-options-container" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: question.title + "の評価",
                                  rules: question.required ? "required" : ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "review-answer-options d-flex justify-content-around"
                                            },
                                            [
                                              _vm._l(10, function(answerIndex) {
                                                return [
                                                  answerIndex >=
                                                    question.config.min_value &&
                                                  answerIndex <=
                                                    question.config.max_value
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key:
                                                            "review_answer_" +
                                                            answerIndex,
                                                          staticClass:
                                                            "review-answer-option form-check form-check-inline d-flex flex-column mr-0"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label justify-content-center mb-2",
                                                              attrs: {
                                                                for:
                                                                  "question_" +
                                                                  question.id +
                                                                  "_answer_" +
                                                                  answerIndex
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  answerIndex
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .reviewFormData[
                                                                    "answerOfQuestion" +
                                                                      question.id
                                                                  ],
                                                                expression:
                                                                  "reviewFormData[`answerOfQuestion${question.id}`]"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-check-input mr-0",
                                                            attrs: {
                                                              type: "radio",
                                                              name:
                                                                "review[review_answers_attributes][" +
                                                                questionIndex +
                                                                "][answer]",
                                                              id:
                                                                "question_" +
                                                                question.id +
                                                                "_answer_" +
                                                                answerIndex
                                                            },
                                                            domProps: {
                                                              value: answerIndex,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .reviewFormData[
                                                                  "answerOfQuestion" +
                                                                    question.id
                                                                ],
                                                                answerIndex
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.reviewFormData,
                                                                  "answerOfQuestion" +
                                                                    question.id,
                                                                  answerIndex
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          answerIndex ===
                                                          question.config
                                                            .min_value
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mt-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      question
                                                                        .config
                                                                        .min_label
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          answerIndex ===
                                                          question.config
                                                            .max_value
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mt-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      question
                                                                        .config
                                                                        .max_label
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              })
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      question.type == "text"
                        ? _c(
                            "div",
                            { staticClass: "review-answer-text mt-3" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: question.title,
                                  rules: question.required ? "required" : ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.reviewFormData[
                                                    "answerOfQuestion" +
                                                      question.id
                                                  ],
                                                expression:
                                                  "reviewFormData[`answerOfQuestion${question.id}`]"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name:
                                                "review[review_answers_attributes][" +
                                                questionIndex +
                                                "][answer]",
                                              placeholder: "回答を入力",
                                              rows: "4"
                                            },
                                            domProps: {
                                              value:
                                                _vm.reviewFormData[
                                                  "answerOfQuestion" +
                                                    question.id
                                                ]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.reviewFormData,
                                                  "answerOfQuestion" +
                                                    question.id,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-footer border-0 text-center py-3" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn fw-80 send-review text-white",
                        attrs: { type: "submit" }
                      },
                      [_c("strong", [_vm._v("送信")])]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("loading-indicator", { attrs: { loading: _vm.loading } })
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }