var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "account-pages mt-5 mb-5" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center mt-5" }, [
          _c("div", { staticClass: "col-lg-5" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "card-body p-4" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: { action: _vm.getAction(), method: "post" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: "authenticity_token" },
                      domProps: { value: _vm.csrfToken }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "emailaddress" } }, [
                          _vm._v("メールアドレス")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agencyData.email,
                              expression: "agencyData.email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            "data-vv-as": "メール",
                            name: "agency[email]",
                            placeholder: "メールを入力してください"
                          },
                          domProps: { value: _vm.agencyData.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.agencyData,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("error-message", {
                          attrs: { message: _vm.errors.first("agency[email]") }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "text-muted float-right",
                            attrs: {
                              href: _vm.rootPath + "/agency/password/new"
                            }
                          },
                          [_c("small", [_vm._v("パスワードを忘れましたか?")])]
                        ),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "password" } }, [
                          _vm._v("パスワード")
                        ]),
                        _vm._v(" "),
                        _c("input-password", {
                          attrs: {
                            name: "agency[password]",
                            password: _vm.agencyData.password
                          },
                          on: {
                            "update:password": function($event) {
                              return _vm.$set(
                                _vm.agencyData,
                                "password",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agencyData.remember_me,
                                expression: "agencyData.remember_me"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              name: "agency[remember_me]",
                              id: "checkbox-signin",
                              checked: ""
                            },
                            domProps: {
                              checked: Array.isArray(_vm.agencyData.remember_me)
                                ? _vm._i(_vm.agencyData.remember_me, null) > -1
                                : _vm.agencyData.remember_me
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.agencyData.remember_me,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.agencyData,
                                        "remember_me",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.agencyData,
                                        "remember_me",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.agencyData, "remember_me", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "checkbox-signin" }
                            },
                            [_vm._v("ログインを記憶する")]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit", disabled: _vm.invalid }
                        },
                        [_vm._v("ログイン")]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header pt-4 pb-4 text-center bg-primary" },
      [
        _c(
          "a",
          {
            staticClass: "text-white text-uppercase",
            staticStyle: { "font-size": "1.2rem" },
            attrs: { href: "#" }
          },
          [_c("span", [_vm._v("Hotel Insight CRM")])]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center w-75 m-auto" }, [
      _c(
        "h4",
        { staticClass: "text-dark-50 text-center mt-0 font-weight-bold" },
        [_vm._v("契約者ログイン")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-muted mb-4" }, [
        _vm._v(
          "\n                  管理パネルにアクセスするには、メールアドレスとパスワードを入力してください。\n                "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }