var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visible
        ? [
            _c(
              "div",
              {
                staticClass: "btn btn-sm btn-secondary",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalConfirmToggleVisible"
                }
              },
              [_vm._v("未表示")]
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "btn btn-sm btn-secondary",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalConfirmToggleVisible"
                }
              },
              [_vm._v("表示")]
            )
          ],
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          id: "modalConfirmToggleVisible",
          title: "友達表示状況の変更してもよろしいですか？",
          type: "confirm"
        },
        on: {
          confirm: function($event) {
            return _vm.toggle()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("b", [_vm._v(_vm._s(_vm.visible ? "表示" : "未表示"))]),
                _vm._v(" "),
                _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                _vm._v(" "),
                _c("b", [_vm._v(_vm._s(_vm.visible ? "未表示" : "表示"))])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }