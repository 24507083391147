var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c(
              "form",
              {
                ref: "form",
                attrs: {
                  action: _vm.formAction,
                  method: "post",
                  enctype: "multipart/form-data",
                  "data-remote": "true"
                },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    validate().then(_vm.onSubmit)
                  }
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mxw-1200" }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "form-border" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "fw-300" }, [
                              _vm._v("フォルダー")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex-grow-1" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.siteFormData.folder_id,
                                      expression: "siteFormData.folder_id"
                                    }
                                  ],
                                  staticClass: "form-control fw-300",
                                  attrs: { name: "site[folder_id]" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.siteFormData,
                                        "folder_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.folders, function(folder, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: folder.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(folder.name) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-border" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "サイト名",
                                rules: "required|max:255"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v("サイト名"),
                                                _c("required-mark")
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.siteFormData.name,
                                                  expression:
                                                    "siteFormData.name",
                                                  modifiers: { trim: true }
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "site[name]",
                                                maxlength: "255",
                                                placeholder:
                                                  "サイト名を入力してください"
                                              },
                                              domProps: {
                                                value: _vm.siteFormData.name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.siteFormData,
                                                    "name",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "error-explanation"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-border" },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "URL", rules: "required|url" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v("URL"),
                                                _c("required-mark")
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.siteFormData.url,
                                                  expression:
                                                    "siteFormData.url",
                                                  modifiers: { trim: true }
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "site[url]",
                                                placeholder:
                                                  "URLを入力してください"
                                              },
                                              domProps: {
                                                value: _vm.siteFormData.url
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.siteFormData,
                                                    "url",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "error-explanation"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-footer d-flex" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success mw-120",
                            attrs: { type: "submit" }
                          },
                          [_c("strong", [_vm._v("サイト登録")])]
                        )
                      ]),
                      _vm._v(" "),
                      _c("loading-indicator", {
                        attrs: { loading: _vm.loading }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }