var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showTitle
      ? _c("div", [
          _c(
            "label",
            { staticClass: "w-100" },
            [
              _vm._v("\n      ラベル\n      "),
              _vm.requiredLabel ? _c("required-mark") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.label,
                    expression: "label",
                    modifiers: { trim: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: _vm.requiredLabel && _vm.showTitle,
                      max: 12
                    },
                    expression:
                      "{ required: requiredLabel && showTitle, max: 12 }"
                  }
                ],
                staticClass: "w-100 form-control",
                attrs: {
                  type: "text",
                  name: _vm.name + "_label",
                  placeholder: "ラベルを入力してください",
                  maxlength: "13",
                  "data-vv-as": "ラベル"
                },
                domProps: { value: _vm.label },
                on: {
                  keyup: _vm.changeLabel,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.label = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 mt-1 font-12 text-muted" }, [
                _vm._v("10文字を超える場合、文が途中で途切れる場合があります。")
              ]),
              _vm._v(" "),
              _c("error-message", {
                staticClass: "w-100",
                attrs: { message: _vm.errors.first(_vm.name + "_label") }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showLaunchMessage
      ? _c(
          "div",
          { staticClass: "form-group mt-2" },
          [
            _c(
              "label",
              [
                _vm._v("選択時のメッセージ"),
                _vm.requiredLabel ? _c("required-mark") : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.displayText,
                  expression: "displayText",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: _vm.requiredLabel, max: 300 },
                  expression: "{ required: requiredLabel, max: 300 }"
                }
              ],
              staticClass: "w-100 form-control",
              attrs: {
                type: "text",
                placeholder: "選択時のメッセージを入力してください",
                name: _vm.name + "_display_text",
                maxlength: "301",
                "data-vv-as": "選択時のメッセージ"
              },
              domProps: { value: _vm.displayText },
              on: {
                keyup: function($event) {
                  return _vm.changeDisplayText($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.displayText = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("error-message", {
              staticClass: "w-100",
              attrs: { message: _vm.errors.first(_vm.name + "_display_text") }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("label", [_vm._v("選択後の挙動")]),
        _vm._v(" "),
        _vm._l(_vm.actions, function(action, index) {
          return _c(
            "div",
            { key: index, staticClass: "card border-warning border" },
            [
              _c("div", { staticClass: "card-header" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("span", { staticClass: "flex-1 text-nowrap" }, [
                    _vm._v("アクション" + _vm._s(index + 1))
                  ]),
                  _vm._v(" "),
                  _vm.actions.length > 1
                    ? _c("div", { staticClass: "ml-auto" }, [
                        index > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-sm btn-light",
                                on: {
                                  click: function($event) {
                                    return _vm.moveUp(index)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "dripicons-chevron-up" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index < _vm.actions.length - 1
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-sm btn-light",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveDown(index)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "dripicons-chevron-down"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.actions.length > 1
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-sm btn-light",
                                on: {
                                  click: function($event) {
                                    return _vm.remove(index)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "dripicons-minus" })]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("action-postback", {
                    attrs: {
                      showTitle: false,
                      value: action,
                      name: _vm.name + "_postback_" + index,
                      requiredLabel: false
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeActionType(index, $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm.actions.length < _vm.limit
      ? _c("div", { staticClass: "text-center mt-2" }, [
          _c(
            "div",
            {
              staticClass: "btn btn-warning",
              attrs: { role: "button" },
              on: {
                click: function($event) {
                  return _vm.addAction()
                }
              }
            },
            [_c("i", { staticClass: "uil-plus" }), _vm._v(" アクションの追加")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }