var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "multiselect",
        {
          attrs: {
            "allow-empty": true,
            options: _vm.agents,
            "option-height": 60,
            "show-labels": false,
            placeholder: "未割り当て",
            noOptions: "データはありません",
            label: "name",
            "track-by": "id",
            multiple: false,
            showNoResults: false
          },
          on: {
            select: _vm.onSelected,
            remove: function($event) {
              return _vm.onSelected(null)
            },
            open: function($event) {
              return _vm.$emit("open", _vm.agents.length)
            },
            close: function($event) {
              return _vm.$emit("close")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "singleLabel",
              fn: function(props) {
                return [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("img", {
                      staticClass: "option-image",
                      attrs: {
                        src:
                          props.option.avatar_url ||
                          "/img/no-image-profile.png",
                        alt: "Staff avatar"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm._f("truncate")(props.option.name, 12)))
                    ])
                  ])
                ]
              }
            },
            {
              key: "option",
              fn: function(props) {
                return [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("img", {
                      staticClass: "option-image",
                      attrs: {
                        src:
                          props.option.avatar_url ||
                          "/img/no-image-profile.png",
                        alt: "Staff avatar"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm._f("truncate")(props.option.name, 15)))
                    ])
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.selectedStaff,
            callback: function($$v) {
              _vm.selectedStaff = $$v
            },
            expression: "selectedStaff"
          }
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v("担当者が見つかりません")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }