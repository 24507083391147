var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-object-selector", {
        staticClass: "form-group",
        attrs: {
          title: "Action Type",
          data: _vm.action,
          supports: _vm.supports,
          isNone: _vm.isNone,
          messageType: _vm.messageType
        },
        on: { input: _vm.changeAction }
      }),
      _vm._v(" "),
      _c("action-object-value", {
        attrs: {
          data: _vm.action,
          name: "message_action_" + _vm.name,
          requiredLabel: _vm.requiredLabel,
          showTitle: _vm.showTitle
        },
        on: { input: _vm.changeAction }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }