var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c(
                "div",
                { staticClass: "card-header d-flex align-items-center" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-info fw-120 mr-2",
                      attrs: { href: _vm.rootUrl + "/admin/agencies/new" }
                    },
                    [
                      _c("i", { staticClass: "uil-plus" }),
                      _vm._v(" 新規登録\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-auto d-flex" }, [
                    _c("div", { staticClass: "input-group app-search" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.queryParams.name_or_email_cont,
                            expression: "queryParams.name_or_email_cont"
                          }
                        ],
                        staticClass: "form-control dropdown-toggle fw-250",
                        attrs: { type: "text", placeholder: "検索..." },
                        domProps: { value: _vm.queryParams.name_or_email_cont },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.queryParams,
                              "name_or_email_cont",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "mdi mdi-magnify search-icon"
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-info",
                            on: { click: _vm.loadAgencies }
                          },
                          [_vm._v("検索")]
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-centered mb-0" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._l(_vm.agencies, function(agency, index) {
                        return _c("tbody", { key: agency.id }, [
                          _c("tr", [
                            _c("td", { staticClass: "fw-100" }, [
                              _c("span", [_vm._v(_vm._s(agency.id))])
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "mxw-400" }, [
                              _vm._v(_vm._s(agency.name))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "mxw-300" }, [
                              _vm._v(_vm._s(agency.email))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "fw-200" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatted_time")(agency.created_at)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "fw-200" }, [
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: {
                                      "aria-labelledby": "dropdownMenuUser"
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          href:
                                            _vm.rootUrl +
                                            "/admin/agencies/" +
                                            agency.id +
                                            "/edit",
                                          target: "_blank",
                                          role: "button"
                                        }
                                      },
                                      [_vm._v("契約者を編集")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          role: "button",
                                          "data-toggle": "modal",
                                          "data-target": "#modalDeleteAgency"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.curAgencyIndex = index
                                          }
                                        }
                                      },
                                      [_vm._v("契約者を削除")]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-sm btn-info",
                                  attrs: {
                                    href:
                                      _vm.rootUrl +
                                      "/admin/agencies/" +
                                      agency.id +
                                      "/sso"
                                  }
                                },
                                [_vm._v("ログイン")]
                              )
                            ])
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-4" },
                  [
                    _vm.totalRows > _vm.perPage
                      ? _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                            "aria-controls": "my-table"
                          },
                          on: { change: _vm.loadAgencies },
                          model: {
                            value: _vm.queryParams.page,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "page", $$v)
                            },
                            expression: "queryParams.page"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.loading && _vm.totalRows === 0
                  ? _c(
                      "div",
                      { staticClass: "my-5 text-center font-weight-bold" },
                      [_vm._v("データはありません。")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("loading-indicator", { attrs: { loading: _vm.loading } })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "こちらの契約者を削除してよろしいですが?",
          id: "modalDeleteAgency",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteAgency },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curAgency
                  ? _c("div", [
                      _vm._v("\n        契約者名: "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm._f("truncate")(_vm.curAgency.name, 64))
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("氏名")]),
        _vm._v(" "),
        _c("th", [_vm._v("メールアドレス")]),
        _vm._v(" "),
        _c("th", [_vm._v("登録日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuUser",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }