var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("ValidationObserver", { ref: "observer" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-12" },
                [_vm._v("日時"), _c("required-mark")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "日時", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("datetime", {
                              attrs: {
                                "input-class": "form-control",
                                type: "datetime",
                                phrases: { ok: "確定", cancel: "閉じる" },
                                placeholder: "日付を選択してください",
                                "min-datetime": _vm.currentDate,
                                "value-zone": "Asia/Tokyo",
                                zone: "Asia/Tokyo"
                              },
                              model: {
                                value: _vm.announcementData.announced_at,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.announcementData,
                                    "announced_at",
                                    $$v
                                  )
                                },
                                expression: "announcementData.announced_at"
                              }
                            }),
                            _vm._v(" "),
                            _c("error-message", {
                              attrs: { message: errors[0] }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-12" },
                [_vm._v("タイトル"), _c("required-mark")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "タイトル", rules: "required|max:512" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.announcementData.title,
                                  expression: "announcementData.title",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "announcement[title]",
                                placeholder: "入力してください"
                              },
                              domProps: { value: _vm.announcementData.title },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.announcementData,
                                    "title",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("error-message", {
                              attrs: { message: errors[0] }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-12" },
                [_vm._v("本文"), _c("required-mark")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("rich-text-input", {
                    attrs: { value: _vm.announcementData.body },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.announcementData, "body", $event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ValidationProvider", {
                    attrs: { name: "本文", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.announcementData.body,
                                  expression: "announcementData.body"
                                }
                              ],
                              attrs: {
                                type: "hidden",
                                name: "announcement[body]"
                              },
                              domProps: { value: _vm.announcementData.body },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.announcementData,
                                    "body",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("error-message", {
                              attrs: { message: errors[0] }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer row-form-btn d-flex" }, [
            _c(
              "div",
              {
                staticClass: "btn btn-info fw-120 mr-2",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.onSubmit("published")
                  }
                }
              },
              [_vm._v("登録")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn btn-outline-info fw-120",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.onSubmit("draft")
                  }
                }
              },
              [_vm._v("下書き保存")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }