var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.responses, function(response, index) {
          return _c("tr", { key: index }, [
            _c(
              "td",
              { staticClass: "table-user d-flex align-items-center mw-200" },
              [
                _c("img", {
                  staticClass: "mr-2 rounded-circle",
                  attrs: {
                    src:
                      response.line_friend.avatar_url ||
                      "/img/no-image-profile.png",
                    alt: "table-user"
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "m-0 text-truncate" }, [
                  _vm._v(_vm._s(response.line_friend.display_name))
                ])
              ]
            ),
            _vm._v(" "),
            _c("td", [
              _c("label", [_vm._v("フォームの回答")]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  _vm._l(response.survey_answers, function(answer, index) {
                    return _c("div", { key: index }, [
                      index > 0
                        ? _c("div", { staticClass: "divider my-2" })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex my-1" }, [
                        _c(
                          "div",
                          { staticClass: "mw-200 mr-2 font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(answer.survey_question.content["text"])
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-grow-1" }, [
                          answer.survey_question.type === "image"
                            ? _c("div", [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy:background-image",
                                      value: answer.file_url,
                                      expression: "answer.file_url",
                                      arg: "background-image"
                                    }
                                  ],
                                  staticClass: "fw-120 fh-81 background-cover"
                                })
                              ])
                            : answer.survey_question.type === "pdf"
                            ? _c("div", { staticClass: "d-flex" }, [
                                _c("img", {
                                  staticClass: "fw-120 fh-120 background-cover",
                                  attrs: {
                                    src:
                                      _vm.rootPath + "/images/messages/pdf.png"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-light my-auto text-nowrap",
                                    attrs: {
                                      href: answer.file_url,
                                      download: "lineinsight.pdf"
                                    }
                                  },
                                  [_vm._v("ダウンロード")]
                                )
                              ])
                            : _c("p", { staticClass: "vw-40 item-name" }, [
                                _vm._v(_vm._s(answer.answer || "未設定"))
                              ])
                        ])
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "mw-200" }, [
              _vm._v(_vm._s(_vm._f("formatted_time")(response.created_at)))
            ])
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c(
        "div",
        [
          _vm.totalRows > _vm.perPage
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "aria-controls": "my-table"
                },
                on: { change: _vm.loadResponses },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("回答者")]),
        _vm._v(" "),
        _c("th", [_vm._v("フォームの回答")]),
        _vm._v(" "),
        _c("th", [_vm._v("回答日")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }