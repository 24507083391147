var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "label",
        { staticClass: "w-100" },
        [_vm._v("\n    コンテンツ\n    "), _c("required-mark")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.currentTemplate.template_id
            ? _c(
                "div",
                {
                  staticClass: "btn btn-secondary mw-150",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.name
                  }
                },
                [
                  _vm.currentTemplate.content
                    ? _c("message-content", {
                        attrs: { data: _vm.currentTemplate }
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.currentTemplate.name))])
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "btn btn-secondary mw-150",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#" + _vm.name
                  }
                },
                [_vm._v("\n      テンプレートを選択\n    ")]
              ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentTemplate.template_id,
                expression: "currentTemplate.template_id"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "hidden",
              name: _vm.name + "_template_id",
              "data-vv-as": "テンプレート"
            },
            domProps: { value: _vm.currentTemplate.template_id },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.currentTemplate,
                  "template_id",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("error-message", {
            staticClass: "w-100",
            attrs: { message: _vm.errors.first(_vm.name + "_template_id") }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-select-template", {
        attrs: { id: _vm.name },
        on: { selectTemplate: _vm.selectTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }