var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card chat-panel" },
    [
      _c("div", { staticClass: "card-header d-flex" }, [
        _c(
          "a",
          {
            staticClass: "icon-fs d-sm-block d-lg-none",
            on: {
              click: function($event) {
                return _vm.hiddenChatBox()
              }
            }
          },
          [_c("i", { staticClass: "dripicons-arrow-thin-left" })]
        ),
        _vm._v(" "),
        _c("a", { staticClass: "flex-grow-1" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "icon-fs d-sm-block d-xl-none",
            on: {
              click: function($event) {
                return _vm.showUserDetailBox()
              }
            }
          },
          [_c("i", { staticClass: "mdi mdi-account-details" })]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body d-flex flex-column" },
        [
          _c(
            "ul",
            {
              ref: "chatPanel",
              staticClass: "flex-grow-1 conversation-list overflow-auto",
              on: {
                scroll: _vm.handleScroll,
                drop: _vm.onDropMessage,
                dragover: _vm.allowDrop
              }
            },
            [
              _c("transition", { attrs: { name: "slide-up" } }, [
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shouldShowSpinner,
                        expression: "shouldShowSpinner"
                      }
                    ],
                    staticClass: "spinner-border",
                    attrs: { role: "status" }
                  })
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.messages, function(message, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    attrs: { id: "message_content_" + message.id }
                  },
                  [
                    _c("chat-item", {
                      key: _vm.componentKey,
                      attrs: {
                        message: message,
                        prevMessage: index > 0 ? _vm.messages[index - 1] : null,
                        lastSeenAt: _vm.activeChannel.last_seen_at,
                        showUnreadMarkDiv: message.shouldShowUnreadDiv
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("reply-box", {
            ref: "replyBox",
            on: {
              sendTextMessage: _vm.sendTextMessage,
              resetModalSticker: _vm.resetModalSticker
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("loading-indicator")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }