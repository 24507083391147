var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c(
                "div",
                { staticClass: "card-header d-flex align-items-center" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-info fw-120 mr-2",
                      attrs: { href: _vm.rootUrl + "/agency/users/new" }
                    },
                    [
                      _c("i", { staticClass: "uil-plus" }),
                      _vm._v(" 新規登録\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-auto d-flex" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.queryParams.status_eq,
                            expression: "queryParams.status_eq"
                          }
                        ],
                        staticClass: "form-control fw-150 mr-1",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.queryParams,
                              "status_eq",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("すべて")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "active" } }, [
                          _vm._v("有効")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "blocked" } }, [
                          _vm._v("無効")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group app-search" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.queryParams
                                .name_or_company_name_or_email_cont,
                            expression:
                              "queryParams.name_or_company_name_or_email_cont"
                          }
                        ],
                        staticClass: "form-control dropdown-toggle fw-250",
                        attrs: { type: "text", placeholder: "検索..." },
                        domProps: {
                          value:
                            _vm.queryParams.name_or_company_name_or_email_cont
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.queryParams,
                              "name_or_company_name_or_email_cont",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "mdi mdi-magnify search-icon"
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-info",
                            on: { click: _vm.loadUsers }
                          },
                          [_vm._v("検索")]
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-centered mb-0" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._l(_vm.users, function(user, index) {
                        return _c("tbody", { key: user.id }, [
                          _c("tr", [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.rootUrl + "/admin/users/" + user.id
                                  }
                                },
                                [_vm._v(_vm._s(user.id))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.email))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.company_name))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formattedDatetime(user.created_at))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [_c("user-status", { attrs: { user: user } })],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: {
                                      "aria-labelledby": "dropdownMenuUser"
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          href:
                                            _vm.rootUrl +
                                            "/admin/users/" +
                                            user.id +
                                            "/edit",
                                          target: "_blank",
                                          role: "button"
                                        }
                                      },
                                      [_vm._v("クライアントを編集")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          role: "button",
                                          "data-toggle": "modal",
                                          "data-target":
                                            "#modalToggleStatusUser"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.curUserIndex = index
                                          }
                                        }
                                      },
                                      [
                                        user.status === "active"
                                          ? _c("span", [_vm._v("ブロックする")])
                                          : _c("span", [
                                              _vm._v("ブロック解除する")
                                            ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          role: "button",
                                          "data-toggle": "modal",
                                          "data-target": "#modalDeleteUser"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.curUserIndex = index
                                          }
                                        }
                                      },
                                      [_vm._v("クライアントを削除")]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-sm btn-info",
                                  attrs: {
                                    href:
                                      _vm.rootUrl +
                                      "/admin/users/" +
                                      user.id +
                                      "/sso"
                                  }
                                },
                                [_vm._v("ログイン")]
                              )
                            ])
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-4" },
                  [
                    _vm.totalRows > _vm.perPage
                      ? _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                            "aria-controls": "my-table"
                          },
                          on: { change: _vm.loadUsers },
                          model: {
                            value: _vm.queryParams.page,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "page", $$v)
                            },
                            expression: "queryParams.page"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading && _vm.totalRows === 0
                      ? _c("b", [_vm._v("データはありません。")])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("loading-indicator", { attrs: { loading: _vm.loading } })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "このユーザーの状況を変更してもよろしいですか？",
          id: "modalToggleStatusUser",
          type: "confirm"
        },
        on: { confirm: _vm.submitToggleStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curUser
                  ? _c("div", [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curUser.status === "active"
                              ? "有効"
                              : "ブロックした"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.curUser.status === "active"
                              ? "ブロックした"
                              : "有効"
                          )
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "こちらのユーザーを削除してよろしいですが?",
          id: "modalDeleteUser",
          type: "delete"
        },
        on: { confirm: _vm.submitDeleteUser },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.curUser
                  ? _c("div", [
                      _vm._v("\n        メールアドレス: "),
                      _c("b", [_vm._v(_vm._s(_vm.curUser.email))])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("氏名")]),
        _vm._v(" "),
        _c("th", [_vm._v("メールアドレス")]),
        _vm._v(" "),
        _c("th", [_vm._v("会社名")]),
        _vm._v(" "),
        _c("th", [_vm._v("登録日時")]),
        _vm._v(" "),
        _c("th", [_vm._v("状況")]),
        _vm._v(" "),
        _c("th", { staticClass: "fw-200" }, [_vm._v("操作")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuUser",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [
        _vm._v("\n                        操作 "),
        _c("span", { staticClass: "caret" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }