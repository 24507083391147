var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.actions.length > 0
    ? _c(
        "div",
        _vm._l(_vm.actions, function(action, index) {
          return _c(
            "div",
            { key: index, staticClass: "badge badge-warning pt-1 mr-1" },
            [
              action.type === "text"
                ? _c("span", [_vm._v("テキスト送信")])
                : action.type === "template"
                ? _c("span", [_vm._v("テンプレート送信")])
                : action.type === "scenario"
                ? _c("span", [_vm._v("シナリオ送信")])
                : action.type === "email"
                ? _c("span", [_vm._v("メール通知")])
                : action.type === "tag"
                ? _c("span", [_vm._v("タグ操作")])
                : action.type === "reminder"
                ? _c("span", [_vm._v("リマインダ配信")])
                : action.type === "none"
                ? _c("span", [_vm._v("何もしない")])
                : _vm._e()
            ]
          )
        }),
        0
      )
    : _c("div", [_vm._v("アクションなし")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }