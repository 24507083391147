var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("span", { staticClass: "title-editor ml-3" }, [_vm._v("絵文字")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        ref: "textarea",
        staticClass: "form-control",
        attrs: {
          rows: "5",
          placeholder: "本文を入力してください",
          maxlength: "5001"
        },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.value,
            expression: "value",
            modifiers: { trim: true }
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|max:5000",
            expression: "'required|max:5000'"
          }
        ],
        attrs: {
          type: "hidden",
          "data-vv-as": "本文",
          name: "message-editor" + _vm.index
        },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value.trim()
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("error-message", {
        attrs: { message: _vm.errors.first("message-editor" + _vm.index) }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-sm" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("{name}：お客様の名前")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }