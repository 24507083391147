var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-sm" }, [
    _vm.status === "sending"
      ? _c("span", [_vm._v("送信中")])
      : _vm.status === "sent"
      ? _c("span", [_vm._v("済")])
      : _vm.status === "error"
      ? _c("span", { staticClass: "text-danger" }, [_vm._v("失敗")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }