var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.locked
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle text-danger" }),
          _vm._v(" ブロックした")
        ])
      : _vm.status === "active"
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle text-success" }),
          _vm._v(" 有効 ")
        ])
      : _vm.status === "blocked"
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle text-warning" }),
          _vm._v(" ブロックされた ")
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.visible
      ? _c("span", [
          _c("i", { staticClass: "mdi mdi-circle" }),
          _vm._v(" 非表示")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }