var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "btn btn-secondary mw-200",
          attrs: {
            "data-toggle": "modal",
            "data-target": "#" + _vm.name + "-modal-select-variable"
          }
        },
        [
          _vm._v(
            "\n    " + _vm._s(this.variable_name || "友だち情報を選択") + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("modal-select-variable", {
        attrs: { type: _vm.type, id: _vm.name + "-modal-select-variable" },
        on: {
          selectVariable: function($event) {
            return _vm.selectVariable($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }