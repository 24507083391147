var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var validate = ref.validate
          return [
            _c("ValidationProvider", {
              attrs: { name: "サイト名", rules: "required|max:255" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("div", { staticClass: "input-group d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.siteName,
                                expression: "siteName",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "サイト名",
                              name: "site_name",
                              maxlength: "255",
                              "data-vv-as": "サイト名"
                            },
                            domProps: { value: _vm.siteName },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.enterSubmitChangeName.apply(
                                  null,
                                  arguments
                                )
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.siteName = $event.target.value.trim()
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-auto" }, [
                            _c(
                              "div",
                              {
                                ref: "buttonChange",
                                staticClass: "btn btn-default",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    validate().then(_vm.submitChangeName)
                                  }
                                }
                              },
                              [_vm._v("決定")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-explanation" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }