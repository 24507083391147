var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("folder-left", {
                attrs: {
                  type: "tag",
                  data: _vm.folders,
                  isPc: _vm.isPc,
                  selectedFolder: _vm.selectedFolderIndex
                },
                on: {
                  changeSelectedFolder: _vm.onFolderChanged,
                  submitUpdateFolder: _vm.submitUpdateFolder,
                  submitCreateFolder: _vm.submitCreateFolder
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1 folder-right" }, [
                _c(
                  "div",
                  { staticClass: "btn btn-primary", on: { click: _vm.addTag } },
                  [_c("i", { staticClass: "uil-plus" }), _vm._v(" 新規登録")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive mt-2" }, [
                  _c("table", { staticClass: "table mb-0" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.curFolder
                      ? _c(
                          "tbody",
                          [
                            _vm.showTagInput
                              ? _c("tr", [
                                  _c("td", { staticClass: "mw-200" }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-group newgroup-inputs"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.tagData.name,
                                                  expression: "tagData.name",
                                                  modifiers: { trim: true }
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required|max:32",
                                                  expression:
                                                    "'required|max:32'"
                                                }
                                              ],
                                              ref: "tagName",
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: "タグ名",
                                                name: "tag_name",
                                                maxlength: "33",
                                                "data-vv-as": "タグ名"
                                              },
                                              domProps: {
                                                value: _vm.tagData.name
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.tagData,
                                                    "name",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "input-group-btn"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    ref: "buttonAddTag",
                                                    staticClass:
                                                      "btn btn-light",
                                                    attrs: { role: "button" },
                                                    on: {
                                                      click: _vm.submitCreateTag
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          決定\n                        "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.errors.first("tag_name")
                                          ? _c("error-message", {
                                              attrs: {
                                                message: _vm.errors.first(
                                                  "tag_name"
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.getCreatedAt()))])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.curFolder.tags, function(item, index) {
                              return _c("tag-item", {
                                key: index,
                                attrs: { tag: item },
                                on: {
                                  deleteTag: function($event) {
                                    _vm.curTag = item
                                  },
                                  editTag: _vm.submitUpdateTag,
                                  showFriends: function($event) {
                                    _vm.curTag = $event
                                  }
                                }
                              })
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.curFolder && _vm.curFolder.tags.length === 0
                    ? _c("div", { staticClass: "mt-5 text-md text-center" }, [
                        _c("b", [_vm._v("登録したタグはありません。")])
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("modal-confirm", {
            attrs: {
              title: "このフォルダーを削除してもよろしいですか？",
              id: "modalDeleteFolder",
              type: "delete"
            },
            on: { confirm: _vm.submitDeleteFolder },
            scopedSlots: _vm._u(
              [
                _vm.curFolder
                  ? {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", [
                            _vm._v("\n          フォルダー名："),
                            _c("b", [_vm._v(_vm._s(_vm.curFolder.name))])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("modal-confirm", {
            attrs: {
              title: "このタグを削除してもよろしいですか？",
              type: "delete",
              id: "modalConfirmDelTag"
            },
            on: { confirm: _vm.submitDeleteTag },
            scopedSlots: _vm._u(
              [
                _vm.curFolder && _vm.curTag
                  ? {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", [
                            _vm._v("\n          フォルダー名："),
                            _c("b", [_vm._v(_vm._s(_vm.curFolder.name))])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v("\n          タグ名："),
                            _c("b", [_vm._v(_vm._s(_vm.curTag.name))])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("modal-tag-friends", { attrs: { tag: _vm.curTag } })
        ],
        1
      ),
      _vm._v(" "),
      _c("loading-indicator", { attrs: { loading: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", [_vm._v("タグ名")]),
        _vm._v(" "),
        _c("th", [_vm._v("メンバー数")]),
        _vm._v(" "),
        _c("th", [_vm._v("作成日")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", { staticClass: "mt-2" }, [_vm._v("0人")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }