var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.getClass(),
      attrs: { role: "button" },
      on: { click: _vm.selectPackageId }
    },
    [
      _vm.data.packageId
        ? _c("img", {
            attrs: {
              src:
                "https://stickershop.line-scdn.net/stickershop/v1/product/" +
                _vm.data.packageId +
                "/ANDROID/tab_on.png"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.icon ? _c("i", { class: _vm.data.icon }) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }