var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isSupportPostback
      ? _c("div", { staticClass: "mt-2" }, [
          _c(
            "div",
            {
              staticClass: "custom-control custom-radio custom-control-inline"
            },
            [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: {
                  type: "radio",
                  id: _vm.name + "ActionCategoryCustom" + _vm.index,
                  name: _vm.name + "_message_action_type",
                  value: "default"
                },
                domProps: { checked: _vm.type === "default" },
                on: {
                  change: function($event) {
                    return _vm.changeType("default")
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: _vm.name + "ActionCategoryCustom" + _vm.index }
                },
                [_vm._v("カスタムアクション")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "custom-control custom-radio custom-control-inline"
            },
            [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: {
                  type: "radio",
                  id: _vm.name + "ActionCategoryBasic" + _vm.index,
                  name: _vm.name + "_message_action_type",
                  value: "basic"
                },
                domProps: { checked: _vm.type === "basic" },
                on: {
                  change: function($event) {
                    return _vm.changeType("basic")
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: _vm.name + "ActionCategoryBasic" + _vm.index }
                },
                [_vm._v("基本アクション")]
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-2" },
      [
        _vm.type === "default" && _vm.isSupportPostback
          ? _c("action-editor-custom", {
              attrs: {
                name: _vm.name,
                value: _vm.data,
                requiredLabel: _vm.requiredLabel,
                showTitle: _vm.showTitle
              },
              on: { input: _vm.updateAction }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "basic"
          ? _c("action-editor-standard", {
              attrs: {
                value: _vm.data,
                name: _vm.name,
                supports: _vm.supports,
                isNone: _vm.isNone,
                showTitle: _vm.showTitle,
                requiredLabel: _vm.requiredLabel,
                messageType: _vm.messageType
              },
              on: { input: _vm.updateAction }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }