var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("daterange-picker", {
            staticClass: "d-block",
            attrs: {
              start_date: _vm.condition.add_friend_date.start_date,
              end_date: _vm.condition.add_friend_date.end_date
            },
            on: {
              "update:start_date": function($event) {
                return _vm.$set(
                  _vm.condition.add_friend_date,
                  "start_date",
                  $event
                )
              },
              "update:end_date": function($event) {
                return _vm.$set(
                  _vm.condition.add_friend_date,
                  "end_date",
                  $event
                )
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", {
        staticClass: "mdi mdi-calendar icon-color",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("友だち登録日\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }