var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    richmenu-preview\n    d-flex-sm\n    justify-content-center\n    align-items-center\n    position-relative\n    cursor-pointer\n    bg-white\n  ",
      style:
        _vm.typeTemplate == "compact" || _vm.templateId > 1000
          ? { cursor: "pointer", height: "86px" }
          : { cursor: "pointer", height: "172.554px" }
    },
    [
      _c("img", {
        staticClass: "w-100 h-100 position-absolute",
        attrs: { src: _vm.background || "/img/richmenu-default.png" }
      }),
      _vm._v(" "),
      _vm.background == null
        ? _c(
            "div",
            { staticClass: "text-center text-muted mb-0 position-absolute" },
            [
              _c("small", [
                _vm._v(
                  " テンプレートを選択して、背景画像をアップロードしてください。 "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.templateId == "201"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("B")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "C")
                      }
                    }
                  },
                  [_vm._v("C")]
                )
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "border-top border-primary" }, [
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "D")
                      }
                    }
                  },
                  [_vm._v("D")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "E")
                      }
                    }
                  },
                  [_vm._v("E")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "F")
                      }
                    }
                  },
                  [_vm._v("F")]
                )
              ])
            ]
          )
        : _vm.templateId == "202"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("B")]
                )
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "border-top border-primary" }, [
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "C")
                      }
                    }
                  },
                  [_vm._v("C")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "D")
                      }
                    }
                  },
                  [_vm._v("D")]
                )
              ])
            ]
          )
        : _vm.templateId == "203"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "100%" },
                    attrs: { colspan: "3" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                )
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "border-top border-primary" }, [
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("B")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "C")
                      }
                    }
                  },
                  [_vm._v("C")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "D")
                      }
                    }
                  },
                  [_vm._v("D")]
                )
              ])
            ]
          )
        : _vm.templateId == "204"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    attrs: { colspan: "2", rowspan: "2" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("B")]
                )
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "border-top border-primary" }, [
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "C")
                      }
                    }
                  },
                  [_vm._v("C")]
                )
              ])
            ]
          )
        : _vm.templateId == "205"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                )
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "border-top border-primary" }, [
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("B")]
                )
              ])
            ]
          )
        : _vm.templateId == "206"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "50%" },
                    attrs: { rowspan: "2" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "h2 text-white border-left border-primary",
                    staticStyle: { width: "50%" },
                    attrs: { rowspan: "2" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("\n        B\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "207"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "100%" },
                    attrs: { rowspan: "2" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "1001"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center table-compact"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.34%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("\n        B\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "C")
                      }
                    }
                  },
                  [_vm._v("\n        C\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "1002"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center table-compact"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "66.67%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("\n        B\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "1003"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center table-compact"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "66.67%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "33.33%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("\n        B\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "1004"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center table-compact"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "50%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "B")
                      }
                    }
                  },
                  [_vm._v("\n        B\n      ")]
                )
              ])
            ]
          )
        : _vm.templateId == "1005"
        ? _c(
            "table",
            {
              staticClass:
                "position-absolute w-100 h-100 rounded border border-primary text-center table-compact"
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass:
                      "h2 text-white border-left border-primary bg-dark-10",
                    staticStyle: { width: "100%" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", "A")
                      }
                    }
                  },
                  [_vm._v("\n        A\n      ")]
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }