var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", [
      _c("label", { staticClass: "w-100 mt-2" }, [_vm._v(" タグ設定 ")]),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-md-6 d-flex-auto p-0" }, [
          _c("span", [_vm._v("タグを追加")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("input-tag", {
                attrs: { allTags: true, tags: _vm.value[0].tags },
                on: { input: _vm.onAssignTagsDataChanged }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.value,
                    expression: "value",
                    modifiers: { trim: true }
                  }
                ],
                attrs: { type: "hidden", name: _vm.name + "_assign_tag" },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.value = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 d-flex-auto" }, [
          _c("span", [_vm._v("タグをはずす")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("input-tag", {
                attrs: { allTags: true, tags: _vm.value[1].tags },
                on: { input: _vm.onUnassignTagsDataChanged }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.value,
                    expression: "value",
                    modifiers: { trim: true }
                  }
                ],
                attrs: { type: "hidden", name: _vm.name + "_unassign_tag" },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.value = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }