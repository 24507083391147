var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "channel-list" }, [_c("channel-list")], 1),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "channel-chat",
            class: _vm.showChatBox ? "channel-chat-visible" : ""
          },
          [
            _c("chat-box", {
              ref: "chatBox",
              on: { onResetModalSticker: _vm.onResetModalSticker }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            key: _vm.contentKey,
            staticClass: "channel-friend",
            class: _vm.showUserDetail ? "channel-friend-visible" : ""
          },
          [_c("channel-friend-detail", { attrs: { role: _vm.role } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.activeChannel
        ? [
            _c("modal-select-media", {
              attrs: {
                id: "modalSendMedia",
                types: ["image", "audio", "video", "richmenu"]
              },
              on: {
                select: function($event) {
                  return _vm.sendMediaMessage($event)
                }
              }
            }),
            _vm._v(" "),
            _c("modal-send-template", {
              on: { sendTemplate: _vm.sendTemplate }
            }),
            _vm._v(" "),
            _c("modal-send-scenario", {
              on: { selectScenario: _vm.sendScenario }
            }),
            _vm._v(" "),
            _c("modal-select-sticker", {
              ref: "modalSticker",
              attrs: { id: "modalSelectSticker" },
              on: { input: _vm.sendStickerMessage }
            }),
            _vm._v(" "),
            _c("modal-confirm", {
              attrs: {
                id: "modalConfirmToggleLocked",
                title: "友達状況の変更してもよろしいですか？",
                type: "confirm"
              },
              on: {
                confirm: function($event) {
                  return _vm.toggle()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.curFriend.locked ? "ブロックした" : "有効"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("i", { staticClass: "mdi mdi-arrow-right-bold" }),
                        _vm._v(" "),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.curFriend.locked ? "有効" : "ブロックした"
                            )
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                229224415
              )
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }