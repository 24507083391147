var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("survey-question-header", {
        attrs: { question: _vm.question, qnum: _vm.qnum }
      }),
      _vm._v(" "),
      _c("ValidationProvider", {
        attrs: { name: "答え", rules: { required: _vm.isRequired } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.answer,
                        expression: "answer"
                      }
                    ],
                    staticClass: "form-control w-100",
                    attrs: { name: "answers[" + _vm.qnum + "][answer]" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.answer = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.options, function(option, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: option.value } },
                      [_vm._v("\n        " + _vm._s(option.value) + "\n      ")]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("error-message", { attrs: { message: errors[0] } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }