var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("label", { attrs: { for: "question_title" } }, [_vm._v("質問文")]),
        _vm._v(" "),
        _c("required-mark"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.messageData.text,
              expression: "messageData.text",
              modifiers: { trim: true }
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:240",
              expression: "'required|max:240'"
            }
          ],
          staticClass: "form-control",
          attrs: {
            name: "confirm-label" + _vm.indexParent,
            placeholder: "質問文を入力してください",
            maxlength: "241",
            autocomplete: "off",
            type: "text",
            "data-vv-as": "質問文"
          },
          domProps: { value: _vm.messageData.text },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.messageData, "text", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c("error-message", {
          attrs: {
            message: _vm.errors.first("confirm-label" + _vm.indexParent)
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "w-100 nav nav-tabs nav-bordered mt-2" },
      _vm._l(_vm.messageData.actions, function(item, index) {
        return _c("li", { key: index, staticClass: "nav-item" }, [
          _c(
            "a",
            {
              class:
                _vm.editingActionIndex === index
                  ? "nav-link active"
                  : "nav-link",
              attrs: {
                href: "#messageAction" + index,
                "data-toggle": "tab",
                "aria-expanded": _vm.editingActionIndex === index
              },
              on: {
                click: function($event) {
                  _vm.editingActionIndex = index
                }
              }
            },
            [
              _c("i", {
                staticClass: "mdi mdi-home-variant d-md-none d-block"
              }),
              _vm._v(" "),
              _c("span", { staticClass: "d-none d-md-block" }, [
                _vm._v("選択肢" + _vm._s(index + 1))
              ])
            ]
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-100 tab-content" },
      _vm._l(_vm.messageData.actions, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            class:
              _vm.editingActionIndex === index
                ? "tab-pane show active"
                : "tab-pane",
            attrs: { id: "messageAction" + index }
          },
          [
            _c(
              "div",
              [
                _c("action-editor", {
                  attrs: {
                    index: index,
                    name: "message" + _vm.indexParent + "Confirm" + index,
                    value: item,
                    supports: [
                      "postback",
                      "uri",
                      "message",
                      "datetimepicker",
                      "survey"
                    ]
                  },
                  on: {
                    input: function($event) {
                      var i = arguments.length,
                        argsArray = Array(i)
                      while (i--) argsArray[i] = arguments[i]
                      return _vm.changeAction.apply(
                        void 0,
                        [index].concat(argsArray)
                      )
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }