var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.blurInputTag,
          expression: "blurInputTag"
        }
      ],
      on: { click: _vm.focusForm }
    },
    [
      _c("b-form-tags", {
        staticClass: "mb-2 position-relative",
        attrs: {
          disabled: _vm.disabled,
          "no-outer-focus": "",
          "add-button-text": "追加",
          "remove-on-delete": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var tags = ref.tags
              var disabled = ref.disabled
              var addTag = ref.addTag
              return [
                _c("i", {
                  staticClass: "down icon-action",
                  class: _vm.isFocus
                    ? "fas fa-angle-up float-r"
                    : "fas mdi mdi-chevron-down float-r",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showDropDown.apply(null, arguments)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-inline d-inline-block mb-2" },
                  _vm._l(tags, function(tag) {
                    return _c(
                      "li",
                      { key: tag, staticClass: "list-inline-item" },
                      [
                        _c(
                          "b-form-tag",
                          {
                            attrs: {
                              title: tag,
                              disabled: disabled,
                              variant: "info"
                            },
                            on: {
                              remove: function($event) {
                                return _vm.removeTag(tag, addTag)
                              }
                            }
                          },
                          [_vm._v(_vm._s(tag))]
                        )
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("b-form-input", {
                  ref: "inputTag",
                  staticClass: "input-text-tag",
                  attrs: {
                    disabled: disabled,
                    type: "search",
                    autocomplete: "off",
                    placeholder: "タグ名を入力"
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "search"
                  }
                }),
                _vm._v(" "),
                !disabled && _vm.isFocus
                  ? _c(
                      "div",
                      {
                        staticClass: "w-100 dropdown-tag row",
                        class: { top: _vm.isShowTop() }
                      },
                      [
                        _c("div", { class: _vm.getClassLeftTag() }, [
                          _c("div", { staticClass: "tag-content" }, [
                            _c(
                              "table",
                              { staticClass: "table table-tags-header" },
                              [
                                _c("thead", { staticClass: "thead-light" }, [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { height: "42px" },
                                        attrs: { scope: "col" }
                                      },
                                      [_vm._v("フォルダー")]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "tag-scroll folder-list" },
                              _vm._l(_vm.folders, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    class:
                                      _vm.selectedFolderIndex === index
                                        ? "folder-item active"
                                        : "folder-item",
                                    on: {
                                      click: function($event) {
                                        return _vm.changeSelected(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class:
                                        _vm.selectedFolderIndex === index
                                          ? "fas fa-folder-open"
                                          : "fas fa-folder"
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "tag-label" }, [
                                      _vm._v(_vm._s(item.name))
                                    ]),
                                    _vm._v(
                                      " (" +
                                        _vm._s(item.tags.length) +
                                        ")\n              "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClassRightTag() }, [
                          _c("div", { staticClass: "tag-content" }, [
                            _c("div", { staticClass: "x-tag-header" }, [
                              _c("div", { staticClass: "x-btn-back" }, [
                                _c("i", {
                                  staticClass: "fas fa-arrow-left item-sm",
                                  staticStyle: { margin: "auto" },
                                  on: { click: _vm.backToFolder }
                                })
                              ]),
                              _vm._v(" "),
                              _vm.curFolder
                                ? _c("div", { staticClass: "x-title" }, [
                                    _vm._v(_vm._s(_vm.curFolder.name))
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.availableOptions && _vm.availableOptions.length
                              ? _c(
                                  "div",
                                  { staticClass: "tag-scroll tag-list" },
                                  _vm._l(_vm.availableOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        class: _vm.selectedTags.find(function(
                                          el
                                        ) {
                                          return el.id === item.id
                                        })
                                          ? "folder-item active"
                                          : "folder-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.onTagSelected({
                                              item: item,
                                              addTag: addTag
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tag-label" },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "tag-choose item-hidden"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-check"
                                            }),
                                            _vm._v("選択")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "tag-checked item-hidden"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-check"
                                            }),
                                            _vm._v("選択中")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "tag-remove item-hidden"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-times"
                                            }),
                                            _vm._v("解除")
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tag-scroll tag-empty-content text-center"
                                  },
                                  [_vm._v("空のデータ")]
                                )
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }