var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-inline p-0 m-0" }, [
    _c(
      "button",
      {
        class: "btn btn-success " + _vm.classes,
        attrs: { type: "submit", disabled: _vm.submitted || _vm.disabled },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [
        _vm.submitted
          ? _c(
              "span",
              [
                _c("b-spinner", { attrs: { small: "" } }),
                _vm._v(" "),
                _vm.object
                  ? _c("span", [_vm._v(_vm._s(_vm.object) + "を")])
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.action) + "中")])
              ],
              1
            )
          : _c("span", [
              _vm.object
                ? _c("span", [_vm._v(_vm._s(_vm.object) + "を")])
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.action))])
            ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }