var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { key: _vm.contentKey, staticClass: "mxw-1200" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "form-group d-flex" }, [
                _c("label", { staticClass: "fw-300" }, [_vm._v("フォルダー")]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.surveyData.folder_id,
                          expression: "surveyData.folder_id"
                        }
                      ],
                      staticClass: "form-control fw-300",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.surveyData,
                            "folder_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.folders, function(folder, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: folder.id } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(folder.name) +
                              "\n              "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group d-flex" }, [
                _c(
                  "label",
                  { staticClass: "fw-300 mb-auto" },
                  [_vm._v("フォーム名(管理用)"), _c("required-mark")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        name: "フォーム名(管理用)",
                        rules: "required|max:64"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.surveyData.name,
                                    expression: "surveyData.name",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "survey_name",
                                  placeholder:
                                    "フォーム名(管理用)を入力してください",
                                  maxlength: "65"
                                },
                                domProps: { value: _vm.surveyData.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.surveyData,
                                      "name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error-message", {
                                attrs: { message: errors[0] }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group d-flex" }, [
                _c(
                  "label",
                  { staticClass: "fw-300 mb-auto" },
                  [_vm._v("タイトル"), _c("required-mark")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "タイトル", rules: "required|max:255" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.surveyData.title,
                                    expression: "surveyData.title",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "survey_title",
                                  placeholder: "タイトルを入力してください"
                                },
                                domProps: { value: _vm.surveyData.title },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.surveyData,
                                      "title",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error-message", {
                                attrs: { message: errors[0] }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group d-flex" }, [
                _c(
                  "label",
                  { staticClass: "fw-300 mb-auto" },
                  [_vm._v("説明"), _c("required-mark")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "説明", rules: "required|max:1000" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.surveyData.description,
                                    expression: "surveyData.description"
                                  }
                                ],
                                staticClass: "form-control flex-grow-1",
                                attrs: {
                                  rows: "3",
                                  type: "text",
                                  name: "survey_description",
                                  placeholder: "説明を入力してください",
                                  maxlength: "1001"
                                },
                                domProps: { value: _vm.surveyData.description },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.surveyData,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error-message", {
                                attrs: { message: errors[0] }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group d-flex" }, [
                _c("label", { staticClass: "fw-300 mb-auto" }, [
                  _vm._v("回答後の文章")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "回答後の文章", rules: "max:1000" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.surveyData.success_message,
                                    expression: "surveyData.success_message"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  rows: "2",
                                  type: "text",
                                  name: "survey_success_message",
                                  placeholder: "回答後の文章を入力してください",
                                  maxlength: "1001"
                                },
                                domProps: {
                                  value: _vm.surveyData.success_message
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.surveyData,
                                      "success_message",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error-message", {
                                attrs: { message: errors[0] }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group d-flex" }, [
                _c("label", { staticClass: "fw-300 mb-auto" }, [
                  _vm._v("バナー")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("input-image", {
                      attrs: { imageUrl: _vm.surveyData.banner_url },
                      on: {
                        "update:imageUrl": function($event) {
                          return _vm.$set(_vm.surveyData, "banner_url", $event)
                        },
                        "update:image-url": function($event) {
                          return _vm.$set(_vm.surveyData, "banner_url", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.surveyData.re_answer,
                      expression: "surveyData.re_answer",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "repeatAnswerCheck" },
                  domProps: {
                    checked: Array.isArray(_vm.surveyData.re_answer)
                      ? _vm._i(_vm.surveyData.re_answer, null) > -1
                      : _vm.surveyData.re_answer
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.surveyData.re_answer,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.surveyData,
                              "re_answer",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.surveyData,
                              "re_answer",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.surveyData, "re_answer", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "repeatAnswerCheck" }
                  },
                  [_vm._v("何度でも回答可能にする")]
                )
              ]),
              _vm._v(" "),
              _vm.can_sync_ggsheet
                ? _c("div", { staticClass: "custom-control custom-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.surveyData.sync_to_ggsheet,
                          expression: "surveyData.sync_to_ggsheet",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: { type: "checkbox", id: "syncToGoogleSheet" },
                      domProps: {
                        checked: Array.isArray(_vm.surveyData.sync_to_ggsheet)
                          ? _vm._i(_vm.surveyData.sync_to_ggsheet, null) > -1
                          : _vm.surveyData.sync_to_ggsheet
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.surveyData.sync_to_ggsheet,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.surveyData,
                                  "sync_to_ggsheet",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.surveyData,
                                  "sync_to_ggsheet",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.surveyData, "sync_to_ggsheet", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "syncToGoogleSheet" }
                      },
                      [_vm._v("Googleスプレッドシート連携")]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: this.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("survey-question-editor", {
                  attrs: {
                    data: _vm.surveyData.questions,
                    name: "survey-question-editor"
                  },
                  on: {
                    input: function($event) {
                      return _vm.onQuestionsChanged($event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: this.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("action-editor-custom", {
                  attrs: {
                    name: "survey-action",
                    value: _vm.surveyData.after_action,
                    requiredLabel: false,
                    showTitle: false,
                    showLaunchMessage: false
                  },
                  on: {
                    input: function($event) {
                      _vm.surveyData.after_action = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("loading-indicator", { attrs: { loading: this.loading } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              {
                staticClass: "btn btn-success mr-2",
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v("保存＆公開")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn btn-outline-success mw-120",
                on: {
                  click: function($event) {
                    return _vm.submit(false)
                  }
                }
              },
              [_vm._v("下書き保存")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("survey-preview", { attrs: { survey: _vm.surveyData } }),
      _vm._v(" "),
      _c("modal-confirm", {
        attrs: {
          title: "公開後、編集できませんがよろしいでしょうか。",
          id: "modal-publish",
          type: "confirm"
        },
        on: {
          input: function($event) {
            return _vm.submit(true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("基本設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("質問一覧")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header left-border" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("回答後のアクション")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }