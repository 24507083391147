var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "card mvh-50" },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", [
            _c("table", { staticClass: "table table-centered mt-2 pc" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.friendList, function(friend) {
                  return _c("tr", { key: friend.id }, [
                    _c(
                      "td",
                      { staticClass: "d-lg-table-cell" },
                      [_c("FriendTag", { attrs: { tags: friend.tags } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "d-lg-table-cell" }, [
                      _vm._v(_vm._s(friend.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "d-lg-table-cell" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatted_time")(friend.created_at))
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center mt-4" },
            [
              parseInt(_vm.totalRows) > parseInt(_vm.perPage)
                ? _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      "aria-controls": "my-table"
                    },
                    on: { change: _vm.loadDetail },
                    model: {
                      value: _vm.curPage,
                      callback: function($$v) {
                        _vm.curPage = $$v
                      },
                      expression: "curPage"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading && _vm.totalRows === 0
            ? _c("div", { staticClass: "text-center my-5 font-weight-bold" }, [
                _vm._v("データはありません。")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "d-lg-table-cell" }, [_vm._v("タグ")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-lg-table-cell" }, [_vm._v("名前")]),
        _vm._v(" "),
        _c("th", { staticClass: "d-lg-table-cell" }, [_vm._v("友だち追加日時")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }