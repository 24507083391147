var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "card-header d-flex align-items-center" }, [
          _c(
            "a",
            {
              staticClass: "text-info",
              attrs: { href: _vm.userRootUrl + "/user/setting" }
            },
            [
              _c("i", { staticClass: "mdi mdi-arrow-left" }),
              _vm._v("アカウント詳細\n      ")
            ]
          ),
          _vm._v(" "),
          _c("h5", { staticClass: "m-auto font-weight-bold" }, [
            _vm._v("設定変更")
          ])
        ]),
        _vm._v(" "),
        _c("ValidationObserver", {
          ref: "observer",
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var validate = ref.validate
                return [
                  _c(
                    "form",
                    {
                      ref: "form",
                      attrs: {
                        action: _vm.getAction(),
                        method: "post",
                        enctype: "multipart/form-data"
                      },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          validate().then(_vm.onSubmit)
                        }
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "hidden", name: "authenticity_token" },
                        domProps: { value: _vm.csrfToken }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "_method",
                          value: "patch"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "hidden", name: "setting[id]" },
                        domProps: { value: _vm.settingFormData.id }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "fw-350" },
                            [
                              _vm._v("LINE公式アカウントID"),
                              _c("required-mark")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "LINE公式アカウントID",
                                  rules: "required|max:255"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.settingFormData
                                                    .line_user_id,
                                                expression:
                                                  "settingFormData.line_user_id",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "setting[line_user_id]",
                                              placeholder: "入力してください",
                                              maxlength: "256"
                                            },
                                            domProps: {
                                              value:
                                                _vm.settingFormData.line_user_id
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.settingFormData,
                                                  "line_user_id",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("label", { staticClass: "fw-350" }, [
                            _vm._v("LIFF ID")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "LIFF ID", rules: "max:255" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.settingFormData.liff_id,
                                                expression:
                                                  "settingFormData.liff_id",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "setting[liff_id]",
                                              placeholder: "入力してください",
                                              maxlength: "256"
                                            },
                                            domProps: {
                                              value: _vm.settingFormData.liff_id
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.settingFormData,
                                                  "liff_id",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("label", { staticClass: "fw-350" }, [
                            _vm._v("PMS APIキー")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "PMS APIキー",
                                  rules: "max:255"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.settingFormData
                                                    .pms_api_key,
                                                expression:
                                                  "settingFormData.pms_api_key",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "setting[pms_api_key]",
                                              placeholder: "入力してください",
                                              maxlength: "256"
                                            },
                                            domProps: {
                                              value:
                                                _vm.settingFormData.pms_api_key
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.settingFormData,
                                                  "pms_api_key",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "fw-350" },
                            [_vm._v("LINEアカウント名"), _c("required-mark")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "LINEアカウント名",
                                  rules: "required|max:255"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.settingFormData.line_name,
                                                expression:
                                                  "settingFormData.line_name",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "setting[line_name]",
                                              placeholder: "入力してください",
                                              maxlength: "256"
                                            },
                                            domProps: {
                                              value:
                                                _vm.settingFormData.line_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.settingFormData,
                                                  "line_name",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c(
                            "label",
                            { staticClass: "fw-350" },
                            [_vm._v("表示名"), _c("required-mark")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "表示名",
                                  rules: "required|max:255"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.settingFormData
                                                    .display_name,
                                                expression:
                                                  "settingFormData.display_name",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "setting[display_name]",
                                              placeholder: "入力してください",
                                              maxlength: "256"
                                            },
                                            domProps: {
                                              value:
                                                _vm.settingFormData.display_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.settingFormData,
                                                  "display_name",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "error-explanation"
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("label", { staticClass: "fw-350" }, [
                            _vm._v("チャネルID")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-grow-1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settingFormData.channel_id,
                                  expression: "settingFormData.channel_id",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "入力してください",
                                maxlength: "256",
                                disabled: ""
                              },
                              domProps: {
                                value: _vm.settingFormData.channel_id
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settingFormData,
                                    "channel_id",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("label", { staticClass: "fw-350" }, [
                            _vm._v("チャネルシークレット")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-grow-1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settingFormData.channel_secret,
                                  expression: "settingFormData.channel_secret",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "入力してください",
                                maxlength: "256",
                                disabled: ""
                              },
                              domProps: {
                                value: _vm.settingFormData.channel_secret
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settingFormData,
                                    "channel_secret",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group d-flex" }, [
                          _c("label", { staticClass: "fw-350" }, [
                            _vm._v("Webhook URL")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-grow-1" }, [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "入力してください",
                                disabled: ""
                              },
                              domProps: { value: _vm.webhookUrl() }
                            })
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-footer row-form-btn d-flex" },
                        [
                          _c("submit-button", {
                            attrs: {
                              object: "設定",
                              action: "保存",
                              submitted: _vm.submitted
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        }),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }