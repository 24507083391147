var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.broadcast.type === "all"
    ? _c("div", [_vm._v("全て")])
    : _vm.broadcast.type === "condition"
    ? _c(
        "div",
        [
          _vm.broadcast.tags && _vm.broadcast.tags.length > 0
            ? _c(
                "div",
                { staticClass: "d-flex align-items-center flex-wrap" },
                [
                  _c("span", { staticClass: "text-sm mr-2" }, [_vm._v("タグ")]),
                  _vm._v(" "),
                  _vm._l(_vm.broadcast.tags, function(tag, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "badge badge-warning badge-pill mr-1"
                      },
                      [_vm._v(_vm._s(tag.name))]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.broadcast.conditions &&
          _vm.broadcast.conditions.type === "specific"
            ? _c("divider")
            : _vm._e(),
          _vm._v(" "),
          _vm.broadcast.conditions &&
          _vm.broadcast.conditions.type === "specific"
            ? _c(
                "div",
                { staticClass: "d-flex align-items-center flex-wrap" },
                [
                  _c("span", { staticClass: "text-sm mr-2" }, [
                    _vm._v("友だち登録日")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      _vm._s(_vm.friendAddCondition.start_date) +
                        " ~ " +
                        _vm._s(_vm.friendAddCondition.end_date)
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }