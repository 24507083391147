var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-preview" },
    _vm._l(_vm.messages, function(item, index) {
      return _c("div", { key: index }, [
        _vm.isDisplay(item)
          ? _c("div", { staticClass: "chat chat-text-light chat-secondary" }, [
              _c(
                "div",
                { staticClass: "avatar mb-1 rounded-circle avatar-sm" },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index === 0,
                        expression: "index === 0"
                      }
                    ],
                    staticClass: "w-100 h-100",
                    staticStyle: {
                      background:
                        "url('/img/no-image-profile.png') center center / cover"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "chat-content w-min-0" }, [
                _c("div", { staticClass: "chat-body" }, [
                  _c(
                    "div",
                    { staticClass: "chat-main w-min-0" },
                    [_c("message-content", { attrs: { data: item } })],
                    1
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }