var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade modal-template modal-common01",
      attrs: {
        id: _vm.name,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c(
          "div",
          { staticClass: "modal-content", staticStyle: { padding: "0 15px" } },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body mh-100 overflow-hidden" }, [
              _c(
                "div",
                { staticClass: "tag-list" },
                [
                  _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "col-md-4 tag-content-left p-0" },
                      [
                        _c("div", { staticClass: "tag-content" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tag-scroll folder-list" },
                            [
                              _vm.isAddMoreFolder
                                ? _c(
                                    "div",
                                    { staticClass: "folder-item new-folder" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group newgroup-inputs"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.folderForm.name,
                                                expression: "folderForm.name",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "フォルダー名"
                                            },
                                            domProps: {
                                              value: _vm.folderForm.name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.folderForm,
                                                  "name",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "input-group-btn"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.loading.folderLoading
                                ? _c("div", [_vm._v("Loading...")])
                                : _vm._l(_vm.folderLists, function(
                                    item,
                                    index
                                  ) {
                                    return _c("flexmesasge-folder-item", {
                                      key: index,
                                      attrs: {
                                        data: item,
                                        active: _vm.folderId == item.id,
                                        disableEditor: true,
                                        index: index
                                      },
                                      on: {
                                        changeSelected: function($event) {
                                          _vm.folderId = $event.folderId
                                        }
                                      }
                                    })
                                  })
                            ],
                            2
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-8 tag-content-right",
                        class: _vm.currentFolder !== null ? "show" : ""
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tag-content",
                            staticStyle: { background: "rgb(249, 249, 249)" }
                          },
                          [
                            _vm.currentFolder !== null
                              ? _c(
                                  "table",
                                  { staticClass: "table table-tags-header" },
                                  [
                                    _c(
                                      "thead",
                                      { staticClass: "thead-light" },
                                      [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "hidden-pc" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-arrow-left",
                                                on: { click: _vm.backToFolder }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "header-title folder-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.currentFolder.name || ""
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "tag-scroll tag-list" }, [
                              _vm.loading.flexMessageLoading
                                ? _c("div", [_vm._v("Loading...")])
                                : _c(
                                    "table",
                                    {
                                      staticClass: "table",
                                      staticStyle: {
                                        "max-width": "initial !important"
                                      }
                                    },
                                    [
                                      _c("tbody", [
                                        _vm.flexMessageList &&
                                        _vm.flexMessageList.length
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.flexMessageList,
                                                  function(item, index) {
                                                    return [
                                                      _c(
                                                        "tr",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            display: "flex"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "flex-item"
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right",
                                                                width: "auto"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "btn-more btn-more-linebot btn-block cursor-pointer",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.currentFlexMessage ===
                                                                      item
                                                                        ? (_vm.currentFlexMessage = null)
                                                                        : (_vm.currentFlexMessage = item)
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                プレビュー\n                              "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "btn-more btn-more-linebot btn-block cursor-pointer",
                                                                  attrs: {
                                                                    "data-dismiss":
                                                                      "modal"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.pickFlexMessage(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                選択\n                              "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item ===
                                                      _vm.currentFlexMessage
                                                        ? _c(
                                                            "tr",
                                                            {
                                                              key:
                                                                index +
                                                                "_preview",
                                                              staticStyle: {
                                                                background:
                                                                  "#ededed"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "td",
                                                                {
                                                                  attrs: {
                                                                    colspan: "2"
                                                                  }
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticStyle: {
                                                                      zoom:
                                                                        "0.6"
                                                                    },
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        item.html_template
                                                                      )
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "text-center mt40"
                                              },
                                              [_vm._v("データーがありません")]
                                            )
                                      ])
                                    ]
                                  )
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.currentFlexMessage != null
                    ? _c("flexmessage-modal-preview", {
                        attrs: {
                          id: "flexMessagePreview",
                          model: _vm.currentFlexMessage
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Flexメッセージを選択")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "folder-list-title" }, [
      _c("table", { staticClass: "table table-tags-header" }, [
        _c("thead", { staticClass: "thead-light" }, [
          _c("tr", [
            _c("th", { staticClass: "header-title", attrs: { scope: "col" } }, [
              _vm._v("フォルダー")
            ]),
            _vm._v(" "),
            _c("th", {
              staticStyle: { "text-align": "right" },
              attrs: { scope: "col" }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }