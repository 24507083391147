var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mxw-1200" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group d-flex align-items-center" }, [
            _c("label", { staticClass: "fw-300" }, [_vm._v("フォルダー")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.variableData.folder_id,
                      expression: "variableData.folder_id"
                    }
                  ],
                  staticClass: "form-control fw-300",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.variableData,
                        "folder_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.folders, function(folder, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: folder.id } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(folder.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c(
              "label",
              { staticClass: "fw-300 mb-auto" },
              [_vm._v("友だち情報欄名"), _c("required-mark")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fw-300" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.variableData.name,
                      expression: "variableData.name",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:255",
                      expression: "'required|max:255'"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "variable_name",
                    placeholder: "友だち情報欄名を入力してください",
                    maxlength: "256",
                    "data-vv-as": "友だち情報欄名"
                  },
                  domProps: { value: _vm.variableData.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.variableData,
                        "name",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("error-message", {
                  attrs: { message: _vm.errors.first("variable_name") }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex" }, [
            _c("label", { staticClass: "fw-300" }, [_vm._v("種別")]),
            _vm._v(" "),
            _c("div", { staticClass: "fw-300" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.variableData.type,
                      expression: "variableData.type"
                    }
                  ],
                  staticClass: "form-control w-100",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.variableData,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.dataTypes, function(option, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: option.type } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(option.name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _vm.isSupportDefaultValue
            ? _c("div", { staticClass: "form-group d-flex" }, [
                _c("label", { staticClass: "fw-300" }, [_vm._v("既定値")]),
                _vm._v(" "),
                _c("div", { staticClass: "grow-flex-1 d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "fw-300" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.variableData.default,
                            expression: "variableData.default",
                            modifiers: { trim: true }
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:255",
                            expression: "'max:255'"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "variable_default",
                          placeholder: "友だち情報欄名を入力してください",
                          maxlength: "256",
                          "data-vv-as": "既定値"
                        },
                        domProps: { value: _vm.variableData.default },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.variableData,
                              "default",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-message", {
                        attrs: { message: _vm.errors.first("variable_default") }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-light ml-1 mb-auto",
                      on: {
                        click: function($event) {
                          _vm.variableData.default = "{name}"
                        }
                      }
                    },
                    [_vm._v("{name}を使用")]
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("loading-indicator", { attrs: { loading: _vm.loading } }),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success text-nowrap",
              attrs: { disabled: _vm.invalid },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.variable_id ? "友だち情報欄変更" : "友だち情報欄登録"
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }