var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey
    ? _c(
        "div",
        { staticClass: "bg-white" },
        [
          _vm.survey.banner_url
            ? _c("img", {
                staticClass: "banner mb-1",
                attrs: { src: "" + _vm.survey.banner_url }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.survey.title))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "font-12" }, [
            _vm._v(_vm._s(_vm.survey.description))
          ]),
          _vm._v(" "),
          _vm._l(_vm.questions, function(question, index) {
            return _c(
              "div",
              { key: index, staticClass: "mt-2" },
              [
                question.type === "text"
                  ? _c("survey-form-text", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "textarea"
                  ? _c("survey-form-textarea", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "pulldown"
                  ? _c("survey-form-pulldown", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "radio"
                  ? _c("survey-form-radio", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "checkbox"
                  ? _c("survey-form-checkbox", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "image"
                  ? _c("survey-form-image", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "pdf"
                  ? _c("survey-form-pdf", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "date"
                  ? _c("survey-form-date", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "time"
                  ? _c("survey-form-time", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.type === "datetime"
                  ? _c("survey-form-datetime", {
                      attrs: { question: question, qnum: index + 1 }
                    })
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }