var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        display: "flex",
        "flex-direction": "column"
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "justify-content": "center",
            height: "40px",
            display: "flex",
            "flex-direction": "row"
          }
        },
        [
          _vm.object.image.data !== null
            ? _c(
                "div",
                {
                  staticStyle: { margin: "auto 0", width: "30px" },
                  on: {
                    click: function($event) {
                      return _vm.zoomIn()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-search-plus",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.object.image.data !== null
            ? _c(
                "div",
                {
                  staticStyle: { margin: "auto 0", width: "30px" },
                  on: {
                    click: function($event) {
                      return _vm.zoomOut()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-search-minus",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("span", { staticClass: "w-100 text-center error" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "text-align": "center",
            height: "40px",
            display: "flex"
          }
        },
        [
          _c(
            "label",
            {
              staticClass: "btn-img-upload text-info",
              attrs: { role: "button" }
            },
            [
              _c("input", {
                staticStyle: {
                  position: "absolute",
                  "z-index": "1",
                  top: "0",
                  opacity: "0",
                  width: "100%"
                },
                attrs: { type: "file", name: "image" },
                on: {
                  change: function($event) {
                    return _vm.changeImage($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("i", { staticClass: "mdi mdi-image-size-select-actual" }),
              _vm._v(" アップロード\n    ")
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { flex: "1", "object-fit": "scale-down" },
        attrs: { id: "canvas-area" }
      },
      [_c("canvas", { attrs: { id: "canvas-editor" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }