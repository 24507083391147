var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: { hover: true }
        }
      ],
      staticClass: "ml-1",
      attrs: { title: _vm.title }
    },
    [_c("i", { staticClass: "mdi mdi-information-outline icon" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }