var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.originalContentUrl
      ? _c("div", { staticClass: "d-flex align-items-center border" }, [
          _c(
            "div",
            { staticClass: "my-4 ml-2" },
            [
              _c("media-preview", {
                attrs: { type: _vm.data.type, src: _vm.data.originalContentUrl }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn btn-sm btn-danger ml-2",
              attrs: { role: "button" },
              on: { click: _vm.removeMedia }
            },
            [_vm._v("削除")]
          )
        ])
      : _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "border text-center",
                class: _vm.errors.first("media-template" + _vm.index)
                  ? "invalid-box"
                  : ""
              },
              [
                _c("div", { staticClass: "figure figure-grid-sm my-4" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-primary",
                      attrs: {
                        href: "#",
                        "data-toggle": "modal",
                        "data-target": "#modalSelectMedia"
                      }
                    },
                    [
                      _vm.data.type === "image"
                        ? _c("span", [_vm._v("写真をアップロード")])
                        : _vm.data.type === "video"
                        ? _c("span", [_vm._v("動画をアップロード")])
                        : _vm.data.type === "audio"
                        ? _c("span", [_vm._v("ボイスメッセージをアップロード")])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-muted opacity-30" }, [
                    _vm.data.type === "image"
                      ? _c("i", { staticClass: "mdi mdi-image mdi-3x" })
                      : _vm.data.type === "video"
                      ? _c("i", { staticClass: "mdi mdi-video mdi-3x" })
                      : _vm.data.type === "audio"
                      ? _c("i", { staticClass: "mdi mdi-voice mdi-3x" })
                      : _vm._e()
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("error-message", {
              attrs: { message: _vm.errors.first("media-template" + _vm.index) }
            }),
            _vm._v(" "),
            _c("modal-select-media", {
              attrs: { types: [_vm.data.type] },
              on: {
                select: function($event) {
                  return _vm.addMedia($event)
                }
              }
            })
          ],
          1
        ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.data.originalContentUrl,
          expression: "data.originalContentUrl"
        },
        {
          name: "validate",
          rawName: "v-validate",
          value: "required",
          expression: "'required'"
        }
      ],
      attrs: {
        type: "hidden",
        name: "media-template" + _vm.index,
        "data-vv-as": "ファイル"
      },
      domProps: { value: _vm.data.originalContentUrl },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.data, "originalContentUrl", $event.target.value)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }