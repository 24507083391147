var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-content" }, [
    _c("img", {
      staticClass: "image-location",
      attrs: { src: _vm.getImageMap() }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "location-info" }, [
      _c("div", { staticClass: "location-title" }, [
        _vm._v(_vm._s(_vm.data.title))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "location-address" }, [
        _vm._v(_vm._s(_vm.data.address))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }