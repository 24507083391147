var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "text-right",
        on: {
          click: function($event) {
            return _vm.expand()
          }
        }
      },
      [
        _c("span", { staticClass: "float-left" }, [_vm._v("ボックス")]),
        _vm._v(" "),
        _c("i", {
          staticClass: "mdi mdi-arrow-expand",
          attrs: { "aria-hidden": "true" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card-container",
        class: _vm.name + "-expand",
        staticStyle: { "margin-top": "10px", display: "none" }
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "card-header" }, [_vm._v("アクション設定")]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("action-editor", {
                  staticClass: "form-group",
                  attrs: {
                    title: "Action Type",
                    supports: [
                      "postback",
                      "uri",
                      "message",
                      "datetimepicker",
                      "survey"
                    ],
                    showTitle: false,
                    name: _vm.name,
                    value: _vm.action
                  },
                  on: {
                    input: function($event) {
                      _vm.action = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }